import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import { getAuth } from "firebase/auth";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import firestore from "../../firebase";

export const QuizChoice3 = ({ onQuizResult }) => {
  const initialQuestions = [
    {
      id: 1,
      question: 'Welche der folgenden Merkmale sind typisch für eine Phishing-E-Mail?',
      options: [
        { id: 'a', text: 'Unerwartete Aufforderung zur Eingabe persönlicher Daten', correct: true },
        { id: 'b', text: 'Dringende Warnungen oder Drohungen', correct: true },
        { id: 'c', text: 'Links, die zu gefälschten Webseiten führen', correct: true },
        { id: 'd', text: 'Perfekt formulierte Nachrichten ohne Rechtschreibfehler', }
      ]
    },
    {
      id: 2,
      question: 'Welche Maßnahmen sollten Sie ergreifen, um sich vor Phishing-Angriffen zu schützen?',
      options: [
        { id: 'a', text: 'Verdächtige E-Mails sofort löschen', correct: true },
        { id: 'b', text: 'Auf Links in verdächtigen E-Mails klicken, um sie zu überprüfen', },
        { id: 'c', text: 'Niemals persönliche Informationen über E-Mail preisgeben', correct: true },
        { id: 'd', text: 'Sicherheitssoftware regelmäßig aktualisieren', correct: true }
      ]
    },
    {
      id: 3,
      question: 'Was sollten Sie tun, wenn Sie eine verdächtige E-Mail erhalten, die nach Ihren persönlichen Informationen fragt?',
      options: [
        { id: 'a', text: 'Sofort auf den Link klicken und die Informationen eingeben', },
        { id: 'b', text: 'Die E-Mail löschen und nicht darauf reagieren', correct: true },
        { id: 'c', text: 'Die E-Mail an Freunde weiterleiten, um sie zu warnen', },
        { id: 'd', text: 'Die Informationen in einem neuen Tab eingeben, um sicher zu sein', }
      ]
    },
    {
      id: 4,
      question: 'Worauf zielen heutzutage Phishing-Angriffe neben E-Mail auch noch ab?',
      options: [
        { id: 'a', text: 'Postsendungen', },
        { id: 'b', text: 'SMS-Nachrichten (Smishing)', correct: true },
        { id: 'c', text: 'CD-ROMs', },
        { id: 'd', text: 'Flyer und Broschüren', }
      ]
    },
    {
      id: 5,
      question: 'Welche Hinweise können darauf hindeuten, dass eine E-Mail ein Phishing-Versuch ist?',
      options: [
        { id: 'a', text: 'Die E-Mail-Adresse des Absenders scheint verdächtig oder falsch', correct: true },
        { id: 'b', text: 'Es wird ein dringender Handlungsbedarf suggeriert', correct: true },
        { id: 'c', text: 'Die E-Mail fordert zur Eingabe von Passwörtern oder Kreditkarteninformationen auf', correct: true },
        { id: 'd', text: 'Die E-Mail enthält keine Betreffzeile', }
      ]
    }
  ];

  const [questions, setQuestions] = useState(initialQuestions);
  const [answers, setAnswers] = useState({});
  const [result, setResult] = useState(0);  // Zustand für das Ergebnis
  const [progress, setProgress] = useState(0); // Zustand für den Fortschritt
  const [resultsDB, setResultsDB] = useState(0);
  const [maxResultsDB, setMaxResultsDB] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false); // Zustand für die Anzeige des Fortschrittsbalkens
  const [check, setCheck] = useState(false);

  const handleOptionSelect = (questionId, optionId) => {
    const newAnswers = { ...answers };
    if (newAnswers[questionId]) {
      // If question already has answers, toggle the option
      if (newAnswers[questionId].includes(optionId)) {
        newAnswers[questionId] = newAnswers[questionId].filter(id => id !== optionId);
      } else {
        newAnswers[questionId].push(optionId);
      }
    } else {
      // If no answers yet, create new array with the selected option
      newAnswers[questionId] = [optionId];
    }
    setAnswers(newAnswers);
  };

  const checkAnswers = () => {
    let rightAnswers = 0;
    let maxAnswers = 0;
    questions.forEach(question => {
      const selectedOptions = answers[question.id] || [];
      const correctOptions = question.options.filter(option => option.correct).map(option => option.id);
      maxAnswers++;
      if (selectedOptions.length === correctOptions.length && selectedOptions.every(option => correctOptions.includes(option))) {
        rightAnswers++;
      }
    });

    setResultsDB(rightAnswers);
    setMaxResultsDB(maxAnswers);

    const totalQuestions = questions.length;
    setResult({ rightAnswers, totalQuestions });

    const progressPercentage = (rightAnswers / totalQuestions) * 100;
    setProgress(progressPercentage);
    setShowProgressBar(true); // Zeigt den Fortschrittsbalken an, nachdem die Antworten überprüft wurden

    if (typeof onQuizResult === 'function') {
      onQuizResult({ rightAnswers, totalQuestions });
    }

    updateDatabase();
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");
  
    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
  
      // Prüfe, ob das Dokument existiert
      if (!userDocSnap.exists()) {
        console.error("Benutzerdokument existiert nicht.");
        return;
      }
  
      const userData = userDocSnap.data();
      const currentStars = resultsDB;
      const currentMaxStars = maxResultsDB;
      
      // Vergleiche die aktuellen Ergebnisse mit den gespeicherten Ergebnissen
      if (!userData.starsGefahr3 || !userData.maxStarsGefahr3) {
        // Initialisiere die Arrays, wenn sie nicht existieren
        userData.starsGefahr3 = [];
        userData.maxStarsGefahr3 = [];
      }
  
      // Überprüfe, ob die aktuellen Ergebnisse besser sind als die gespeicherten Ergebnisse
      if (!userData.starsGefahr3[2] || currentStars > userData.starsGefahr3[2]) {
        userData.starsGefahr3[2] = currentStars;
      }
      
      if (!userData.maxStarsGefahr3[2] || currentMaxStars > userData.maxStarsGefahr3[2]) {
        userData.maxStarsGefahr3[2] = currentMaxStars;
      }
  
      // Speichern der aktualisierten Daten
      await setDoc(userDoc, userData);
      
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  useEffect(() => {
    if (check == true){
      checkAnswers();
      setCheck(false);
    }
  }, );

  return (
    <div className="quiz-container">
      <Link className="Link" to="/Gefahr3">
        <img
          className="logo"
          src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
          alt="Logo"
        />
      </Link>

      {showProgressBar && (
        <ProgressBar
        completed={Math.round((resultsDB / maxResultsDB) * 100)}  // Aufrunden
        bgColor="#06D001"
        baseBgColor="grey"
        labelColor="black"
        labelAlignment="center"
        labelSize="14px"
      />
      )}

      {questions.map(question => (
        <div className="Erklärungscard-Div-Choice3" key={question.id}>
          <p>{question.question}</p>
          <div className="options">
            {question.options.map(option => (
              <div key={option.id}>
                <input
                  type="checkbox"
                  id={`${question.id}-${option.id}`}
                  name={`question-${question.id}`}
                  value={option.id}
                  checked={answers[question.id] && answers[question.id].includes(option.id)}
                  onChange={() => handleOptionSelect(question.id, option.id)}
                />
                <label htmlFor={`${question.id}-${option.id}`}>{option.text}</label>
              </div>
            ))}
          </div>
        </div>
      ))}

      <button
        className='button-87'
        onClick={() => {
          checkAnswers();   
          setCheck(true);       
        }}
      >
        Überprüfen
      </button>
      <br />
      <br />
      
      {result && (
        <div className="Erklärungscard-Div">
          <p>Du hast {result.rightAnswers} von {result.totalQuestions} Fragen richtig beantwortet.</p>
        </div>
      )}
    </div>
  );
};

export default QuizChoice3;
