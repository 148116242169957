import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, setDoc } from "firebase/firestore";
import firestore from "../../firebase";
import ProgressBar from "@ramonak/react-progress-bar";

export const Gefahr1 = () => {
  const [progress, setProgress] = useState({
    QuizDragAndDrop1: 0,
    QuizLueckentext1: 0,
    QuizMatching1: 0,
    QuizChoice1: 0,
    QuizFillIn1: 0,
  });

  const [progressDB, setProgressDB] = useState(0); // Initialisiert mit 0
  const [maxProgressDB, setMaxProgressDB] = useState(0); // Initialisiert mit 0

  const updateProgress = (quizName, newProgress) => {
    setProgress(prevProgress => ({
      ...prevProgress,
      [quizName]: newProgress,
    }));
  };

  const totalProgress = Object.values(progress).reduce((acc, cur) => acc + cur, 0) / 5;

  const videoOptions = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  const getDocument = async () => {
    try {
      const docRef = doc(firestore, "Users", getAuth().currentUser.email);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data();

      if (userData && userData.starsGefahr1 !== undefined) {
        setProgressDB(userData.starsGefahr1[0] + userData.starsGefahr1[1] + userData.starsGefahr1[2]);
        setMaxProgressDB(userData.maxStarsGefahr1[0] + userData.maxStarsGefahr1[1] + userData.maxStarsGefahr1[2]);
      } else {
        console.error("starsGefahr1 nicht gefunden im Dokument");
      }

    } catch (e) {
      console.error("Fehler beim Abrufen des Dokuments aus der Datenbank", e);
    }
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");

    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();

      if (progressDB / maxProgressDB > 0.4){
        userData.unlockGefahr1 = true;
      }
      else {
        userData.unlockGefahr1 = false;
      }

      await setDoc(userDoc, userData);
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  // useEffect wird hier eingebaut
  useEffect(() => {
    getDocument();
  }, ); // Leeres Abhängigkeitsarray sorgt dafür, dass getDocument() nur einmal bei der ersten Render ausgeführt wird.

  return (
    <div className="gefahr2">
      <div className="progressBar" >
        <ProgressBar
          completed={Math.round((progressDB / maxProgressDB) * 100)}  // Aufrunden
          bgColor="#06D001"
          baseBgColor="grey"
          labelColor="black"
          labelAlignment="center"
          labelSize="14px"
        />
      </div>

      <Link className="Link" onClick={updateDatabase} to="/StartseiteLoggedIn" >
        <img className="logo" src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image" />
      </Link>

        <div className="gefahr-text">
          <h1>Drive-by-Downloads von Schadsoftware</h1>
          <p><strong>Was sind Drive-by-Downloads?<br /><br /></strong>
              Drive-by-Downloads sind eine Form von Cyberangriff, bei dem Schadsoftware (Malware) unbemerkt auf einen Computer heruntergeladen und installiert wird, wenn der Nutzer eine infizierte Website besucht. Dies geschieht ohne das Wissen oder die Zustimmung des Nutzers, weshalb diese Angriffe besonders heimtückisch sind.
          </p>
          <p><strong>Wie funktionieren Drive-by-Downloads?</strong>
          <ul><br />
              <li><strong>Infizierte Websites:</strong><br />Cyberkriminelle manipulieren legitime Websites oder erstellen bösartige Seiten, um Malware zu verbreiten.</li><br />
              <li><strong>Ausnutzung von Schwachstellen:</strong><br />Die Angriffe nutzen Sicherheitslücken in veralteter Software aus, wie in Webbrowsern oder Plugins (z.B. Flash, Java, PDF-Reader). Sobald eine anfällige Website aufgerufen wird, kann die Schadsoftware ohne weiteres Zutun des Nutzers heruntergeladen werden.</li><br />
              <li><strong>Automatische Infektion:</strong><br />Die Malware wird sofort und ohne Benutzerinteraktion installiert, wodurch das System infiziert wird.</li>
          </ul></p>
          <p><strong>Warum sind Drive-by-Downloads gefährlich?</strong>
          <ul><br />
              <li><strong>Unbemerkte Infektion:</strong><br />Die Infektion geschieht im Hintergrund, oft ohne sichtbare Anzeichen, dass etwas auf dem Computer passiert.</li><br />
              <li><strong>Verbreitung von Malware:</strong><br />Diese Methode kann verwendet werden, um verschiedenste Malware, wie Ransomware, Trojaner oder Spyware, zu verbreiten.</li><br />
              <li><strong>Häufigkeit:</strong><br />Drive-by-Downloads sind eine der häufigsten Methoden, um Malware zu verbreiten, und haben klassische Methoden wie E-Mail-Anhänge in ihrer Häufigkeit überholt.</li>
          </ul></p>
          <p><strong>Wie kann man sich schützen?</strong>
          <ul><br />
              <li><strong>Regelmäßige Updates:</strong><br />Halten Sie Ihre Software, insbesondere Browser und Plugins, immer auf dem neuesten Stand, um bekannte Sicherheitslücken zu schließen.</li><br />
              <li><strong>Sicherheitssoftware:</strong><br />Verwenden Sie aktuelle Antivirenprogramme und aktivieren Sie deren Echtzeitschutz.</li><br />
              <li><strong>Vorsicht beim Surfen:</strong><br />Vermeiden Sie verdächtige Websites und klicken Sie nicht auf unbekannte Links.</li>
          </ul></p>
          <p><strong>Zusammenfassung:</strong><br /><br />
              Drive-by-Downloads sind gefährliche Cyberangriffe, die unbemerkt beim Besuch einer infizierten Website ablaufen und zur Installation von Schadsoftware führen können. Schützen Sie sich durch regelmäßige Updates und den Einsatz von Sicherheitssoftware.
          </p>
        </div>

      <div className="video-container">
        <h2>Mehr über Drive-by-Downloads</h2>
        <YouTube videoId="jYykpF5Y6o4" opts={videoOptions} />
      </div>

      <div className="Quiz-button">
        <div className="containerQuizze">
          <Link className="Link" to="/QuizFillIn1">
            <button className='button-87'>Fill-In-Quiz</button>
          </Link>
          <Link className="Link" to="/QuizLueckentext1">
            <button className='button-87'>Lückentext Quiz</button>
          </Link>
          <Link className="Link" to="/QuizChoice1">
            <button className='button-87'>Multiple- oder Single-Choice-Quiz</button>
          </Link>
        </div>
      </div>
      
    </div>
  );
};
