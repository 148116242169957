import { Link } from 'react-router-dom';
import { useCallback, useState, useRef } from "react";
import React from "react";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, setDoc } from "firebase/firestore";
import firestore from "../firebase";

export const Signup = () => {
  const messageRef = useRef();
  const nameRef = useRef();
  const ref = collection(firestore, "Users");

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [kostenpflichtigDB, setKostenpflichtigDB] = useState(false); // Neuer Zustand für kostenpflichtige Version

  const isDisabled = !email || !username || !password;

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      await setDoc(doc(ref, messageRef.current.value), {
        Username: nameRef.current.value,
        Emailadress: messageRef.current.value,
        Registerdate: Date().toLocaleString("en-GB", { timeZone: "UTC" }),
        kostenpflichtig: kostenpflichtigDB,
        starsGefahr1: [0, 0, 0],
        starsGefahr2: [0, 0, 0],
        starsGefahr3: [0, 0, 0],
        starsGefahr4: [0, 0, 0],
        starsGefahr5: [0, 0, 0],
        starsGefahr6: [0, 0, 0],
        maxStarsGefahr1: [4, 4, 5],
        maxStarsGefahr2: [6, 5, 3],
        maxStarsGefahr3: [3, 6, 5],
        maxStarsGefahr4: [7, 6, 5],
        maxStarsGefahr5: [6, 6, 7],
        maxStarsGefahr6: [8, 2, 6],
        starsGesamt1: [0, 0, 0],
        starsGesamt2: [0, 0, 0],
        maxStarsGesamt1: [10, 6, 6],
        maxStarsGesamt2: [8, 6, 6],
        unlockGefahr1: false,
        unlockGefahr2: false,
        unlockGefahr3: false,
        unlockGefahr4: false,
        unlockGefahr5: false,
        unlockGefahr6: false,
        unlockGesamt1: false,
        unlockGesamt2: false,
        unlockEin1: false,
        unlockEin2: false,
        championsBadge: false,
        top3Badge: false,
      });
    } catch (e) {
      alert(e);
      alert("Datenbankfehler");
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (e) {
      alert(e.message);
    }
  }, [email, username, password, ref, kostenpflichtigDB]);

  return (
    <div className="curved" style={{ minHeight: '100vh', position: 'relative' }}>
      <svg className='svg-curve' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#0099ff" fillOpacity="1" d="M0,32L34.3,42.7C68.6,53,137,75,206,106.7C274.3,139,343,181,411,218.7C480,256,549,288,617,266.7C685.7,245,754,171,823,144C891.4,117,960,139,1029,165.3C1097.1,192,1166,224,1234,229.3C1302.9,235,1371,213,1406,202.7L1440,192L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
      </svg>

      <Link className="Link-Anmeldung" to="/">
          <img
          className="logo"
          src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
          alt="Logo"
          />
        </Link>
      <h1 className='login-headline'>Account erstellen</h1>
      <form onSubmit={handleSubmit} className="Login_Form">
        <Link to="/">
          <img
            style={{ margin: "0 auto", width: '100px' }}
            src="https://firebasestorage.googleapis.com/v0/b/internet-fuererschein.appspot.com/o/IF_Logo.svg?alt=media&token=fa845e98-fd2a-4bad-882e-5acf05a5b21c"
            alt=""
          />
        </Link>

        <a className="ErrorLogin" />
        <label>Email-Adresse</label>
        <input
          className="input-field"
          name="email"
          placeholder="email"
          type="email"
          value={email}
          ref={messageRef}
          onChange={(e) => setEmail(e.target.value)}
          style={{ margin: '0 10px' }} // Abstand zu den Rändern hinzufügen
        />
        <label>Username</label>
        <input
          className="input-field"
          name="username"
          placeholder="username"
          type="text"
          value={username}
          ref={nameRef}
          onChange={(e) => setUsername(e.target.value)}
          style={{ margin: '0 10px' }} // Abstand zu den Rändern hinzufügen
        />
         <label>Password</label>
        <input
          className="input-field"
          name="password"
          placeholder="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ margin: '0 10px' }} // Abstand zu den Rändern hinzufügen
        />

        {/* Checkbox für kostenpflichtige Version */}
        <div style={{ margin: '10px 10px' }}>
          <input
            type="checkbox"
            id="paidVersion"
            checked={kostenpflichtigDB}
            onChange={(e) => setKostenpflichtigDB(e.target.checked)}
          />
          <label htmlFor="paidVersion" style={{ marginLeft: '5px' }}>Kostenpflichtige Version für 4,99€</label>
        </div>

        <button
          style={{ margin: "20px auto" }} // Platz für den Button unterhalb hinzufügen
          className={isDisabled ? 'button-87-disabled' : 'button-87'}
          type="submit"
          disabled={isDisabled}
        >
          Account erstellen
        </button>
        <div style={{ textAlign: 'center' }}>
          <a href='/Help' style={{ color: 'blue', display: 'block', marginBottom: '10px' }}>Passwort vergessen?</a>
          <a href='/Login' style={{ color: 'blue' }}>Du hast schon einen Account?</a>
        </div>
      </form>

      <div className='bottom-div' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '50px', left: '0', right: '0' }}>
        <hr className='hr-modern'></hr>
        <a>© 2024 THWS</a>
      </div>
    </div>
  );
};
