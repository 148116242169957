import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, setDoc } from "firebase/firestore";
import firestore from "../../firebase";
import ProgressBar from "@ramonak/react-progress-bar";

export const Gefahr2 = () => {
  const [progress, setProgress] = useState({
    QuizDragAndDrop1: 0,
    QuizLueckentext1: 0,
    QuizMatching1: 0,
    QuizChoice1: 0,
    QuizFillIn1: 0,
  });

  const [progressDB, setProgressDB] = useState(0); // Initialisiert mit 0
  const [maxProgressDB, setMaxProgressDB] = useState(0); // Initialisiert mit 0

  const updateProgress = (quizName, newProgress) => {
    setProgress(prevProgress => ({
      ...prevProgress,
      [quizName]: newProgress,
    }));
  };

  const totalProgress = Object.values(progress).reduce((acc, cur) => acc + cur, 0) / 5;

  const videoOptions = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  const getDocument = async () => {
    try {
      const docRef = doc(firestore, "Users", getAuth().currentUser.email);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data();

      if (userData && userData.starsGefahr2 !== undefined) {
        setProgressDB(userData.starsGefahr2[0] + userData.starsGefahr2[1] + userData.starsGefahr2[2]);
        setMaxProgressDB(userData.maxStarsGefahr2[0] + userData.maxStarsGefahr2[1] + userData.maxStarsGefahr2[2]);
      } else {
        console.error("starsGefahr1 nicht gefunden im Dokument");
      }

    } catch (e) {
      console.error("Fehler beim Abrufen des Dokuments aus der Datenbank", e);
    }
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");

    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();

      if (progressDB / maxProgressDB > 0.4){
        userData.unlockGefahr2 = true;
      }
      else {
        userData.unlockGefahr2 = false;
      }

      await setDoc(userDoc, userData);
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  // useEffect wird hier eingebaut
  useEffect(() => {
    getDocument();
  }, ); // Leeres Abhängigkeitsarray sorgt dafür, dass getDocument() nur einmal bei der ersten Render ausgeführt wird.



  return (
    <div className="gefahr2">

      <div className="progressBar" >
        <ProgressBar
          completed={Math.round((progressDB / maxProgressDB) * 100)}  // Aufrunden
          bgColor="#06D001"
          baseBgColor="grey"
          labelColor="black"
          labelAlignment="center"
          labelSize="14px"
        />
      </div>

      <Link className="Link" onClick={updateDatabase} to="/StartseiteLoggedIn" >
        <img className="logo" src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image" alt="Logo" />
      </Link>

      <div className="gefahr-text">
    <h1>Social Engineering</h1>
    
    <p><strong>Was ist Social Engineering?<br /><br /></strong>
        Social Engineering ist eine Technik, bei der Cyberkriminelle versuchen, Menschen zu manipulieren, um vertrauliche Informationen preiszugeben oder bestimmte Handlungen auszuführen, die den Angreifern zugutekommen. 
        Statt technische Schwachstellen auszunutzen, zielen diese Angriffe auf die menschliche Psychologie ab.
    </p>
    
    <p><strong>Wie funktioniert Social Engineering?</strong>
    <ul><br />
        <li><strong>Manipulation von Vertrauen:</strong><br />
            Angreifer nutzen das Vertrauen oder die Unwissenheit von Menschen aus, um sie dazu zu bringen, sensible Informationen wie Passwörter oder Bankdaten herauszugeben. 
            Oft geben sie sich als vertrauenswürdige Personen oder Organisationen aus, z.B. als IT-Mitarbeiter oder Bankangestellte.
        </li><br />
        
        <li><strong>Gefälschte Kommunikation:</strong><br />
            Häufig werden Phishing-E-Mails, betrügerische Anrufe oder gefälschte Websites verwendet, um die Opfer dazu zu bringen, auf Links zu klicken, Anhänge zu öffnen oder persönliche Daten einzugeben.
        </li><br />
        
        <li><strong>Psychologische Tricks:</strong><br />
            Social Engineering setzt auf die Ausnutzung von Emotionen wie Angst, Dringlichkeit oder Neugierde. 
            Zum Beispiel könnte eine E-Mail behaupten, dass das Konto des Nutzers gesperrt wird, wenn er nicht sofort seine Daten bestätigt.
        </li>
    </ul></p>
    
    <p><strong>Warum ist Social Engineering gefährlich?</strong>
    <ul><br />
        <li><strong>Hohe Erfolgsquote:</strong><br />
            Menschen sind oft das schwächste Glied in der Sicherheitskette, und selbst gut geschulte Personen können Opfer solcher Angriffe werden.
        </li><br />
        
        <li><strong>Schwer zu erkennen:</strong><br />
            Social Engineering-Angriffe sind oft sehr gut getarnt und schwer von legitimen Anfragen zu unterscheiden.
        </li><br />
        
        <li><strong>Weitreichende Folgen:</strong><br />
            Wenn Angreifer erfolgreich sind, können sie Zugang zu sensiblen Daten, finanziellen Informationen oder internen Systemen erhalten.
        </li>
    </ul></p>
    
    <p><strong>Wie kann man sich schützen?</strong>
    <ul><br />
        <li><strong>Gesundes Misstrauen:</strong><br />
            Seien Sie vorsichtig bei unerwarteten E-Mails, Anrufen oder Nachrichten, die nach persönlichen Informationen fragen oder ungewöhnliche Anfragen stellen.
        </li><br />
        
        <li><strong>Verifikation:</strong><br />
            Überprüfen Sie die Identität von Personen oder Organisationen, die nach sensiblen Informationen fragen, bevor Sie diese preisgeben.
        </li><br />
        
        <li><strong>Schulung:</strong><br />
            Regelmäßige Schulungen und Sensibilisierungskampagnen können helfen, das Bewusstsein für Social Engineering zu schärfen und die Wahrscheinlichkeit, Opfer zu werden, zu verringern.
        </li>
    </ul></p>
    
    <p><strong>Zusammenfassung:</strong><br /><br />
        Social Engineering ist eine gefährliche Form des Cyberangriffs, bei dem Menschen durch psychologische Manipulation dazu gebracht werden, sensible Informationen preiszugeben oder schädliche Handlungen vorzunehmen. 
        Schutzmaßnahmen wie Misstrauen gegenüber ungewöhnlichen Anfragen und gründliche Verifikation können helfen, sich gegen solche Angriffe zu wehren.
    </p>
</div>


      <div className="video-container">
        <h2>Mehr über Social Engineering</h2>
        <YouTube videoId="2ZpktJJfWrc" opts={videoOptions} />
      </div>

      <div className="Quiz-button">
        <div className="containerQuizze">
          <Link className="Link" to="/QuizDragAndDrop2">
            <button className='button-87'>Draggable Quiz</button>
          </Link>
          <Link className="Link" to="/QuizChoice2">
            <button className='button-87'>Multiple- oder Single-Choice-Quiz</button>
          </Link>
          <Link className="Link" to="/QuizFillIn2">
            <button className='button-87'>Fill-In-Quiz</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
