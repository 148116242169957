import '../../App.css';
import { Link } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, setDoc } from "firebase/firestore";
import firestore from "../../firebase";


export const Einfuehrung2 = () => {


  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");

    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();

      if (userData && userData.unlockEin2 !== undefined) { 
        userData.unlockEin2 = true;
      } else {
        console.error("unlockEin1 nicht gefunden im Dokument");
      }

      await setDoc(userDoc, userData);
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  return (
    <div className="einfuehrung2">

      <Link className="Link" onClick={updateDatabase} to="/StartseiteLoggedIn" >
        <img className="logo" src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image" alt="Logo" />
      </Link>

      <div className="gefahr-text">
        <h1>Internet</h1>
        <p>
          In der heutigen digitalen Welt ist das Internet nicht mehr wegzudenken. Es ist das Rückgrat der modernen digitalen Kommunikation und ein zentrales Werkzeug im Alltag vieler Menschen und Unternehmen.
          <img class="team-image"  src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Internet-icon.svg/1200px-Internet-icon.svg.png"/>
          <p>
            <h4>Das Internet bietet zahlreiche Vorteile:</h4>
            <br></br>
            <li>Informationen in Sekunden abrufen</li>
            <br></br>
            <li>Einkäufe bequem erledigen</li>
            <br></br>
            <li>Weltweit kommunizieren</li>
            <br></br>
            <li>Soziale Medien nutzen</li>
            <br></br>
            <li>Websites besuchen</li>
            <br></br>
            <li>Und mehr</li>
          </p>
          Doch so groß die Vorteile auch sind, so bedeutend sind auch die Risiken und Gefahren, die das Internet mit sich bringt. Deshalb ist es unerlässlich, sich dieser Gefahren bewusst zu sein.
        </p>
      </div>
      <div className="gefahr-text">
       <p>
        <h4>Aus diesem Grund hat sich SafeSurf dazu entschieden eine Möglichkeit zu schaffen die Gefahren, die das Internet verbirgt, spielerisch kennenzulernen.</h4>
        <img class="team-image"  src="https://firebasestorage.googleapis.com/v0/b/internet-fuererschein.appspot.com/o/IF_Logo.svg?alt=media&token=fa845e98-fd2a-4bad-882e-5acf05a5b21c"/>
        </p>       
      </div>
    </div>
  );
};
