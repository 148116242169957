import React, { useEffect, useState } from 'react';
import { motion, useDragControls } from "framer-motion"
import { Reorder } from "framer-motion"
import ProgressBar from "@ramonak/react-progress-bar";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import firestore from "../firebase.js";

const Quiz6 = (props) => {
    const dragControls = useDragControls();

    const [correctItems, setCorrectItems] = useState([]);
    const [wrongItems, setWrongItems] = useState([]);
    const [texts, setText] = useState([
        { id: 1, text: 'Botmaster', correct: true },
        { id: 2, text: 'Firewall', correct: false },
        { id: 3, text: 'Phishing', correct: false },
        { id: 4, text: 'DDoS-Angriff', correct: true },
        { id: 5, text: 'Sandbox', correct: false },
        { id: 6, text: 'Trojaner', correct: false },
        { id: 7, text: 'Zombie', correct: true },
        // { id: 8, text: 'Antivirenprogramm', correct: false },

    ]);
    const [progress, setProgress] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [resultsDB, setResultsDB] = useState(0);
    const [maxResultsDB, setMaxResultsDB] = useState(0);  
    const [check, setCheck] = useState(false);

    useEffect(() => {
        updateProgress();
    }, [correctItems, wrongItems, texts]);

    const updateProgress = () => {
        const totalItems = correctItems.length + wrongItems.length + texts.length;
        const completedItems = correctItems.length + wrongItems.length;
        const progressPercent = (completedItems / totalItems) * 100;
        setProgress(progressPercent);
    };

    const handleDragEnd = (event, info, draggedText) => {
        const correctZone = document.querySelector('.correct-zone');
        const wrongZone = document.querySelector('.wrong-zone');
        wrongZone.style.border = '2px solid black';
        correctZone.style.border = '2px solid black';
        wrongZone.style.backgroundColor = '';
        correctZone.style.backgroundColor = '';

        if (correctZone) {
            const correctZoneRect = correctZone.getBoundingClientRect();
            const isOverCorrectZone = info.point.x > correctZoneRect.left && info.point.x < correctZoneRect.right && info.point.y > correctZoneRect.top && info.point.y < correctZoneRect.bottom;

            if (isOverCorrectZone && !correctItems.find(item => item.id === draggedText.id)) {
                setCorrectItems((prevItems) => [...prevItems, { id: draggedText.id, text: draggedText.text, correct: draggedText.correct }]);
                setWrongItems((prevText) => prevText.filter((t) => t.id !== draggedText.id));
                setText((prevText) => prevText.filter((t) => t.id !== draggedText.id));
            }
        }

        if (wrongZone && !wrongItems.find(item => item.id === draggedText.id)) {
            const wrongZoneRect = wrongZone.getBoundingClientRect();
            const isOverWrongZone = info.point.x > wrongZoneRect.left && info.point.x < wrongZoneRect.right && info.point.y > wrongZoneRect.top && info.point.y < wrongZoneRect.bottom;

            if (isOverWrongZone) {
                setWrongItems((prevItems) => [...prevItems, { id: draggedText.id, text: draggedText.text, correct: draggedText.correct }]);
                setCorrectItems((prevText) => prevText.filter((t) => t.id !== draggedText.id));
                setText((prevText) => prevText.filter((t) => t.id !== draggedText.id));
            }
        }
    };

    const onDrag = (event, info) => {
        const correctZone = document.querySelector('.correct-zone');
        const wrongZone = document.querySelector('.wrong-zone');

        if (correctZone) {
            const correctZoneRect = correctZone.getBoundingClientRect();
            const isOverCorrectZone = info.point.x > correctZoneRect.left && info.point.x < correctZoneRect.right && info.point.y > correctZoneRect.top && info.point.y < correctZoneRect.bottom;
            correctZone.style.border = isOverCorrectZone ? '5px solid green' : '2px solid black';
            correctZone.style.backgroundColor = isOverCorrectZone ? 'green' : '';
        }

        if (wrongZone) {
            const wrongZoneRect = wrongZone.getBoundingClientRect();
            const isOverWrongZone = info.point.x > wrongZoneRect.left && info.point.x < wrongZoneRect.right && info.point.y > wrongZoneRect.top && info.point.y < wrongZoneRect.bottom;
            wrongZone.style.border = isOverWrongZone ? '5px solid red' : '2px solid black';
            wrongZone.style.backgroundColor = isOverWrongZone ? 'red' : '';
        }
    };

    const deleteItem = (event, info, item, itemBoolean) => {
        if (itemBoolean) {
            setCorrectItems((prevItems) => prevItems.filter((t) => t.id !== item.id));
        } else {
            setWrongItems((prevItems) => prevItems.filter((t) => t.id !== item.id));
        }
        setText((prevText) => [...prevText, { id: item.id, text: item.text, correct: true }]);
    };

    const handleResult = () => {
        let correctAnswers = 0;
        let maxAnswers = 0;
        wrongItems.forEach(item => {
            maxAnswers++;
            if (!item.correct) {
                correctAnswers++;
            }
        });
        correctItems.forEach(item => {
            maxAnswers++;
            if (item.correct) {
                correctAnswers++;
            }
        });

        setResultsDB(correctAnswers);
        setMaxResultsDB(maxAnswers);

        props.onQuizResult({ totalQuestions: wrongItems.length + correctItems.length, rightAnswers: correctAnswers });
        setShowProgressBar(true);

        updateDatabase();
    }

    const updateDatabase = async () => {
        const ref = collection(firestore, "Users");
      
        try {
          const userDoc = doc(ref, getAuth().currentUser.email);
          const userDocSnap = await getDoc(userDoc);
      
          // Prüfe, ob das Dokument existiert
          if (!userDocSnap.exists()) {
            console.error("Benutzerdokument existiert nicht.");
            return;
          }
      
          const userData = userDocSnap.data();
          const currentStars = resultsDB;
          const currentMaxStars = maxResultsDB;
          
          // Vergleiche die aktuellen Ergebnisse mit den gespeicherten Ergebnissen
          if (!userData.starsGefahr6 || !userData.maxStarsGefahr6) {
            // Initialisiere die Arrays, wenn sie nicht existieren
            userData.starsGefahr6 = [];
            userData.maxStarsGefahr6 = [];
          }
      
          // Überprüfe, ob die aktuellen Ergebnisse besser sind als die gespeicherten Ergebnisse
          if (!userData.starsGefahr6[2] || currentStars > userData.starsGefahr6[2]) {
            userData.starsGefahr6[2] = currentStars;
          }
          
          if (!userData.maxStarsGefahr6[2] || currentMaxStars > userData.maxStarsGefahr6[2]) {
            userData.maxStarsGefahr6[2] = currentMaxStars;
          }
      
          // Speichern der aktualisierten Daten
          await setDoc(userDoc, userData);
          
        } catch (e) {
          console.error("Fehler beim Aktualisieren der Datenbank", e);
        }
      };

      useEffect(() => {
        if (check == true){
          handleResult();
          setCheck(false);
        }
      }, );
      

    return (
        <div className="Erklärungscard-Div">
            {/* Fortschrittsbalken ganz oben */}
            {showProgressBar && 
            <ProgressBar
            completed={Math.round((resultsDB / maxResultsDB) * 100)}  // Aufrunden
            bgColor="#06D001"
            baseBgColor="grey"
            labelColor="black"
            labelAlignment="center"
            labelSize="14px"
            progress={progress}
            />}
            <div style={{ border: '2px solid black', padding: '10px', minHeight: '20%' }}>
                <h2>Ordne die Links in die richtigen Kästen zu</h2>
                <div>
                    {texts.map((text) => (
                        <motion.div
                            style={{ cursor: 'grab', backgroundColor: '#333', border: '2px solid #666', color: 'white', borderRadius: '4px', padding: '8px', margin: '3px', userSelect: 'none', display: 'flex', alignItems: 'center' }}
                            key={text.id}
                            drag
                            dragControls={dragControls}
                            onDrag={(event, info) => onDrag(event, info)}
                            onDragEnd={(event, info) => handleDragEnd(event, info, text)}
                            dragElastic={1}
                            dragMomentum={false}
                            dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                            dragConstraints={{ left: -20, right: 30, top: -10, bottom: 30 }}
                        >
                            <svg style={{ width: '20px', marginRight: '8px', fill: 'white' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g>
                                    <circle cx="6" cy="6" r="2" />
                                    <circle cx="12" cy="6" r="2" />
                                    <circle cx="18" cy="6" r="2" />
                                    <circle cx="6" cy="12" r="2" />
                                    <circle cx="12" cy="12" r="2" />
                                    <circle cx="18" cy="12" r="2" />
                                    <circle cx="6" cy="18" r="2" />
                                    <circle cx="12" cy="18" r="2" />
                                    <circle cx="18" cy="18" r="2" />
                                </g>
                            </svg>
                            {text.text}
                        </motion.div>
                    ))}
                </div>
            </div>

            <div
                className="drop-zone correct-zone"
                style={{ border: '2px solid black', padding: '10px', minHeight: '15%' }}
                onDrag={(event, info) => onDrag(event, info)}
            >
                <h2>Richtig ✅</h2>
                <Reorder.Group style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} axis="y" values={correctItems} onReorder={setCorrectItems}>
                    {correctItems.map((item) => (
                        <Reorder.Item
                            key={item.id}
                            drag
                            dragControls={dragControls}
                            onDrag={(event, info) => onDrag(event, info)}
                            onDragEnd={(event, info) => handleDragEnd(event, info, item)}
                            dragElastic={1}
                            dragMomentum={false}
                            dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                            dragConstraints={{ left: -20, right: 30, top: -10, bottom: 30 }}
                            style={{ position: "relative", left: "-40px", width: "97%", cursor: 'grab', backgroundColor: '#333', border: '2px solid #666', color: 'white', borderRadius: '4px', padding: '8px', margin: '3px 0', userSelect: 'none', display: 'flex', alignItems: 'center' }}
                        >
                            <svg style={{ width: '20px', marginRight: '8px', fill: 'white' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g>
                                    <circle cx="6" cy="6" r="2" />
                                    <circle cx="12" cy="6" r="2" />
                                    <circle cx="18" cy="6" r="2" />
                                    <circle cx="6" cy="12" r="2" />
                                    <circle cx="12" cy="12" r="2" />
                                    <circle cx="18" cy="12" r="2" />
                                    <circle cx="6" cy="18" r="2" />
                                    <circle cx="12" cy="18" r="2" />
                                    <circle cx="18" cy="18" r="2" />
                                </g>
                            </svg>
                            {item.text}
                        </Reorder.Item>
                    ))}
                </Reorder.Group>
            </div>

            <div
                className="drop-zone wrong-zone"
                style={{ border: '2px solid black', padding: '10px', minHeight: '15%' }}
                onDrag={(event, info) => onDrag(event, info)}
            >
                <h2>Falsch❌</h2>
                <Reorder.Group style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} axis="y" values={correctItems} onReorder={setCorrectItems}>
                    {wrongItems.map((item) => (
                        <Reorder.Item
                            key={item.id}
                            drag
                            dragControls={dragControls}
                            onDrag={(event, info) => onDrag(event, info)}
                            onDragEnd={(event, info) => handleDragEnd(event, info, item)}
                            dragElastic={1}
                            dragMomentum={false}
                            dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                            dragConstraints={{ left: -20, right: 30, top: -10, bottom: 30 }}
                            style={{ position: "relative", left: "-40px", width: "97%", cursor: 'grab', backgroundColor: '#333', border: '2px solid #666', color: 'white', borderRadius: '4px', padding: '8px', margin: '3px 0', userSelect: 'none', display: 'flex', alignItems: 'center' }}
                        >
                            <svg style={{ width: '20px', marginRight: '8px', fill: 'white' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g>
                                    <circle cx="6" cy="6" r="2" />
                                    <circle cx="12" cy="6" r="2" />
                                    <circle cx="18" cy="6" r="2" />
                                    <circle cx="6" cy="12" r="2" />
                                    <circle cx="12" cy="12" r="2" />
                                    <circle cx="18" cy="12" r="2" />
                                    <circle cx="6" cy="18" r="2" />
                                    <circle cx="12" cy="18" r="2" />
                                    <circle cx="18" cy="18" r="2" />
                                </g>
                            </svg>
                            {item.text}
                        </Reorder.Item>
                    ))}
                </Reorder.Group>
            </div>

            {texts.length !== 0 ? (
                <button style={{ position: 'absolute', bottom: '-85px', left: '50%', transform: 'translateX(-50%)' }} className={'button-87-disabled'} disabled={true}>
                    Es fehlen noch die Antworten
                </button>
            ) : (
                <button
                className='button-87'
                onClick={() => {
                    handleResult();   
                  setCheck(true);       
                }}
              >
                Überprüfen
              </button>            )}
        </div>
    );
};

export default Quiz6;
