import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import firestore from "../firebase.js";

const QuizConnection3 = (props) => {
  const [leftTexts, setLeftTexts] = useState([
    { id: 1, left: true, text: 'Phishing per SMS', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 6 },
    { id: 2, left: true, text: 'Zielgerichtet auf Führungskräfte', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 3 },
    { id: 3, left: true, text: 'Ködern mit einem attraktiven Angebot', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 4 },
    { id: 4, left: true, text: 'Gefälschte E-Mail-Kopie', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 2 },
    { id: 5, left: true, text: 'Phishing per Telefonanruf', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 5 },
    { id: 6, left: true, text: 'Angriff auf eine bestimmte Person', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 1 }
  ]);

  const [rightTexts, setRightTexts] = useState([
    { id: 1, left: false, text: 'Spear Phishing', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 6 },
    { id: 2, left: false, text: 'Clone Phishing', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 4 },
    { id: 3, left: false, text: 'Whaling', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 2 },
    { id: 4, left: false, text: 'Baiting', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 3 },
    { id: 5, left: false, text: 'Vishing', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 5 },
    { id: 6, left: false, text: 'Smishing', ausgewählt: false, color: "black", opacity: 100, rightAnswerID: 1 }
  ]);

  const [farben, setFarben] = useState([
    { Farbe: "#3498db" }, // Blau
    { Farbe: "#e74c3c" }, // Rot
    { Farbe: "#2ecc71" }, // Grün
    { Farbe: "#f39c12" }, // Orange
    { Farbe: "#9b59b6" }, // Lila
    { Farbe: "#1abc9c" }  // Türkis
  ]);

  const [firstSelectedText, setFirstSelectedText] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [gameOver, setGameOver] = useState(false); // Neuer Zustand für das Spielende
  const maxAttempts = 9;
  const maxAnswer = 6;
  const [resultsDB, setResultsDB] = useState(0);
  const [maxResultsDB, setMaxResultsDB] = useState(0);  


  const resetGame = () => {
    setLeftTexts(leftTexts.map(text => ({ ...text, ausgewählt: false, color: "black", opacity: 100 })));
    setRightTexts(rightTexts.map(text => ({ ...text, ausgewählt: false, color: "black", opacity: 100 })));
    setFirstSelectedText("");
    setAttempts(0);
    setShowProgress(false);
    setCorrectAnswers(0);
    setGameOver(false);
  };

  useEffect(() => {
    if (attempts >= maxAttempts) {
      setGameOver(true);
    }
  }, [attempts]);

  const areAllSelected = (leftTexts, rightTexts) => {
    const leftAllSelected = leftTexts.every(item => item.ausgewählt);
    const rightAllSelected = rightTexts.every(item => item.ausgewählt);
    return leftAllSelected && rightAllSelected;
  };

  const handleClick = (text) => {
    if (attempts >= maxAttempts || gameOver) return;

    if (firstSelectedText === "") {
      const randomIndex = Math.floor(Math.random() * farben.length);
      const randomColor = farben[randomIndex].Farbe;
      text.color = randomColor;
      setFirstSelectedText(text);
    } else if (firstSelectedText.id === text.id && text.left === firstSelectedText.left) {
      text.color = "black";
      setFirstSelectedText("");
    } else {
      if (text.left && firstSelectedText.rightAnswerID === text.id && !firstSelectedText.left) {
        setLeftTexts(leftTexts.map(item => item.id === text.id ? { ...item, ausgewählt: true, opacity: 0 } : item));
        setRightTexts(rightTexts.map(item => item.id === firstSelectedText.id ? { ...item, ausgewählt: true, opacity: 0 } : item));
        setFirstSelectedText("");
        setCorrectAnswers(prev => prev + 1);
      } else if (!text.left && firstSelectedText.rightAnswerID === text.id && firstSelectedText.left) {
        setRightTexts(rightTexts.map(item => item.id === text.id ? { ...item, ausgewählt: true, opacity: 0 } : item));
        setLeftTexts(leftTexts.map(item => item.id === firstSelectedText.id ? { ...item, ausgewählt: true, opacity: 0 } : item));
        setFirstSelectedText("");
        setCorrectAnswers(prev => prev + 1);
      }

      setAttempts(prevAttempts => prevAttempts + 1);
      setResultsDB(correctAnswers);
      setMaxResultsDB(maxAnswer);
    }

  };

  const handleResult = () => {
    setShowProgress(true);
    setGameOver(true); // Setze das Spiel als beendet

    updateDatabase();
    
  };

  const progressBarWidth = (correctAnswers / leftTexts.length) * 100;

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");
  
    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
  
      // Prüfe, ob das Dokument existiert
      if (!userDocSnap.exists()) {
        console.error("Benutzerdokument existiert nicht.");
        return;
      }
  
      const userData = userDocSnap.data();
      const currentStars = resultsDB;
      const currentMaxStars = maxResultsDB;
      
      // Vergleiche die aktuellen Ergebnisse mit den gespeicherten Ergebnissen
      if (!userData.starsGefahr3 || !userData.maxStarsGefahr3) {
        // Initialisiere die Arrays, wenn sie nicht existieren
        userData.starsGefahr3 = [];
        userData.maxStarsGefahr3 = [];
      }
  
      // Überprüfe, ob die aktuellen Ergebnisse besser sind als die gespeicherten Ergebnisse
      if (!userData.starsGefahr3[1] || currentStars > userData.starsGefahr3[1]) {
        userData.starsGefahr3[1] = currentStars;
      }
      
      if (!userData.maxStarsGefahr3[1] || currentMaxStars > userData.maxStarsGefahr3[1]) {
        userData.maxStarsGefahr3[1] = currentMaxStars;
      }
  
      // Speichern der aktualisierten Daten
      await setDoc(userDoc, userData);
      
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };
  


  return (
    <div className="Erklärungscard-Div" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      
      <h2 style={{ color: 'black' }}>Kombiniere die richtigen Aussagen miteinander </h2>

      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ border: "none", flex: 1, marginRight: "5px" }}>
          {leftTexts.map((text, index) => (
            <motion.div
              animate={{ opacity: text.opacity }}
              className='ConnectionDiv'
              onClick={() => handleClick(text)}
              key={index}
              style={{ backgroundColor: leftTexts[index].color, marginBottom: '10px' }}
            >
              <p style={{ margin: '0px 0', padding: '10px', color: 'white' }}>{text.text}</p>
            </motion.div>
          ))}
        </div>

        <div style={{ flex: 1, marginLeft: "5px" }}>
          {rightTexts.map((text, index) => (
            <motion.div
              animate={{ opacity: text.opacity }}
              className='ConnectionDiv'
              onClick={() => handleClick(text)}
              key={index}
              style={{ opacity: text.opacity, backgroundColor: rightTexts[index].color, marginBottom: '10px' }}
            >
              <p style={{ margin: '0px 0', padding: '10px', color: 'white' }}>{text.text}</p>
            </motion.div>
          ))}
        </div>
      </div>

      {!areAllSelected(leftTexts, rightTexts) ? (
        <button style={{ position: 'absolute', bottom: '15px', left: '50%', transform: 'translateX(-50%)' }} className={'button-87-disabled'} disabled={true}>
          Es fehlen noch die Antworten
        </button>
      ) : (
        <button onClick={handleResult} style={{ position: 'absolute', bottom: '-5px', left: '50%', transform: 'translateX(-50%)' }} className={'button-87'}>
          Weiter
        </button>
      )}

      {showProgress && (
        <div style={{ marginTop: '20px', width: '80%', height: '20px', backgroundColor: '#e0e0e0' }}>
          <div style={{ width: `${progressBarWidth}%`, height: '100%', backgroundColor: '#3498db' }}></div>
        </div>
      )}

      {showProgress && (
        <p>{correctAnswers} von {leftTexts.length} richtig</p>
      )}

      <p>Versuche: {attempts} / {maxAttempts}</p>

      {gameOver && (
        <button onClick={resetGame} style={{ position: 'absolute', bottom: '-5px', left: '50%', transform: 'translateX(-50%)' }} className={'button-87'}>
          Erneut spielen
        </button>
      )}
    </div>
  );
};

export default QuizConnection3;
