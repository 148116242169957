import { useState } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { AuthContextProvider, useAuthState } from './firebase';
import { Startseite } from "./Pages/Startseite";
import { Login } from './Pages/Login';
import { Signup } from './Pages/Signup';
import { PasswortVergessen } from './Pages/PasswortVergessen';

import {GefahrTemplate} from "./Pages/GefahrTemplate";

import { KostenloseVariante } from './Pages/KostenloseVariante';
import { StartseiteLoggedIn } from './Pages/StartseiteLoggedIn';
import { KostenpflichtigeVariante } from './Pages/KostenpflichtigeVariante';
import { AGB } from './Pages/Startseite/AGB';
import { UeberUns } from './Pages/Startseite/UeberUns';

import { Gefahr1 } from './Pages/Gefahr-1/Gefahr1';
import { Gefahr2 } from './Pages/Gefahr-2/Gefahr2';
import { Gefahr3 } from './Pages/Gefahr-3/Gefahr3';
import { Gefahr4 } from './Pages/Gefahr-4/Gefahr4';
import { Gefahr5 } from './Pages/Gefahr-5/Gefahr5';
import { Gefahr6 } from './Pages/Gefahr-6/Gefahr6';

import { QuizGesamtuebung } from './Pages/Gesamtuebung/QuizGesamtuebung';
import { QuizGesamtuebung2 } from './Pages/Gesamtuebung2/QuizGesamtuebung2';

import { QuizLueckentext1 } from './Pages/Gefahr-1/QuizLueckentext1';
import { QuizChoice1 } from './Pages/Gefahr-1/QuizChoice1';
import { QuizFillIn1 } from './Pages/Gefahr-1/QuizFillIn1';

import { QuizDragAndDrop2 } from './Pages/Gefahr-2/QuizDragAndDrop2';
import { QuizChoice2 } from './Pages/Gefahr-2/QuizChoice2';
import { QuizFillIn2 } from './Pages/Gefahr-2/QuizFillIn2';

import { QuizLueckentext3 } from './Pages/Gefahr-3/QuizLueckentext3';
import { QuizMatching3 } from './Pages/Gefahr-3/QuizMatching3';
import { QuizChoice3 } from './Pages/Gefahr-3/QuizChoice3';

import { QuizLueckentext4 } from './Pages/Gefahr-4/QuizLueckentext4';
import { QuizDragAndDrop4 } from './Pages/Gefahr-4/QuizDragAndDrop4';
import { QuizMatching4 } from './Pages/Gefahr-4/QuizMatching4';

import { QuizLueckentext5 } from './Pages/Gefahr-5/QuizLueckentext5';
import { QuizChoice5 } from './Pages/Gefahr-5/QuizChoice5';
import { QuizDragAndDrop5 } from './Pages/Gefahr-5/QuizDragAndDrop5';

import { QuizDragAndDrop6 } from './Pages/Gefahr-6/QuizDragAndDrop6';
import { QuizLueckentext6 } from './Pages/Gefahr-6/QuizLueckentext6';
import { QuizMatching6 } from './Pages/Gefahr-6/QuizMatching6';

import { QuizLueckentextGesamtuebung } from './Pages/Gesamtuebung/QuizLueckentextGesamtuebung';
import { QuizChoiceGesamtuebung } from './Pages/Gesamtuebung/QuizChoiceGesamtuebung';
import { QuizFillInGesamtuebung } from './Pages/Gesamtuebung/QuizFillInGesamtuebung';

import { QuizLueckentextGesamtuebung2 } from './Pages/Gesamtuebung2/QuizLueckentextGesamtuebung2';
import { QuizChoiceGesamtuebung2 } from './Pages/Gesamtuebung2/QuizChoiceGesamtuebung2';
import { QuizFillInGesamtuebung2 } from './Pages/Gesamtuebung2/QuizFillInGesamtuebung2';

import { Daily } from './Pages/Daily';


import { Einfuehrung1 } from './Pages/Einfuehrung/Einfuehrung1';
import { Einfuehrung2 } from './Pages/Einfuehrung/Einfuehrung2';

function App() {
  const [urlStr, setUrlStr] = useState("/startseite");
  const [progress, setProgress] = useState({
    QuizDragAndDrop1: 0,
    QuizLueckentext1: 0,
    QuizMatching1: 0,
    QuizChoice1: 0,
    QuizFillIn1: 0,
  });

  const updateProgress = (quizName, newProgress) => {
    setProgress(prevProgress => ({
      ...prevProgress,
      [quizName]: newProgress,
    }));
  };

  const AuthenticatedRoute = ({ component: C, ...props }) => {
    const { isAuthenticated } = useAuthState();

    return (
      <Route
        {...props}
        render={routeProps =>
          isAuthenticated ? <C {...routeProps} /> : <></>
        }
      />
    );
  };

  const UnauthenticatedRoute = ({ component: C, ...props }) => {
    const { isAuthenticated } = useAuthState();
    console.log(`UnauthenticatedRoute: ${isAuthenticated}`);
    return (
      <Route
        {...props}
        render={routeProps =>
          !isAuthenticated ? <C {...routeProps} /> : <Redirect to={urlStr} />
        }
      />
    );
  };

  return (
    <AuthContextProvider>
      <Router>
        <Switch>
          <AuthenticatedRoute path="/startseite" component={StartseiteLoggedIn} />

          <UnauthenticatedRoute exact path="/StartseiteLoggedIn" component={StartseiteLoggedIn} />
          <UnauthenticatedRoute exact path="/KostenloseVariante" component={KostenloseVariante} />
          <UnauthenticatedRoute exact path="/" component={Startseite} />
          <UnauthenticatedRoute exact path="/Login" component={Login} />
          <UnauthenticatedRoute exact path="/Signup" component={Signup} />
          <UnauthenticatedRoute exact path="/Help" component={PasswortVergessen} />
          <UnauthenticatedRoute exact path="/KostenpflichtigeVariante" component={KostenpflichtigeVariante} />

          <UnauthenticatedRoute exact path="/UeberUns" component={UeberUns} />
          <UnauthenticatedRoute exact path="/AGB" component={AGB} />

          <AuthenticatedRoute exact path="/Gefahrtemplate" component={GefahrTemplate} />

          <AuthenticatedRoute exact path="/Gefahr1" component={() => <Gefahr1 updateProgress={updateProgress} />} />
          <AuthenticatedRoute exact path="/Gefahr2" component={Gefahr2} />
          <AuthenticatedRoute exact path="/Gefahr3" component={Gefahr3} />
          <AuthenticatedRoute exact path="/Gefahr4" component={Gefahr4} />
          <AuthenticatedRoute exact path="/Gefahr5" component={Gefahr5} />
          <AuthenticatedRoute exact path="/Gefahr6" component={Gefahr6} />

          <AuthenticatedRoute exact path="/QuizGesamtuebung" component={QuizGesamtuebung} />
          <AuthenticatedRoute exact path="/QuizGesamtuebung2" component={QuizGesamtuebung2} />

          <AuthenticatedRoute exact path="/QuizLueckentext1" component={() => <QuizLueckentext1 updateProgress={updateProgress} />} />
          <AuthenticatedRoute exact path="/QuizChoice1" component={() => <QuizChoice1 updateProgress={updateProgress} />} />
          <AuthenticatedRoute exact path="/QuizFillIn1" component={() => <QuizFillIn1 updateProgress={updateProgress} />} />

          <AuthenticatedRoute exact path="/QuizDragAndDrop2" component={QuizDragAndDrop2} />
          <AuthenticatedRoute exact path="/QuizChoice2" component={QuizChoice2} />
          <AuthenticatedRoute exact path="/QuizFillIn2" component={QuizFillIn2} />

          <AuthenticatedRoute exact path="/QuizMatching3" component={QuizMatching3} />
          <AuthenticatedRoute exact path="/QuizLueckentext3" component={QuizLueckentext3} />
          <AuthenticatedRoute exact path="/QuizChoice3" component={QuizChoice3} />

          <AuthenticatedRoute exact path="/QuizDragAndDrop4" component={QuizDragAndDrop4} />
          <AuthenticatedRoute exact path="/QuizMatching4" component={QuizMatching4} />
          <AuthenticatedRoute exact path="/QuizLueckentext4" component={QuizLueckentext4} />

          <AuthenticatedRoute exact path="/QuizChoice5" component={QuizChoice5} />
          <AuthenticatedRoute exact path="/QuizDragAndDrop5" component={QuizDragAndDrop5} />
          <AuthenticatedRoute exact path="/QuizLueckentext5" component={QuizLueckentext5} />

          <AuthenticatedRoute exact path="/QuizDragAndDrop6" component={QuizDragAndDrop6} />
          <AuthenticatedRoute exact path="/QuizLueckentext6" component={QuizLueckentext6} />
          <AuthenticatedRoute exact path="/QuizMatching6" component={QuizMatching6} />

          <AuthenticatedRoute exact path="/QuizLueckentextGesamtuebung" component={QuizLueckentextGesamtuebung} />
          <AuthenticatedRoute exact path="/QuizChoiceGesamtuebung" component={QuizChoiceGesamtuebung} />
          <AuthenticatedRoute exact path="/QuizFillInGesamtuebung" component={QuizFillInGesamtuebung} />

          <AuthenticatedRoute exact path="/QuizLueckentextGesamtuebung2" component={QuizLueckentextGesamtuebung2} />
          <AuthenticatedRoute exact path="/QuizChoiceGesamtuebung2" component={QuizChoiceGesamtuebung2} />
          <AuthenticatedRoute exact path="/QuizFillInGesamtuebung2" component={QuizFillInGesamtuebung2} />

          <AuthenticatedRoute exact path="/Daily" component={Daily}/>

          <AuthenticatedRoute exact path="/Einfuehrung1" component={Einfuehrung1}/>
          <AuthenticatedRoute exact path="/Einfuehrung2" component={Einfuehrung2}/>

        </Switch>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
