import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, setDoc } from "firebase/firestore";
import firestore from "../../firebase";
import ProgressBar from "@ramonak/react-progress-bar";

export const Gefahr6 = () => {
  const [progress, setProgress] = useState({
    QuizDragAndDrop1: 0,
    QuizLueckentext1: 0,
    QuizMatching1: 0,
    QuizChoice1: 0,
    QuizFillIn1: 0,
  });

  const [progressDB, setProgressDB] = useState(0); // Initialisiert mit 0
  const [maxProgressDB, setMaxProgressDB] = useState(0); // Initialisiert mit 0

  const updateProgress = (quizName, newProgress) => {
    setProgress(prevProgress => ({
      ...prevProgress,
      [quizName]: newProgress,
    }));
  };

  const totalProgress = Object.values(progress).reduce((acc, cur) => acc + cur, 0) / 5;

  const videoOptions = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  const getDocument = async () => {
    try {
      const docRef = doc(firestore, "Users", getAuth().currentUser.email);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data();

      if (userData && userData.starsGefahr6 !== undefined) {
        setProgressDB(userData.starsGefahr6[0] + userData.starsGefahr6[1] + userData.starsGefahr6[2]);
        setMaxProgressDB(userData.maxStarsGefahr6[0] + userData.maxStarsGefahr6[1] + userData.maxStarsGefahr6[2]);
      } else {
        console.error("starsGefahr1 nicht gefunden im Dokument");
      }

    } catch (e) {
      console.error("Fehler beim Abrufen des Dokuments aus der Datenbank", e);
    }
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");

    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();

      if (progressDB / maxProgressDB > 0.4){
        userData.unlockGefahr6 = true;
      }
      else {
        userData.unlockGefahr6 = false;
      }

      await setDoc(userDoc, userData);
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  // useEffect wird hier eingebaut
  useEffect(() => {
    getDocument();
  }, ); // Leeres Abhängigkeitsarray sorgt dafür, dass getDocument() nur einmal bei der ersten Render ausgeführt wird.


  return (
    <div className="gefahr2">

      <div className="progressBar" >
        <ProgressBar
          completed={Math.round((progressDB / maxProgressDB) * 100)}  // Aufrunden
          bgColor="#06D001"
          baseBgColor="grey"
          labelColor="black"
          labelAlignment="center"
          labelSize="14px"
        />
      </div>

      <Link className="Link" onClick={updateDatabase} to="/StartseiteLoggedIn" >
        <img className="logo" src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image" alt="Logo" />
      </Link>

      <div className="gefahr-text">
    <h1>Botnetze</h1>
    
    <p><strong>Definition:<br /><br /></strong>
        Ein Botnetz ist ein Netzwerk aus mehreren Computern oder Geräten, die mit schädlicher Software (Malware) infiziert wurden und von einem Angreifer, dem sogenannten Botmaster, ferngesteuert werden. Diese infizierten Geräte, auch "Bots" oder "Zombies" genannt, führen unbemerkt bösartige Aktivitäten aus.
    </p>
    
    <p><strong>Hauptfunktionen und Gefahren von Botnetzen:</strong>
    
    <ul><br />
        <li><strong>DDoS-Angriffe (Distributed Denial of Service):</strong><br /><br />
            <strong>Beschreibung:</strong><br />Botnetze werden häufig genutzt, um DDoS-Angriffe durchzuführen. Dabei senden die infizierten Geräte massenhaft Anfragen an einen Server, um diesen zu überlasten und dadurch den Zugriff auf eine Website oder einen Online-Dienst zu blockieren.<br /><br />
            <strong>Gefahr:</strong><br />Diese Angriffe können Websites und Online-Dienste lahmlegen, was zu finanziellen Verlusten und Rufschädigung für Unternehmen führt.
        </li><br />
        
        <li><strong>Spam-Versand:</strong><br /><br />
            <strong>Beschreibung:</strong><br />Ein Botnetz kann zum massenhaften Versand von Spam-E-Mails eingesetzt werden, oft mit betrügerischen oder schädlichen Inhalten wie Phishing-Versuchen oder Links zu Malware.<br /><br />
            <strong>Gefahr:</strong><br />Spam-Versand kann zur Verbreitung von Malware beitragen und Phishing-Angriffe unterstützen, die darauf abzielen, persönliche Daten zu stehlen.
        </li><br />
        
        <li><strong>Diebstahl von Daten:</strong><br /><br />
            <strong>Beschreibung:</strong><br />Botnetze können genutzt werden, um sensible Daten wie Passwörter, Kreditkarteninformationen oder Unternehmensgeheimnisse von den infizierten Geräten zu stehlen.<br /><br />
            <strong>Gefahr:</strong><br />Der Diebstahl solcher Daten kann zu Identitätsdiebstahl, finanziellem Verlust und ernsthaften Sicherheitsverletzungen führen.
        </li><br />
        
        <li><strong>Klickbetrug:</strong><br /><br />
            <strong>Beschreibung:</strong><br />Botnetze können verwendet werden, um gefälschte Klicks auf Online-Werbung zu generieren. Dies führt zu unrechtmäßigen Einnahmen für den Angreifer und höheren Kosten für die Werbetreibenden.<br /><br />
            <strong>Gefahr:</strong><br />Klickbetrug untergräbt die Integrität von Online-Werbung und verursacht finanzielle Verluste für Werbetreibende.
        </li><br />
        
        <li><strong>Verbreitung von Malware:</strong><br /><br />
            <strong>Beschreibung:</strong><br />Ein Botnetz kann dazu verwendet werden, weitere Malware auf die infizierten Geräte oder andere Systeme im Netzwerk zu verteilen.<br /><br />
            <strong>Gefahr:</strong><br />Dies verstärkt die Auswirkungen des Botnetzes, da mehr Geräte infiziert und für weitere schädliche Zwecke genutzt werden können.
        </li>
    </ul></p>
    
    <p><strong>Wie Botnetze entstehen:</strong>
    
    <ul><br />
        <li><strong>Infektion durch Malware:</strong><br />
            Geräte werden meist durch schädliche Software wie Viren, Würmer oder Trojaner infiziert. Diese Malware gelangt oft durch Phishing-E-Mails, infizierte Webseiten oder unsichere Downloads auf das Gerät.
        </li><br />
        
        <li><strong>Fernsteuerung:</strong><br />
            Sobald ein Gerät infiziert ist, kann der Botmaster es aus der Ferne steuern, um es Teil seines Botnetzes zu machen.
        </li>
    </ul></p>
    
    <p><strong>Schutzmaßnahmen gegen Botnetze:</strong>
    
    <ul><br />
        <li><strong>Aktualisierte Software:</strong><br />
            Regelmäßige Updates für Betriebssysteme, Browser und Anwendungen, um Sicherheitslücken zu schließen.
        </li><br />
        
        <li><strong>Sichere Passwörter:</strong><br />
            Verwendung starker, einzigartiger Passwörter für alle Online-Konten und Geräte, um unbefugten Zugriff zu verhindern.
        </li><br />
        
        <li><strong>Antivirenprogramme:</strong><br />
            Einsatz von zuverlässiger Antivirensoftware, die in der Lage ist, bekannte Malware zu erkennen und zu entfernen.
        </li><br />
        
        <li><strong>Vorsicht bei Downloads:</strong><br />
            Keine Software aus unsicheren Quellen herunterladen und bei verdächtigen E-Mail-Anhängen oder Links vorsichtig sein.
        </li><br />
        
        <li><strong>Netzwerküberwachung:</strong><br />
            Einsatz von Tools zur Überwachung des Netzwerks, um verdächtige Aktivitäten frühzeitig zu erkennen.
        </li>
    </ul></p>
    
    <p><strong>Zusammenfassung:<br /><br /></strong>
        Botnetze sind ein ernsthaftes Sicherheitsrisiko, da sie eine große Anzahl von infizierten Geräten für kriminelle Zwecke ausnutzen können. Durch den Einsatz von Schutzmaßnahmen und eine wachsamere Online-Nutzung kann das Risiko, Teil eines Botnetzes zu werden, erheblich reduziert werden.
    </p>
</div>


      <div className="video-container">
        <h2>Youtube Video</h2>
        <YouTube videoId="_jUzzM2umhs" opts={videoOptions} />
      </div>

      <div className="Quiz-button">
        <div className="containerQuizze">
          <Link className="Link" to="/QuizMatching6">
            <button className='button-87'>Kombiniere Quiz</button>
          </Link>
          <Link className="Link" to="/QuizLueckentext6">
            <button className='button-87'>Lückentext Quiz</button>
          </Link>
          <Link className="Link" to="/QuizDragAndDrop6">
            <button className='button-87'>Draggable Quiz</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
