import { useState, useEffect, useCallback } from 'react';
import '../App.css';
import { getAuth, signOut } from 'firebase/auth';
import { useAuthState } from '../firebase';
import { doc, getDoc, query, collection, where, getDocs, setDoc } from "firebase/firestore";
import firestore from "../firebase";
import { motion } from 'framer-motion';
import fox1 from "../Json/fox1.json";
import fox2 from "../Json/fox2.json";
import fox3 from "../Json/fox3.json";
import fox4 from "../Json/fox4.json";
import Lottie from "lottie-react";
import WeeklyQuizKachel from '../Jsx-Components/WeeklyQuizKachel';
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
const auth = getAuth();

export const StartseiteLoggedIn = () => {
  let counter = 0;
  const [progressDeleted, setProgressDeleted] = useState(false);
  const [username, setUsername] = useState();
  const [registerdate, setRegisterdate] = useState();
  const [showLargeBubble, setShowLargeBubble] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false); // State for profile modal
  const [showLeaderboardModal, setShowLeaderboardModal] = useState(false); // State for leaderboard modal
  const [showWeeklyQuizKachel, setShowWeeklyQuizKachel] = useState(false); // State for weekly quiz modal
  const [showInfoModal, setShowInfoModal] = useState(false); // State for weekly quiz modal
  const { isAuthenticated } = useAuthState();
  const [buttonindex, setButtonindex] = useState(0);
  const [allDocs, setAllDocs] = useState([]);
  const [registerDate, setRegisterDate] = useState();

  const [progressDB1, setProgressDB1] = useState(0); // Gefahr 1
  const [maxProgressDB1, setMaxProgressDB1] = useState(0); // Gefahr 1
  const [progressDB2, setProgressDB2] = useState(0); // Gefahr 2
  const [maxProgressDB2, setMaxProgressDB2] = useState(0); // Gefahr 2
  const [progressDB3, setProgressDB3] = useState(0); // Gefahr 3
  const [maxProgressDB3, setMaxProgressDB3] = useState(0); // Gefahr 3
  const [progressDB4, setProgressDB4] = useState(0); // Gefahr 4
  const [maxProgressDB4, setMaxProgressDB4] = useState(0); // Gefahr 4
  const [progressDB5, setProgressDB5] = useState(0); // Gefahr 5
  const [maxProgressDB5, setMaxProgressDB5] = useState(0); // Gefahr 5
  const [progressDB6, setProgressDB6] = useState(0); // Gefahr 6
  const [maxProgressDB6, setMaxProgressDB6] = useState(0); // Gefahr 6

  const [progressGDB1, setProgressGDB1] = useState(0); // Gesamtuebung 1
  const [maxProgressGDB1, setMaxProgressGDB1] = useState(0); // Gesamtuebung 1
  const [progressGDB2, setProgressGDB2] = useState(0); // Gesamtuebung 2
  const [maxProgressGDB2, setMaxProgressGDB2] = useState(0); // Gesamtuebung 2

  const [unlockEin1, setUnlockEin1] = useState(false); // Einführung 1
  const [unlockEin2, setUnlockEin2] = useState(false); // Einführung 2 

  const [unlockGefahr1, setUnlockGefahr1] = useState(false); // Gefahr 1
  const [unlockGefahr2, setUnlockGefahr2] = useState(false); // Gefahr 2
  const [unlockGefahr3, setUnlockGefahr3] = useState(false); // Gefahr 3
  const [unlockGefahr4, setUnlockGefahr4] = useState(false); // Gefahr 4
  const [unlockGefahr5, setUnlockGefahr5] = useState(false); // Gefahr 5
  const [unlockGefahr6, setUnlockGefahr6] = useState(false); // Gefahr 6

  const [unlockGesamt1, setUnlockGesamt1] = useState(false); // Gesamt 1
  const [unlockGesamt2, setUnlockGesamt2] = useState(false); // Gesamt 2

  const [championsBadge, setChampionsBadge] = useState(false); // Champions Badge 
  const [top3Badge, setTop3Badge] = useState(false); // Top 3 Badge

  const [progressDBVonAllem, setProgressDBVonAllem] = useState(0); // Initialisiert mit 0
  const [maxProgressDBVonAllem, setMaxProgressDBVonAllem] = useState(0); // Initialisiert mit 0
  const [daily, setDaily] = useState(false); // Top 3 Badge
  const [kostenpflichtig, setKostenpflichtig] = useState(false); // Top 3 Badge




  const getDocument = async () => {
   
    const docRef = doc(firestore, "Users", getAuth().currentUser.email);
    
    await getDoc(docRef).then((doc) => {
      if(getAuth().currentUser.displayName){
        setUsername(getAuth().currentUser.displayName);
        setRegisterdate(getAuth().currentUser.Registerdate);
      }else{
        setUsername(doc.get("Username"));
      }
    
    });

    try {
      const docRef = doc(firestore, "Users", getAuth().currentUser.email);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data();

        setProgressDB1(userData.starsGefahr1[0] + userData.starsGefahr1[1] + userData.starsGefahr1[2]);
        setMaxProgressDB1(userData.maxStarsGefahr1[0] + userData.maxStarsGefahr1[1] + userData.maxStarsGefahr1[2]);
        setProgressDB2(userData.starsGefahr2[0] + userData.starsGefahr2[1] + userData.starsGefahr2[2]);
        setMaxProgressDB2(userData.maxStarsGefahr2[0] + userData.maxStarsGefahr2[1] + userData.maxStarsGefahr2[2]);
        setProgressDB3(userData.starsGefahr3[0] + userData.starsGefahr3[1] + userData.starsGefahr3[2]);
        setMaxProgressDB3(userData.maxStarsGefahr3[0] + userData.maxStarsGefahr3[1] + userData.maxStarsGefahr3[2]);
        setProgressDB4(userData.starsGefahr4[0] + userData.starsGefahr4[1] + userData.starsGefahr4[2]);
        setMaxProgressDB4(userData.maxStarsGefahr4[0] + userData.maxStarsGefahr4[1] + userData.maxStarsGefahr4[2]);
        setProgressDB5(userData.starsGefahr5[0] + userData.starsGefahr5[1] + userData.starsGefahr5[2]);
        setMaxProgressDB5(userData.maxStarsGefahr5[0] + userData.maxStarsGefahr5[1] + userData.maxStarsGefahr5[2]);
        setProgressDB6(userData.starsGefahr6[0] + userData.starsGefahr6[1] + userData.starsGefahr6[2]);
        setMaxProgressDB6(userData.maxStarsGefahr6[0] + userData.maxStarsGefahr6[1] + userData.maxStarsGefahr6[2]);

        setProgressGDB1(userData.starsGesamt1[0] + userData.starsGesamt1[1] + userData.starsGesamt1[2]);
        setMaxProgressGDB1(userData.maxStarsGesamt1[0] + userData.maxStarsGesamt1[1] + userData.maxStarsGesamt1[2]);
        setProgressGDB2(userData.starsGesamt2[0] + userData.starsGesamt2[1] + userData.starsGesamt2[2]);
        setMaxProgressGDB2(userData.maxStarsGesamt2[0] + userData.maxStarsGesamt2[1] + userData.maxStarsGesamt2[2]);

        setProgressDBVonAllem(userData.starsGefahr1[0] + userData.starsGefahr1[1] + userData.starsGefahr1[2] + userData.starsGefahr2[0] + userData.starsGefahr2[1] + userData.starsGefahr2[2] + userData.starsGefahr3[0] + userData.starsGefahr3[1] + userData.starsGefahr3[2] + userData.starsGefahr4[0] + userData.starsGefahr4[1] + userData.starsGefahr4[2] + userData.starsGefahr5[0] + userData.starsGefahr5[1] + userData.starsGefahr5[2] + userData.starsGefahr6[0] + userData.starsGefahr6[1] + userData.starsGefahr6[2] + userData.starsGesamt1[0] + userData.starsGesamt1[1] + userData.starsGesamt1[2] + userData.starsGesamt2[0] + userData.starsGesamt2[1] + userData.starsGesamt2[2]);
        setMaxProgressDBVonAllem(userData.maxStarsGefahr1[0] + userData.maxStarsGefahr1[1] + userData.maxStarsGefahr1[2] + userData.maxStarsGefahr2[0] + userData.maxStarsGefahr2[1] + userData.maxStarsGefahr2[2] + userData.maxStarsGefahr3[0] + userData.maxStarsGefahr3[1] + userData.maxStarsGefahr3[2] + userData.maxStarsGefahr4[0] + userData.maxStarsGefahr4[1] + userData.maxStarsGefahr4[2] + userData.maxStarsGefahr5[0] + userData.maxStarsGefahr5[1] + userData.maxStarsGefahr5[2] + userData.maxStarsGefahr6[0] + userData.maxStarsGefahr6[1] + userData.maxStarsGefahr6[2] + userData.maxStarsGesamt1[0] + userData.maxStarsGesamt1[1] + userData.maxStarsGesamt1[2] + userData.maxStarsGesamt2[0] + userData.maxStarsGesamt2[1] + userData.maxStarsGesamt2[2]);
        
        setUnlockEin1(userData.unlockEin1);
        setUnlockEin2(userData.unlockEin2);

        setUnlockGefahr1(userData.unlockGefahr1);
        setUnlockGefahr2(userData.unlockGefahr2);
        setUnlockGefahr3(userData.unlockGefahr3);
        setUnlockGefahr4(userData.unlockGefahr4);
        setUnlockGefahr5(userData.unlockGefahr5);
        setUnlockGefahr6(userData.unlockGefahr6);

        setUnlockGesamt1(userData.unlockGesamt1);
        setUnlockGesamt2(userData.unlockGesamt2);

        setChampionsBadge(userData.championsBadge);
        setTop3Badge(userData.top3Badge);

        setRegisterDate(userData.Registerdate);
        setDaily(userData.daily);
        setKostenpflichtig(userData.kostenpflichtig);


    } catch (e) {
      console.error("Fehler beim Abrufen des Dokuments aus der Datenbank", e);
    }

  };

  const getAllDocuments = useCallback(async () => {
 
    const q = query(
      collection(firestore, "Users"),
      where("Username", "!=", "")
    );
    const docs = [];
    const querySnapshot = await getDocs(q);
  
    querySnapshot.forEach((doc) => {
      docs.push({ id: doc.id, data: doc.data() });
      console.log(doc.id, " => ", doc.data());
    });
  
    console.log("Docs:", docs); // Überprüfen der Daten
    setAllDocs(docs);
  }, []);

  const updateChampionsBadge = async (badge) => {
    const ref = collection(firestore, "Users");
    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();
      userData.championsBadge = badge;
      await setDoc(userDoc, userData);
      getDocument();
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank der ChampionsBadge", e);
    }
  };

  const updateTop3Badge = async (badge) => {
    const ref = collection(firestore, "Users");
    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();
      userData.top3Badge = badge;
      await setDoc(userDoc, userData);
      getDocument();
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank der Top 3 Badge", e);
    }
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");

    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();

      userData.starsGefahr1[0] = 0;
      userData.starsGefahr1[1] = 0;
      userData.starsGefahr1[2] = 0;
      userData.starsGefahr2[0] = 0;
      userData.starsGefahr2[1] = 0;
      userData.starsGefahr2[2] = 0;
      userData.starsGefahr3[0] = 0;
      userData.starsGefahr3[1] = 0;
      userData.starsGefahr3[2] = 0;
      userData.starsGefahr4[0] = 0;
      userData.starsGefahr4[1] = 0;
      userData.starsGefahr4[2] = 0;
      userData.starsGefahr5[0] = 0;
      userData.starsGefahr5[1] = 0;
      userData.starsGefahr5[2] = 0;
      userData.starsGefahr6[0] = 0;
      userData.starsGefahr6[1] = 0;
      userData.starsGefahr6[2] = 0;
      userData.starsGesamt1[0] = 0;
      userData.starsGesamt1[1] = 0;
      userData.starsGesamt1[2] = 0;
      userData.starsGesamt2[0] = 0;
      userData.starsGesamt2[1] = 0;
      userData.starsGesamt2[2] = 0;
      userData.unlockGefahr1 = false;
      userData.unlockGefahr2 = false;
      userData.unlockGefahr3 = false;
      userData.unlockGefahr4 = false;
      userData.unlockGefahr5 = false;
      userData.unlockGefahr6 = false;
      userData.unlockGesamt1 = false;
      userData.unlockGesamt2 = false;
      userData.unlockEin1 = false;
      userData.unlockEin2 = false;
      counter = 0;
      updateChampionsBadge(false);
      updateTop3Badge(false);


      await setDoc(userDoc, userData);
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank beim Fortschritt löschen", e);
    }
  };
  
  
  useEffect(() => {
    getDocument();
    getAllDocuments(); 
  
    if (progressDeleted == true) {
      updateDatabase();
    }
  }, [getAllDocuments]);

  useEffect(() => {
  if (progressDeleted) {
    updateDatabase();
  }
}, [progressDeleted]);  // Jetzt wird updateDatabase ausgeführt, wenn progressDeleted sich ändert


  // useEffect(() => {
  //   getDocument();
  //   getAllDocuments();
  //   updateTop3Badge();
  //   updateChampionsBadge();
  //   if (progressDeleted == true) {
  //     updateDatabase();
  //   }
  // }, );


  // Gefahr 1
  const badgeImage1StarGefahr1 = progressDB1 / maxProgressDB1 > 0.4 
    ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
    : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

  const badgeImage2StarGefahr1 = progressDB1 / maxProgressDB1 > 0.75 
    ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
    : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";
  
  const badgeImage3StarGefahr1 = progressDB1 / maxProgressDB1 == 1
    ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
    : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

  // Gefahr 2
  const badgeImage1StarGefahr2 = progressDB2 / maxProgressDB2 > 0.4 
    ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
    : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

  const badgeImage2StarGefahr2 = progressDB2 / maxProgressDB2 > 0.75 
    ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
    : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";
  
  const badgeImage3StarGefahr2 = progressDB2 / maxProgressDB2 == 1
    ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
    : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

  // Gefahr 3
  const badgeImage1StarGefahr3 = progressDB3 / maxProgressDB3 > 0.4 
    ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
    : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

  const badgeImage2StarGefahr3 = progressDB3 / maxProgressDB3 > 0.75 
    ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
    : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";
  
  const badgeImage3StarGefahr3 = progressDB3 / maxProgressDB3 == 1
    ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
    : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

     // Gefahr 4
  const badgeImage1StarGefahr4 = progressDB4 / maxProgressDB4 > 0.4 
  ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
  : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

const badgeImage2StarGefahr4 = progressDB4 / maxProgressDB4 > 0.75 
  ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
  : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

const badgeImage3StarGefahr4 = progressDB4 / maxProgressDB4 == 1
  ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
  : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

   // Gefahr 5
   const badgeImage1StarGefahr5 = progressDB5 / maxProgressDB5 > 0.4 
   ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
   : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

 const badgeImage2StarGefahr5 = progressDB5 / maxProgressDB5 > 0.75 
   ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
   : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";
 
 const badgeImage3StarGefahr5 = progressDB5 / maxProgressDB5 == 1
   ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
   : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

    // Gefahr 6
  const badgeImage1StarGefahr6 = progressDB6 / maxProgressDB6 > 0.4 
  ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
  : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

const badgeImage2StarGefahr6 = progressDB6 / maxProgressDB6 > 0.75 
  ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
  : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

const badgeImage3StarGefahr6 = progressDB6 / maxProgressDB6 == 1
  ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
  : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";


   // Gesamtuebung 1
   const badgeImage1StarGesamt1 = progressGDB1 / maxProgressGDB1 > 0.4 
   ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
   : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

 const badgeImage2StarGesamt1 = progressGDB1 / maxProgressGDB1 > 0.75 
   ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
   : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";
 
 const badgeImage3StarGesamt1 = progressGDB1 / maxProgressGDB1 == 1
   ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
   : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

    // Gesamtuebung 2
  const badgeImage1StarGesamt2 = progressGDB2 / maxProgressGDB2 > 0.4 
  ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
  : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

const badgeImage2StarGesamt2 = progressGDB2 / maxProgressGDB2 > 0.75 
  ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
  : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

const badgeImage3StarGesamt2 = progressGDB2 / maxProgressGDB2 == 1
  ? "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star.svg?alt=media&token=aed50182-6931-490c-af3d-5c9735520640"
  : "https://firebasestorage.googleapis.com/v0/b/internetfuehrerschein-7c740.appspot.com/o/star_default.svg?alt=media&token=d77c87ff-666b-4f54-b58f-7dc8ef06f933";

  // Gesamtfortschritt für Badges
  const bronzeBadge = progressDBVonAllem / maxProgressDBVonAllem > 0.4 
  ? "https://cdn-icons-png.flaticon.com/512/11881/11881951.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

const silberBadge = progressDBVonAllem / maxProgressDBVonAllem > 0.75 
  ? "https://cdn-icons-png.flaticon.com/512/11881/11881953.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

const goldBadge = progressDBVonAllem / maxProgressDBVonAllem == 1
  ? "https://cdn-icons-png.flaticon.com/512/7645/7645438.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

  const gefahr1 = progressDB1 / maxProgressDB1 == 1
  ? "https://bookpoints.org/sites/default/files/badges/champ_level01.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

  const gefahr2 = progressDB2 / maxProgressDB2 == 1
  ? "https://bookpoints.org/sites/default/files/badges/champ_level02.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

  const gefahr3 = progressDB3 / maxProgressDB3 == 1
  ? "https://bookpoints.org/sites/default/files/badges/champ_level03.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

  const gefahr4 = progressDB4 / maxProgressDB4 == 1
  ? "https://bookpoints.org/sites/default/files/badges/champ_level04.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

  const gesamt1 = progressGDB1 / maxProgressGDB1 == 1
  ? "https://bookpoints.org/sites/default/files/badges/champ_level05.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

  const gefahr5 = progressDB5 / maxProgressDB5 == 1
  ? "https://bookpoints.org/sites/default/files/badges/champ_level06.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

  const gefahr6 = progressDB6 / maxProgressDB6 == 1
  ? "https://bookpoints.org/sites/default/files/badges/champ_level07.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

  const gesamt2 = progressGDB2 / maxProgressGDB2 == 1
  ? "https://bookpoints.org/sites/default/files/badges/champ_level08.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

  const cBadge = championsBadge === true
  ? "https://static.vecteezy.com/system/resources/previews/024/342/176/original/top-1-3d-render-png.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";

  const t3Badge = top3Badge === true
  ? "https://static.vecteezy.com/system/resources/previews/024/345/751/original/top-3-3d-render-png.png"
  : "https://wallpapers.com/images/hd/pure-white-1080-x-1920-background-69l155kugkiyejy6.jpg";







  // let counter = 0;

  if (unlockEin1 == true) {
    counter = 1;
  }

  if ((unlockEin1 == true) && (unlockEin2 == true)) {
    counter = 2;
  }

  if ((unlockEin1 == true) && (unlockEin2 == true) && (unlockGefahr1 == true)) {
    counter = 3;
  }

  if ((unlockEin1 == true) && (unlockEin2 == true) && (unlockGefahr1 == true) && (unlockGefahr2 == true)) {
    counter = 4;
  }

  if ((unlockEin1 == true) && (unlockEin2 == true) && (unlockGefahr1 == true) && (unlockGefahr2 == true) && (unlockGefahr3 == true)) {
    counter = 5;
  }

  if ((unlockEin1 == true) && (unlockEin2 == true) && (unlockGefahr1 == true) && (unlockGefahr2 == true) && (unlockGefahr3 == true) && (unlockGefahr4 == true)) {
    counter = 6;
  }

  if ((unlockEin1 == true) && (unlockEin2 == true) && (unlockGefahr1 == true) && (unlockGefahr2 == true) && (unlockGefahr3 == true) && (unlockGefahr4 == true) && (unlockGesamt1 == true)) {
    counter = 7;
  }

  if ((unlockEin1 == true) && (unlockEin2 == true) && (unlockGefahr1 == true) && (unlockGefahr2 == true) && (unlockGefahr3 == true) && (unlockGefahr4 == true) && (unlockGesamt1 == true) && (unlockGefahr5 == true)) {
    counter = 8;
  }

  if ((unlockEin1 == true) && (unlockEin2 == true) && (unlockGefahr1 == true) && (unlockGefahr2 == true) && (unlockGefahr3 == true) && (unlockGefahr4 == true) && (unlockGesamt1 == true) && (unlockGefahr5 == true) && (unlockGefahr6 == true)) {
    counter = 9;
  }

  if ((unlockEin1 == true) && (unlockEin2 == true) && (unlockGefahr1 == true) && (unlockGefahr2 == true) && (unlockGefahr3 == true) && (unlockGefahr4 == true) && (unlockGesamt1 == true) && (unlockGefahr5 == true) && (unlockGefahr6 == true) && (unlockGesamt2 == true)) {
    counter = 10;
  }



  const buttonStyles = [
    { left: '-120px' },
    { right: '-190px', top: '-30px' },
    { left: '-120px', top: '20px' },
    { right: '-170px', top: '-30px' },
    { left: '-130px', top: '10px' },
    { right: '-190px', top: '-30px' },
    { left: '-140px', top: '-30px' },
    { right: '-190px', top: '-60px' },
    { left: '-140px', top: '-80px' },
    { right: '-180px', top: '-140px' }
  ];

  // Define the labels for each button
  const buttonLabels = [
    "Zur Einführung 1",
    "Zur Einführung 2",
    "Zu Level 1",
    "Zu Level 2",
    "Zu Level 3",
    "Zu Level 4",
    "Zu Level 5",
    "Zu Level 6",
    "Zu Level 7",
    "Zu Level 8"
  ];

  // Define the names for each lesson
  const lessonNames = [
    "SafeSurf",
    "Internet",
    "Drive-by-Downloads",
    "Social Engineering",
    "Phishing",
    "SQL-Injection und Cross-Site Scripting",
    "Gesamtübung",
    "Malware",
    "Botnetze",
    "Gesamtübung"
  ];

  const lessonText = [
    "SafeSurf",
    "das Internet",
    "Drive-by-Downloads.",
    "Social Engineering.",
    "Phishing.",
    "SQL-Injection und Cross-Site Scripting.",
    "die Gefahren.",
    "Trojaner und Würmer.",
    "Botnetze.",
    "die Gefahren."

  ];

  // Define the links for each button
  const buttonLinks = [
    "/Einfuehrung1",
    "/Einfuehrung2",
    "/Gefahr1",
    "/Gefahr2",
    "/Gefahr3",
    "/Gefahr4",
    "/QuizGesamtuebung",
    "/Gefahr5",
    "/Gefahr6",
    "/QuizGesamtuebung2"
  ];


  if (kostenpflichtig === true){
    return (
      <div className="badgeKachel">
        <div className="badge-tile" style={{ border: "2px solid black" }}>     
          <h2 style={{ textAlign: "center" }}>Badges</h2>
          <div className="badge-container">
            <div className="badge-row">
              {/* Erste Reihe von Abzeichen-Icons */}
              <img src={gefahr1} alt="Star" className="level" />
              <img src={gefahr2} alt="Star" className="level" />
              <img src={gefahr3} alt="Star" className="level" />
              <img src={gefahr4} alt="Star" className="level" />
              <img src={gesamt1} alt="Star" className="level" />
              <img src={gefahr5} alt="Star" className="level" />
              <img src={gefahr6} alt="Star" className="level" />
              <img src={gesamt2} alt="Star" className="level" />
            </div>
            <div className="badge-row">
              {/* Zweite Reihe von Abzeichen-Icons */}
              <img src={bronzeBadge} alt="Star" className="star-bronze" />
              <img src={silberBadge} alt="Star" className="star-silber" />
              <img src={goldBadge} alt="Star" className="star-gold" />
              <img src={t3Badge} alt="Star" className="star-gold" />
              <img src={cBadge} alt="Star" className="star-top1" />
            </div>
          </div>
        </div>


        
        <div className="startseiteloggedin">

        {/* <div className="daily-quiz-button-container">
        <Link className="Link" to={"/Daily"}>
          <img
            className="logo"
            src="https://play-lh.googleusercontent.com/iW7yUWpJWIATO0zU9KfygdLsRdRHMUND9CdRE0qM3KX8FaPe_Q_813Fq4i4g4wtB6gI"
            alt="Logo"
          />
        </Link>
        </div> */}

          <div className="lottie-container">
            {/* Your existing Lottie animations and content */}
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "625px", marginLeft: "90px" }} className='Lottie' animationData={fox1} />
              <div style={{ marginTop: "10px", marginLeft: "-630px" }} className="stars">
                <img src={badgeImage1StarGefahr1} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr1} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr1} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-100px", marginLeft: "800px" }} className='Lottie' animationData={fox2} />
              <div style={{ marginTop: "10px", marginLeft: "800px" }} className="stars">
                <img src={badgeImage1StarGefahr2} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr2} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr2} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-0px", marginLeft: "90px" }} className='Lottie' animationData={fox3} />
              <div style={{ marginTop: "10px", marginLeft: "-630px" }} className="stars">
                <img src={badgeImage1StarGefahr3} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr3} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr3} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-90px", marginLeft: "800px" }} className='Lottie' animationData={fox4} />
              <div style={{ marginTop: "10px", marginLeft: "800px" }} className="stars">
                <img src={badgeImage1StarGefahr4} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr4} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr4} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-40px", marginLeft: "90px" }} className='Lottie' animationData={fox2} />
              <div style={{ marginTop: "10px", marginLeft: "-630px" }} className="stars">
                <img src={badgeImage1StarGesamt1} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGesamt1} alt="Star" className="star" />
                <img src={badgeImage3StarGesamt1} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-80px", marginLeft: "800px" }} className='Lottie' animationData={fox1} />
              <div style={{ marginTop: "10px", marginLeft: "800px" }} className="stars">
                <img src={badgeImage1StarGefahr5} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr5} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr5} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-60px", marginLeft: "90px" }} className='Lottie' animationData={fox4} />
              <div style={{ marginTop: "10px", marginLeft: "-630px" }} className="stars">
                <img src={badgeImage1StarGefahr6} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr6} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr6} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-100px", marginLeft: "800px" }} className='Lottie' animationData={fox3} />
              <div style={{ marginTop: "10px", marginLeft: "800px" }} className="stars">
                <img src={badgeImage1StarGesamt2} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGesamt2} alt="Star" className="star" />
                <img src={badgeImage3StarGesamt2} alt="Star" className="star" />
              </div>
            </div>
            <div className="zielflagge">
              <img src="https://static.vecteezy.com/ti/gratis-vektor/p1/1213985-ziellinie-isoliert-auf-weissem-hintergrund-kostenlos-vektor.jpg" alt="Star" className="star" />
            </div>
          </div>

          <div className="profile-header">
            <div className="profile-info">

              {/* Profile Icon to open modal */}
              <img
                src="https://worcestercountyhumanesociety.org/wp-content/uploads/2022/10/avatar-scaled.jpeg"
                alt="Profile"
                className="profile-pic"
                onClick={() => setShowProfileModal(true)}
                disabled={showInfoModal} // Deaktiviert den Button, wenn das Leaderboard angezeigt wird
              />
              <span className="profile-name">Hello, {username} welcome back!</span>
              </div>
              <a
                style={{ textDecoration: "none" }}
                id="logout-link"
                href="/login"
                className="logout-button"
                onClick={() => signOut(getAuth())}
              >
                Logout
              </a>
              </div>

          {/* Profile Modal */}
          {showProfileModal && (
            <div className="modal-overlay" onClick={() => setShowProfileModal(false)}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Profilinformationen</h2>
                <p>Username: {username}</p>
                <p>E-Mail: {getAuth().currentUser.email} </p>
                <p>Registrierungsdatum: {registerDate} </p>

                {/* Fortschritt löschen Button */}
                <button 
                  className="close-button" 
                  onClick={() => {
                    setProgressDeleted(true);  // Fortschritt wird gelöscht
                    updateDatabase();        // Benutzer wird ausgeloggt
                    getDocument();
                    getAllDocuments(); 
                  }}
                >
                  Fortschritt löschen
                </button>

                <button 
                  className="close-button" 
                  onClick={() => {
                    setShowProfileModal(false);  // Modal schließen
                  }} 
                >
                  Close
                </button>
              </div>
            </div>
          )}

          {/* Leaderboard Modal */}
          {showLeaderboardModal && (
            <div className="modal-overlay" onClick={() => setShowLeaderboardModal(false)}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>

              <h1 style={{ color: "white" }}>Leaderboard </h1>
                {allDocs
                  .sort((a, b) => {
                    const pointsA =
                      a.data.starsGefahr1.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGefahr2.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGefahr3.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGefahr4.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGefahr5.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGefahr6.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGesamt1.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGesamt2.reduce((sum, value) => sum + value, 0);
                    const pointsB =
                      b.data.starsGefahr1.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGefahr2.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGefahr3.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGefahr4.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGefahr5.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGefahr6.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGesamt1.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGesamt2.reduce((sum, value) => sum + value, 0);

                    if (pointsA === pointsB) {
                      const dateA = new Date(a.data.ScoreAktualisierung);
                      const dateB = new Date(b.data.ScoreAktualisierung);
                      const diffA =
                        dateA.getTime() - new Date(a.data.Registerdate).getTime();
                      const diffB =
                        dateB.getTime() - new Date(b.data.Registerdate).getTime();

                      return diffA - diffB;
                    }

                    return pointsB - pointsA;
                  })

                  .map((doc, index) => {
                    if (getAuth().currentUser.email === doc.data.Emailadress) {
                      if (index === 0) {
                        updateChampionsBadge(true);
                        updateTop3Badge(true);
                      } else if (index < 4) {
                        updateTop3Badge(true);
                      } else {
                        updateTop3Badge(false);
                        updateChampionsBadge(false);
                      }
                      return (
                        <a
                          style={{
                            display: "block",
                            margin: "8px 0",
                            backgroundColor: "rgba(255, 165, 0, 0.5)",
                            textAlign: "left",
                            color: "white",
                            fontSize: "1.2em"
                          }}
                          key={doc.id}
                        >
                          {index + 1} - {doc.data.Username} | Punkte:{" "}
                          {doc.data.starsGefahr1.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr2.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr3.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr4.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr5.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr6.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGesamt1.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGesamt2.reduce((sum, value) => sum + value, 0)}
                        </a>
                      );
                    } else if (index <= 9) {
                      return (
                        <a
                          style={{
                            display: "block",
                            margin: "8px 0",
                            textAlign: "left",
                            color: "white",
                            fontSize: "1.2em"
                          }}
                          key={doc.id}
                        >
                          {index + 1} - {doc.data.Username} | Punkte:{" "}
                          {doc.data.starsGefahr1.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr2.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr3.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr4.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr5.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr6.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGesamt1.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGesamt2.reduce((sum, value) => sum + value, 0)}
                        </a>
                      );
                    }
                    return null; // Sicherstellen, dass etwas zurückgegeben wird
                  })}

                {/* You can add more content or components here */}
                <button className="close-button" onClick={() => setShowLeaderboardModal(false)}>Close</button>
              </div>
            </div>
          )}

          {/* Info Webseite Modal */}
          {showInfoModal && (
            <div className="modal-overlay" onClick={() => setShowInfoModal(false)}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            
                <h2>Informationen über die Webseite</h2>
                <div className="gefahr-text">
                <p>Die Website SafeSurf ist die Projektarbeit von vier Studenten der Technischen Hochschule Würzburg-Schweinfurt. Ziel war es, die verschiedenen Gefahren des Internets spielerisch zu vermitteln.</p>
                </div>
                {/* You can add more content or components here */}
                <button className="close-button" onClick={() => setShowInfoModal(false)}>Close</button>
              </div>
            </div>
          )}

          {/* Weekly Quiz Modal */}
          {showWeeklyQuizKachel && (
            <div className="modal-overlay" onClick={() => setShowWeeklyQuizKachel(false)}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            
                <h2>Aufgaben</h2>
                <p>Hier werden deine Aufgaben angezeigt:</p>
                {showWeeklyQuizKachel && <WeeklyQuizKachel />}
                {/* You can add more content or components here */}
                <button className="close-button" onClick={() => setShowWeeklyQuizKachel(false)}>Close</button>
              </div>
            </div>
          )}

          <div className="curved-container">
            {buttonStyles.map((style, index) => (
              <div key={index} className="curved-item" style={style}>
                <button
                  disabled={index > counter}  // Button deaktivieren, wenn index > counter
                  className={index <= counter ? "button-10" : "button-10-disabled"}
                  onClick={() => {
                    if (index <= counter) {  // Nur reagieren, wenn index <= counter
                      setButtonindex(index);
                      setShowLargeBubble(!showLargeBubble);
                    }
                  }}
                >
                  {index === counter && !showLargeBubble && (
                    <motion.div
                      className="speech-bubble"
                      initial={{ y: 0, x: -40 }}
                      animate={{ y: [0, -10, 0], x: [-40, -40, -40] }}
                      transition={{ repeat: Infinity, duration: 3, ease: "easeInOut" }}
                    >
                      Anfangen
                      <div className="speech-bubble-arrow"></div>
                    </motion.div>
                  )}
                  {index <= counter ? "✔️" : "❌"}
                </button>
                {showLargeBubble && index === buttonindex && (
                  <motion.div
                    className={index <= counter ? "large-speech-bubble" : "large-speech-bubble-disabled"}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="large-speech-bubble-content">
                      <h3>{lessonNames[index]}</h3>
                      <a>In dieser Lektion lernen Sie alles über {lessonText[index]}</a>
                      <Link to={buttonLinks[index]}>
                        <button className={index <= counter ? 'button-19' : 'button-19-disabled'}>
                          {buttonLabels[index]}
                        </button>
                      </Link>
                    </div>
                    <div className="large-speech-bubble-arrow"></div>
                  </motion.div>
                )}
              </div>
            ))}
          </div>

          {/* Add Weekly Quiz Kachel
          <div className="weekly-quiz-kachel-container">

            <button
              className="aufgaben-button"
              onClick={() => setShowWeeklyQuizKachel(true)}
            >
              Aufgaben
            </button>
          </div> */}

          {/* Add Leaderboard Button */}
          <div className="leaderboard-button-container">
            <button
              className="leaderboard-button"
              onClick={() => setShowLeaderboardModal(true)}
              disabled={showInfoModal} // Deaktiviert den Button, wenn das Leaderboard angezeigt wird
            >
              <img 
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTYL75DBTs8h3BvPr9kKZlDnA7A8dwqBYbpQ&s" 
                alt="Leaderboard"
                style={{ width: '100px', height: 'auto' }}
              />
            </button>
          </div>

          {/* Add Info-Button Button */}
          <div className="info-button-container">
          <button
            className="info-button"
            onClick={() => setShowInfoModal(true)}
            disabled={showLeaderboardModal} // Deaktiviert den Button, wenn das Leaderboard angezeigt wird
          >
            <img 
              src="https://cdn.pixabay.com/photo/2016/06/15/15/02/info-1459077_1280.png" 
              alt="info-button"
              style={{ width: '100px', height: 'auto' }}
            />
          </button>
        </div>

          
        </div>
      </div>
    );
  } else {
    return (
      <div className="badgeKachel">
        <div className="badge-tile" style={{ border: "2px solid black" }}>     
          <h2 style={{ textAlign: "center" }}>Badges</h2>
          <div className="badge-container">
            <div className="badge-row">
              {/* Erste Reihe von Abzeichen-Icons */}
              <img src={gefahr1} alt="Star" className="level" />
              <img src={gefahr2} alt="Star" className="level" />
              <img src={gefahr3} alt="Star" className="level" />
              <img src={gefahr4} alt="Star" className="level" />
              <img src={gesamt1} alt="Star" className="level" />
              <img src={gefahr5} alt="Star" className="level" />
              <img src={gefahr6} alt="Star" className="level" />
              <img src={gesamt2} alt="Star" className="level" />
            </div>
            <div className="badge-row">
              {/* Zweite Reihe von Abzeichen-Icons */}
              <img src={bronzeBadge} alt="Star" className="star-bronze" />
              <img src={silberBadge} alt="Star" className="star-silber" />
              <img src={goldBadge} alt="Star" className="star-gold" />
              <img src={t3Badge} alt="Star" className="star-gold" />
              <img src={cBadge} alt="Star" className="star-top1" />
            </div>
          </div>
        </div>


        
        <div className="startseiteloggedin">

        {/* <div className="daily-quiz-button-container">
        <Link className="Link" to={"/Daily"}>
          <img
            className="logo"
            src="https://play-lh.googleusercontent.com/iW7yUWpJWIATO0zU9KfygdLsRdRHMUND9CdRE0qM3KX8FaPe_Q_813Fq4i4g4wtB6gI"
            alt="Logo"
          />
        </Link>
        </div> */}

          <div className="lottie-container">
            {/* Your existing Lottie animations and content */}
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "625px", marginLeft: "90px" }} className='Lottie' animationData={fox1} />
              <div style={{ marginTop: "10px", marginLeft: "-630px" }} className="stars">
                <img src={badgeImage1StarGefahr1} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr1} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr1} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-100px", marginLeft: "800px" }} className='Lottie' animationData={fox2} />
              <div style={{ marginTop: "10px", marginLeft: "800px" }} className="stars">
                <img src={badgeImage1StarGefahr2} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr2} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr2} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-0px", marginLeft: "90px" }} className='Lottie' animationData={fox3} />
              <div style={{ marginTop: "10px", marginLeft: "-630px" }} className="stars">
                <img src={badgeImage1StarGefahr3} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr3} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr3} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-90px", marginLeft: "800px" }} className='Lottie' animationData={fox4} />
              <div style={{ marginTop: "10px", marginLeft: "800px" }} className="stars">
                <img src={badgeImage1StarGefahr4} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr4} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr4} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-40px", marginLeft: "90px" }} className='Lottie' animationData={fox2} />
              <div style={{ marginTop: "10px", marginLeft: "-630px" }} className="stars">
                <img src={badgeImage1StarGesamt1} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGesamt1} alt="Star" className="star" />
                <img src={badgeImage3StarGesamt1} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-80px", marginLeft: "800px" }} className='Lottie' animationData={fox1} />
              <div style={{ marginTop: "10px", marginLeft: "800px" }} className="stars">
                <img src={badgeImage1StarGefahr5} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr5} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr5} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-60px", marginLeft: "90px" }} className='Lottie' animationData={fox4} />
              <div style={{ marginTop: "10px", marginLeft: "-630px" }} className="stars">
                <img src={badgeImage1StarGefahr6} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGefahr6} alt="Star" className="star" />
                <img src={badgeImage3StarGefahr6} alt="Star" className="star" />
              </div>
            </div>
            <div className="lottie-animation">
              <Lottie style={{ marginTop: "-100px", marginLeft: "800px" }} className='Lottie' animationData={fox3} />
              <div style={{ marginTop: "10px", marginLeft: "800px" }} className="stars">
                <img src={badgeImage1StarGesamt2} alt="Star" className="star" />
                <img style={{ marginTop: "10px" }} src={badgeImage2StarGesamt2} alt="Star" className="star" />
                <img src={badgeImage3StarGesamt2} alt="Star" className="star" />
              </div>
            </div>
            <div className="zielflagge">
              <img src="https://static.vecteezy.com/ti/gratis-vektor/p1/1213985-ziellinie-isoliert-auf-weissem-hintergrund-kostenlos-vektor.jpg" alt="Star" className="star" />
            </div>
          </div>

          <div className="profile-header">
            <div className="profile-info">

              {/* Profile Icon to open modal */}
              <img
                src="https://worcestercountyhumanesociety.org/wp-content/uploads/2022/10/avatar-scaled.jpeg"
                alt="Profile"
                className="profile-pic"
                onClick={() => setShowProfileModal(true)}
                disabled={showInfoModal} // Deaktiviert den Button, wenn das Leaderboard angezeigt wird
              />
              <span className="profile-name">Hello, {username} welcome back!</span>
              </div>
              <a
                style={{ textDecoration: "none" }}
                id="logout-link"
                href="/login"
                className="logout-button"
                onClick={() => signOut(getAuth())}
              >
                Logout
              </a>
              </div>

          {/* Profile Modal */}
          {showProfileModal && (
            <div className="modal-overlay" onClick={() => setShowProfileModal(false)}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Profilinformationen</h2>
                <p>Username: {username}</p>
                <p>E-Mail: {getAuth().currentUser.email} </p>
                <p>Registrierungsdatum: {registerDate} </p>

                {/* Fortschritt löschen Button */}
                <button 
                  className="close-button" 
                  onClick={() => {
                    setProgressDeleted(true);  // Fortschritt wird gelöscht
                    updateDatabase();        // Benutzer wird ausgeloggt
                    getDocument();
                    getAllDocuments(); 
                  }}
                >
                  Fortschritt löschen
                </button>

                <button 
                  className="close-button" 
                  onClick={() => {
                    setShowProfileModal(false);  // Modal schließen
                  }} 
                >
                  Close
                </button>
              </div>
            </div>
          )}

          {/* Leaderboard Modal */}
          {showLeaderboardModal && (
            <div className="modal-overlay" onClick={() => setShowLeaderboardModal(false)}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>

              <h1 style={{ color: "white" }}>Leaderboard </h1>
                {allDocs
                  .sort((a, b) => {
                    const pointsA =
                      a.data.starsGefahr1.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGefahr2.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGefahr3.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGefahr4.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGefahr5.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGefahr6.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGesamt1.reduce((sum, value) => sum + value, 0) +
                      a.data.starsGesamt2.reduce((sum, value) => sum + value, 0);
                    const pointsB =
                      b.data.starsGefahr1.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGefahr2.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGefahr3.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGefahr4.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGefahr5.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGefahr6.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGesamt1.reduce((sum, value) => sum + value, 0) +
                      b.data.starsGesamt2.reduce((sum, value) => sum + value, 0);

                    if (pointsA === pointsB) {
                      const dateA = new Date(a.data.ScoreAktualisierung);
                      const dateB = new Date(b.data.ScoreAktualisierung);
                      const diffA =
                        dateA.getTime() - new Date(a.data.Registerdate).getTime();
                      const diffB =
                        dateB.getTime() - new Date(b.data.Registerdate).getTime();

                      return diffA - diffB;
                    }

                    return pointsB - pointsA;
                  })

                  .map((doc, index) => {
                    if (getAuth().currentUser.email === doc.data.Emailadress) {
                      if (index === 0) {
                        updateChampionsBadge(true);
                        updateTop3Badge(true);
                      } else if (index < 4) {
                        updateTop3Badge(true);
                      } else {
                        updateTop3Badge(false);
                        updateChampionsBadge(false);
                      }
                      return (
                        <a
                          style={{
                            display: "block",
                            margin: "8px 0",
                            backgroundColor: "rgba(255, 165, 0, 0.5)",
                            textAlign: "left",
                            color: "white",
                            fontSize: "1.2em"
                          }}
                          key={doc.id}
                        >
                          {index + 1} - {doc.data.Username} | Punkte:{" "}
                          {doc.data.starsGefahr1.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr2.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr3.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr4.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr5.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr6.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGesamt1.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGesamt2.reduce((sum, value) => sum + value, 0)}
                        </a>
                      );
                    } else if (index <= 9) {
                      return (
                        <a
                          style={{
                            display: "block",
                            margin: "8px 0",
                            textAlign: "left",
                            color: "white",
                            fontSize: "1.2em"
                          }}
                          key={doc.id}
                        >
                          {index + 1} - {doc.data.Username} | Punkte:{" "}
                          {doc.data.starsGefahr1.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr2.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr3.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr4.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr5.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGefahr6.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGesamt1.reduce((sum, value) => sum + value, 0) +
                            doc.data.starsGesamt2.reduce((sum, value) => sum + value, 0)}
                        </a>
                      );
                    }
                    return null; // Sicherstellen, dass etwas zurückgegeben wird
                  })}

                {/* You can add more content or components here */}
                <button className="close-button" onClick={() => setShowLeaderboardModal(false)}>Close</button>
              </div>
            </div>
          )}

          {/* Info Webseite Modal */}
          {showInfoModal && (
            <div className="modal-overlay" onClick={() => setShowInfoModal(false)}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            
                <h2>Informationen über die Webseite</h2>
                <div className="gefahr-text">
                <p>Die Website SafeSurf ist die Projektarbeit von vier Studenten der Technischen Hochschule Würzburg-Schweinfurt. Ziel war es, die verschiedenen Gefahren des Internets spielerisch zu vermitteln.</p>
                </div>
                {/* You can add more content or components here */}
                <button className="close-button" onClick={() => setShowInfoModal(false)}>Close</button>
              </div>
            </div>
          )}

          {/* Weekly Quiz Modal */}
          {showWeeklyQuizKachel && (
            <div className="modal-overlay" onClick={() => setShowWeeklyQuizKachel(false)}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            
                <h2>Aufgaben</h2>
                <p>Hier werden deine Aufgaben angezeigt:</p>
                {showWeeklyQuizKachel && <WeeklyQuizKachel />}
                {/* You can add more content or components here */}
                <button className="close-button" onClick={() => setShowWeeklyQuizKachel(false)}>Close</button>
              </div>
            </div>
          )}

          <div className="curved-container">
            {buttonStyles.map((style, index) => (
              <div key={index} className="curved-item" style={style}>
                <button
                  disabled={index > counter || index >= 7}  // Buttons für die letzten drei Level immer deaktiviert
                  className={index <= counter && index < 7 ? "button-10" : "button-10-disabled"}
                  onClick={() => {
                    if (index <= counter && index < 7) {  // Nur reagieren, wenn index <= counter und es kein gesperrtes Level ist
                      setButtonindex(index);
                      setShowLargeBubble(!showLargeBubble);
                    }
                  }}
                >
                  {index === counter && !showLargeBubble && index < 7 && (
                    <motion.div
                      className="speech-bubble"
                      initial={{ y: 0, x: -40 }}
                      animate={{ y: [0, -10, 0], x: [-40, -40, -40] }}
                      transition={{ repeat: Infinity, duration: 3, ease: "easeInOut" }}
                    >
                      Anfangen
                      <div className="speech-bubble-arrow"></div>
                    </motion.div>
                  )}

                  {/* Bedingung für die Anzeige des Symbols: */}
                  {index < 7 
                    ? (index <= counter ? "✔️" : "❌")  // Für Stufen 1 bis 7: Haken oder X
                    : "🔒"}  {/* Für Stufen 8, 9 und 10: immer ein Schloss */}
                </button>

                {showLargeBubble && index === buttonindex && index < 7 && (
                  <motion.div
                    className={index <= counter ? "large-speech-bubble" : "large-speech-bubble-disabled"}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="large-speech-bubble-content">
                      <h3>{lessonNames[index]}</h3>
                      <a>In dieser Lektion lernen Sie alles über {lessonText[index]}</a>
                      <Link to={buttonLinks[index]}>
                        <button className={index <= counter ? 'button-19' : 'button-19-disabled'}>
                          {buttonLabels[index]}
                        </button>
                      </Link>
                    </div>
                    <div className="large-speech-bubble-arrow"></div>
                  </motion.div>
                )}
              </div>
            ))}
          </div>



          {/* Add Weekly Quiz Kachel
          <div className="weekly-quiz-kachel-container">

            <button
              className="aufgaben-button"
              onClick={() => setShowWeeklyQuizKachel(true)}
            >
              Aufgaben
            </button>
          </div> */}

          {/* Add Leaderboard Button */}
          <div className="leaderboard-button-container">
            <button
              className="leaderboard-button"
              onClick={() => setShowLeaderboardModal(true)}
              disabled={showInfoModal} // Deaktiviert den Button, wenn das Leaderboard angezeigt wird
            >
              <img 
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTYL75DBTs8h3BvPr9kKZlDnA7A8dwqBYbpQ&s" 
                alt="Leaderboard"
                style={{ width: '100px', height: 'auto' }}
              />
            </button>
          </div>

          {/* Add Info-Button Button */}
          <div className="info-button-container">
          <button
            className="info-button"
            onClick={() => setShowInfoModal(true)}
            disabled={showLeaderboardModal} // Deaktiviert den Button, wenn das Leaderboard angezeigt wird
          >
            <img 
              src="https://cdn.pixabay.com/photo/2016/06/15/15/02/info-1459077_1280.png" 
              alt="info-button"
              style={{ width: '100px', height: 'auto' }}
            />
          </button>
        </div>

          
        </div>
      </div>
    );

  }
}
