import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, setDoc } from "firebase/firestore";
import firestore from "../../firebase";
import ProgressBar from "@ramonak/react-progress-bar";

export const QuizGesamtuebung2 = () => {

  const [progressDB, setProgressDB] = useState(0); // Initialisiert mit 0
  const [maxProgressDB, setMaxProgressDB] = useState(0); // Initialisiert mit 0

  const getDocument = async () => {
    try {
      const docRef = doc(firestore, "Users", getAuth().currentUser.email);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data();

      if (userData && userData.starsGesamt2 !== undefined) {
        setProgressDB(userData.starsGesamt2[0] + userData.starsGesamt2[1] + userData.starsGesamt2[2]);
        setMaxProgressDB(userData.maxStarsGesamt2[0] + userData.maxStarsGesamt2[1] + userData.maxStarsGesamt2[2]);
      } else {
        console.error("starsGefahr1 nicht gefunden im Dokument");
      }

    } catch (e) {
      console.error("Fehler beim Abrufen des Dokuments aus der Datenbank", e);
    }
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");

    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();

      if (progressDB / maxProgressDB > 0.4){
        userData.unlockGesamt2 = true;
      }
      else {
        userData.unlockGesamt2 = false;
      }
      
      await setDoc(userDoc, userData);
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  // useEffect wird hier eingebaut
  useEffect(() => {
    getDocument();
  }, ); // Leeres Abhängigkeitsarray sorgt dafür, dass getDocument() nur einmal bei der ersten Render ausgeführt wird.


  return (
    <div className="gefahr2">

      <div className="progressBar" >
        <ProgressBar
          completed={Math.round((progressDB / maxProgressDB) * 100)}  // Aufrunden
          bgColor="#06D001"
          baseBgColor="grey"
          labelColor="black"
          labelAlignment="center"
          labelSize="14px"
        />
      </div>

      <Link className="Link" onClick={updateDatabase} to="/StartseiteLoggedIn" >
        <img className="logo" src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image" alt="Logo" />
      </Link>

      <div className="gefahr-text">
        <h1>Quiz Gesamtübung 2</h1>
        <p>
          Hier werden nochmal Fragen über die bereits gelernten Lektionen gestellt.
        </p>
      </div>

      <div className="Quiz-button">
        <div className="containerQuizze">
          <Link className="Link" to="/QuizLueckentextGesamtuebung2">
              <button className='button-87'>Zum Lückentext Quiz</button>
          </Link>
            <Link className="Link" to="/QuizChoiceGesamtuebung2">
              <button className='button-87'>Zum Multiple- oder Single-Choice-Quiz</button>
          </Link>
          <Link className="Link" to="/QuizFillInGesamtuebung2">
              <button className='button-87'>Zum Fill-In-Quiz</button>
          </Link>
        </div>
      </div>


    </div>
  );
}
