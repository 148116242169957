import { useState, useEffect } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import firestore from "../firebase";

// Fragenpool
const allQuestions = [
  {
    id: 1,
    question: 'Was ist das Hauptziel von Social Engineering-Angriffen?',
    options: [
      { id: 'a', text: 'Die Manipulation von Maschinen' },
      { id: 'b', text: 'Die Überlastung von Netzwerken' },
      { id: 'c', text: 'Das Überlisten von Menschen, um an sensible Informationen zu gelangen', correct: true },
      { id: 'd', text: 'Die Verschlüsselung von Daten' }
    ]
  },
  {
    id: 2,
    question: 'Welche dieser Maßnahmen kann die Wahrscheinlichkeit eines erfolgreichen Drive-by-Downloads verringern?',
      options: [
        { id: 'a', text: 'Deaktivierung von Pop-ups', correct: true },
        { id: 'b', text: 'Regelmäßige System-Backups' },
        { id: 'c', text: 'Nutzung eines Ad-Blockers', correct: true },
        { id: 'd', text: 'Deaktivierung von Flash und Java', correct: true }
    ]
  },
  {
    id: 3,
    question: 'Was ist das Hauptziel von Social Engineering-Angriffen?',
      options: [
        { id: 'a', text: 'Die Manipulation von Maschinen' },
        { id: 'b', text: 'Die Überlastung von Netzwerken' },
        { id: 'c', text: 'Das Überlisten von Menschen, um an sensible Informationen zu gelangen', correct: true },
        { id: 'd', text: 'Die Verschlüsselung von Daten' }
    ]
  },
  {
    id: 4,
    question: 'Warum sind soziale Netzwerke ein beliebtes Ziel für Social Engineering-Angriffe?',
      options: [
        { id: 'a', text: 'Weil sie keine Sicherheitsfunktionen haben', },
        { id: 'b', text: 'Weil Benutzer oft persönliche Informationen teilen, die für Angriffe genutzt werden können', correct: true },
        { id: 'c', text: 'Weil sie nur von IT-Experten genutzt werden', },
        { id: 'd', text: 'Weil sie automatisch vertrauliche Informationen preisgeben', }
    ]
  },
  {
    id: 5,
    question: 'Welche der folgenden Merkmale sind typisch für eine Phishing-E-Mail?',
      options: [
        { id: 'a', text: 'Unerwartete Aufforderung zur Eingabe persönlicher Daten', correct: true },
        { id: 'b', text: 'Dringende Warnungen oder Drohungen', correct: true },
        { id: 'c', text: 'Links, die zu gefälschten Webseiten führen', correct: true },
        { id: 'd', text: 'Perfekt formulierte Nachrichten ohne Rechtschreibfehler', }
    ]
  },
  {
    id: 6,
    question: 'Was sollten Sie tun, wenn Sie eine verdächtige E-Mail erhalten, die nach Ihren persönlichen Informationen fragt?',
    options: [
      { id: 'a', text: 'Sofort auf den Link klicken und die Informationen eingeben', },
      { id: 'b', text: 'Die E-Mail löschen und nicht darauf reagieren', correct: true },
      { id: 'c', text: 'Die E-Mail an Freunde weiterleiten, um sie zu warnen', },
      { id: 'd', text: 'Die Informationen in einem neuen Tab eingeben, um sicher zu sein', }
    ]
  },
  {
    id: 7,
    question: 'Welche Art von Malware wird verwendet, um vertrauliche Informationen wie Passwörter zu stehlen?',
    options: [
      { id: 'a', text: 'Trojaner', orrect: true },
      { id: 'b', text: 'Adware' },
      { id: 'c', text: 'Rootkit' },
      { id: 'd', text: 'Wurm',  }
    ]
  },
  {
    id: 8,
    question: 'Wie verbreitet sich ein Wurm typischerweise?',
    options: [
      { id: 'a', text: 'Durch das Herunterladen von Software aus offiziellen Stores', },
      { id: 'b', text: 'Durch das Übertragen von Dateien über ein Netzwerk', correct: true },
      { id: 'c', text: 'Durch infizierte E-Mail-Anhänge', },
      { id: 'd', text: 'Durch manipulierte Webseiten', }
    ]
  },
  {
    id: 9,
    question: 'Was ist eine SQL-Injection?',
      options: [
        { id: 'a', text: 'Ein Tool zur Überwachung von Netzwerkaktivitäten' },
        { id: 'b', text: 'Eine Art von Computervirus' },
        { id: 'c', text: 'Eine Technik, bei der schädliche SQL-Befehle in eine Datenbank eingeschleust werden', correct: true },
        { id: 'd', text: 'Eine Methode zur sicheren Verschlüsselung von Daten' }
    ]
  },
  {
    id: 10,
    question: 'Wofür werden Botnetze typischerweise verwendet?',
      options: [
        { id: 'a', text: 'Zur Erstellung von Sicherheitssoftware' },
        { id: 'b', text: 'Zum Schutz vor Malware' },
        { id: 'c', text: 'Zum Speichern von Cloud-Daten' },
        { id: 'd', text: 'Zum Versenden von Spam-E-Mails', correct: true }
    ]
  }
  // Weitere Fragen bis zur ID 10 hinzufügen
];

// Funktion zum Abrufen der täglichen Fragen
const getDailyQuestions = () => {
  const selectedIds = new Set();
  while (selectedIds.size < 2) {
    const randomIndex = Math.floor(Math.random() * allQuestions.length);
    selectedIds.add(allQuestions[randomIndex].id);
  }
  return allQuestions.filter(question => selectedIds.has(question.id));
};

// Funktion zur Berechnung der verbleibenden Zeit bis zum nächsten Tag
const getTimeUntilNextDay = () => {
  const now = new Date();
  const utcNow = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Berlin' }));
  const nextDay = new Date(utcNow);
  nextDay.setDate(utcNow.getDate() + 1);
  nextDay.setHours(0, 0, 0, 0); // Setze auf 0 Uhr in deutscher Zeit

  const timeDifference = nextDay - utcNow;
  return timeDifference;
};

export const Daily = ({ onQuizResult }) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [result, setResult] = useState(null);
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState(getTimeUntilNextDay());
  const [isAnswered, setIsAnswered] = useState(false); // Zustand, um zu überprüfen, ob die Antworten bereits abgegeben wurden
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setQuestions(getDailyQuestions());

    // Initialer Timer
    const updateTimer = () => {
      const remainingTime = getTimeUntilNextDay();
      setTimeLeft(remainingTime);
    };

    updateTimer(); // Update sofort beim Laden
    const timer = setInterval(updateTimer, 1000); // Aktualisiere jede Sekunde

    return () => clearInterval(timer);
  }, []);

  const handleOptionSelect = (questionId, optionId) => {
    if (!isAnswered) { // Nur Antworten ändern, wenn die Fragen noch nicht überprüft wurden
      const newAnswers = { ...answers };
      if (newAnswers[questionId]) {
        if (newAnswers[questionId].includes(optionId)) {
          newAnswers[questionId] = newAnswers[questionId].filter(id => id !== optionId);
        } else {
          newAnswers[questionId].push(optionId);
        }
      } else {
        newAnswers[questionId] = [optionId];
      }
      setAnswers(newAnswers);
    }
  };

  const checkAnswers = () => {
    let rightAnswers = 0;
    questions.forEach(question => {
      const selectedOptions = answers[question.id] || [];
      const correctOptions = question.options.filter(option => option.correct).map(option => option.id);
      if (selectedOptions.length === correctOptions.length && selectedOptions.every(option => correctOptions.includes(option))) {
        rightAnswers++;
      }

      updateDatabase();

    });

    const totalQuestions = questions.length;
    setResult({ rightAnswers, totalQuestions });

    const progressPercentage = (rightAnswers / totalQuestions) * 100;
    setProgress(progressPercentage);

    if (rightAnswers === totalQuestions) {
      // Speichere den Status in localStorage, wenn alle Fragen richtig beantwortet wurden
      localStorage.setItem('quizStatus', 'completed');
    }

    if (typeof onQuizResult === 'function') {
      onQuizResult({ rightAnswers, totalQuestions });
    }

    setIsAnswered(true); // Setze den Zustand auf beantwortet
  };

  const formatTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / 1000 / 60) % 60;
    const hours = Math.floor(milliseconds / 1000 / 60 / 60) % 24;
    const days = Math.floor(milliseconds / 1000 / 60 / 60 / 24);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");
    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      if (!userDocSnap.exists()) {
        console.error("Benutzerdokument existiert nicht.");
        return;
      }
  
      const userData = userDocSnap.data();
  
      userData.daily = userData.daily + result.rightAnswers;
      userData.dailyGemacht = true;
  
      await setDoc(userDoc, userData);
      console.log("Datenbank erfolgreich aktualisiert.");
  
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  useEffect(() => {
    if (check == true){
      checkAnswers();
      setCheck(false);
    }
  }, );

  return (
    <div className="quiz-container">
      <div className="timer">
        Zeit bis zum nächsten Tag: {formatTime(timeLeft)}
      </div>
      <Link className="Link" to="/StartseiteLoggedIn">
        <img
          className="logo"
          src="https://as1.ftcdn.net/v2/jpg/02/22/82/06/1000_F_222820631_WRuNhAnUV3N8V78LYAo2SYaPY8bVqvbD.jpghttps://t3.ftcdn.net/jpg/02/22/82/06/360_F_222820631_WRuNhAnUV3N8V78LYAo2SYaPY8bVqvbD.jpg"
          alt="Logo"
        />
      </Link>

      {/* Fortschrittsbalken wird nur angezeigt, wenn ein Ergebnis vorliegt */}
      {result && (
        <ProgressBar
          completed={Math.round((result.rightAnswers / result.totalQuestions) * 100)}  // Aufrunden
          bgColor="#06D001"
          baseBgColor="grey"
          labelColor="black"
          labelAlignment="center"
          labelSize="14px"
        />
      )}

      {!isAnswered && questions.length > 0 && (
        questions.map(question => (
          <div className="Erklärungscard-Div" key={question.id}>
            <p>{question.question}</p>
            <div className="options">
              {question.options.map(option => (
                <div key={option.id}>
                  <input
                    type="checkbox"
                    id={`${question.id}-${option.id}`}
                    name={`question-${question.id}`}
                    value={option.id}
                    checked={answers[question.id] && answers[question.id].includes(option.id)}
                    onChange={() => handleOptionSelect(question.id, option.id)}
                  />
                  <label htmlFor={`${question.id}-${option.id}`}>{option.text}</label>
                </div>
              ))}
            </div>
          </div>
        ))
      )}

      {!isAnswered && (
        <button
          className='button-87'
          onClick={() => {
            checkAnswers();
            setCheck(true);
          }}
        >
          Überprüfen
        </button>
      )}

      {result && (
        <div className="Erklärungscard-Div">
          <p>Du hast {result.rightAnswers} von {result.totalQuestions} Fragen richtig beantwortet.</p>
        </div>
      )}
    </div>
  );
};

export default Daily;
