import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import firestore from "../../firebase";

export const QuizChoice2 = ({ onQuizResult }) => {
  const initialQuestions = [
    {
      id: 1,
      question: 'Was ist das Hauptziel von Social Engineering-Angriffen?',
      options: [
        { id: 'a', text: 'Die Manipulation von Maschinen', },
        { id: 'b', text: 'Die Überlastung von Netzwerken' },
        { id: 'c', text: 'Das Überlisten von Menschen, um an sensible Informationen zu gelangen', correct: true },
        { id: 'd', text: 'Die Verschlüsselung von Daten', }
      ]
    },
    {
      id: 2,
      question: 'Welches dieser Szenarien ist ein Beispiel für Social Engineering?',
      options: [
        { id: 'a', text: 'Ein Hacker nutzt eine Software-Schwachstelle aus, um in ein System einzudringen.', },
        { id: 'b', text: 'Eine Person gibt sich am Telefon als IT-Mitarbeiter aus, um das Passwort eines Mitarbeiters zu erfragen.', correct: true },
        { id: 'c', text: 'Ein Virus verbreitet sich über einen E-Mail-Anhang.', },
        { id: 'd', text: 'Eine Firewall blockiert unerlaubte Zugriffe auf ein Netzwerk.', }
      ]
    },
    {
      id: 3,
      question: 'Welche der folgenden Maßnahmen kann helfen, sich gegen Social Engineering-Angriffe zu schützen?',
      options: [
        { id: 'a', text: 'Misstrauisch gegenüber unerwarteten E-Mails sein', correct: true },
        { id: 'b', text: 'Regelmäßige Sicherheitsupdates durchführen', },
        { id: 'c', text: 'Keine vertraulichen Informationen am Telefon preisgeben', correct: true },
        { id: 'd', text: 'Öffnen aller Anhänge in E-Mails, um sie zu überprüfen', }
      ]
    },
    {
      id: 4,
      question: 'Warum sind soziale Netzwerke ein beliebtes Ziel für Social Engineering-Angriffe?',
      options: [
        { id: 'a', text: 'Weil sie keine Sicherheitsfunktionen haben', },
        { id: 'b', text: 'Weil Benutzer oft persönliche Informationen teilen, die für Angriffe genutzt werden können', correct: true },
        { id: 'c', text: 'Weil sie nur von IT-Experten genutzt werden', },
        { id: 'd', text: 'Weil sie automatisch vertrauliche Informationen preisgeben', }
      ]
    },
    {
      id: 5,
      question: 'Welche Methode wird häufig bei Social Engineering-Angriffen verwendet, um das Vertrauen des Opfers zu gewinnen?',
      options: [
        { id: 'a', text: 'Technische Exploits verwenden', },
        { id: 'b', text: 'Sich als vertrauenswürdige Institution oder Person ausgeben', correct: true },
        { id: 'c', text: 'Physische Sicherheitssysteme umgehen', },
        { id: 'd', text: 'Software-Updates erzwingen', }
      ]
    }
  ];

  const [questions, setQuestions] = useState(initialQuestions);
  const [answers, setAnswers] = useState({});
  const [result, setResult] = useState(0);  // Zustand für das Ergebnis
  const [progress, setProgress] = useState(0); // Zustand für den Fortschritt
  const [resultsDB, setResultsDB] = useState(0);
  const [maxResultsDB, setMaxResultsDB] = useState(0);
  const [check, setCheck] = useState(false);


  const handleOptionSelect = (questionId, optionId) => {
    const newAnswers = { ...answers };
    if (newAnswers[questionId]) {
      // If question already has answers, toggle the option
      if (newAnswers[questionId].includes(optionId)) {
        newAnswers[questionId] = newAnswers[questionId].filter(id => id !== optionId);
      } else {
        newAnswers[questionId].push(optionId);
      }
    } else {
      // If no answers yet, create new array with the selected option
      newAnswers[questionId] = [optionId];
    }
    setAnswers(newAnswers);
  };

  const checkAnswers = () => {
    let rightAnswers = 0;
    let maxAnswers = 0;
    questions.forEach(question => {
      const selectedOptions = answers[question.id] || [];
      const correctOptions = question.options.filter(option => option.correct).map(option => option.id);
      maxAnswers++;
      if (selectedOptions.length === correctOptions.length && selectedOptions.every(option => correctOptions.includes(option))) {
        rightAnswers++;
      }
    });

    setResultsDB(rightAnswers);
    setMaxResultsDB(maxAnswers);

    const totalQuestions = questions.length;
    setResult({ rightAnswers, totalQuestions });

    const progressPercentage = (rightAnswers / totalQuestions) * 100;
    setProgress(progressPercentage);

    if (typeof onQuizResult === 'function') {
      onQuizResult({ rightAnswers, totalQuestions });
    }

    updateDatabase();
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");

    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
  
      // Prüfe, ob das Dokument existiert
      if (!userDocSnap.exists()) {
        console.error("Benutzerdokument existiert nicht.");
        return;
      }
  
      const userData = userDocSnap.data();
      const currentStars = resultsDB;
      const currentMaxStars = maxResultsDB;
      
      // Vergleiche die aktuellen Ergebnisse mit den gespeicherten Ergebnissen
      if (!userData.starsGefahr2 || !userData.maxStarsGefahr2) {
        // Initialisiere die Arrays, wenn sie nicht existieren
        userData.starsGefahr2 = [];
        userData.maxStarsGefahr2 = [];
      }
  
      // Überprüfe, ob die aktuellen Ergebnisse besser sind als die gespeicherten Ergebnisse
      if (!userData.starsGefahr2[1] || currentStars > userData.starsGefahr2[1]) {
        userData.starsGefahr2[1] = currentStars;
      }
      
      if (!userData.maxStarsGefahr2[1] || currentMaxStars > userData.maxStarsGefahr2[1]) {
        userData.maxStarsGefahr2[1] = currentMaxStars;
      }
  
      // Speichern der aktualisierten Daten
      await setDoc(userDoc, userData);
      
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  useEffect(() => {
    if (check == true){
      checkAnswers();
      setCheck(false);
    }
  }, );
  

  return (
    <div className="quiz-container">
      <Link className="Link" to="/Gefahr2">
      <img
          className="logo"
          src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
          alt="Logo"
        />
      </Link>

      {/* Fortschrittsbalken wird nur angezeigt, wenn ein Ergebnis vorliegt */}
      {result && (
        <ProgressBar
        completed={Math.round((resultsDB / maxResultsDB) * 100)}  // Aufrunden
        bgColor="#06D001"
          baseBgColor="grey"
          labelColor="black"
          labelAlignment="center"
          labelSize="14px"
        />
      )}

      {questions.map(question => (
        <div className="Erklärungscard-Div-Choice2" key={question.id}>
          <p>{question.question}</p>
          <div className="options">
            {question.options.map(option => (
              <div key={option.id}>
                <input
                  type="checkbox"
                  id={`${question.id}-${option.id}`}
                  name={`question-${question.id}`}
                  value={option.id}
                  checked={answers[question.id] && answers[question.id].includes(option.id)}
                  onChange={() => handleOptionSelect(question.id, option.id)}
                />
                <label htmlFor={`${question.id}-${option.id}`} style={{ whiteSpace: 'nowrap' }}>{option.text}</label>
              </div>
            ))}
          </div>
        </div>
      ))}

      <button
        className='button-87'
        onClick={() => {
          checkAnswers();   
          setCheck(true);       
        }}
      >
        Überprüfen
      </button>
      <br></br>
      <br></br>
      
      {result && (
        <div className="Erklärungscard-Div">
          <p>Du hast {result.rightAnswers} von {result.totalQuestions} Fragen richtig beantwortet.</p>
        </div>
      )}
    </div>
  );
};

export default QuizChoice2;
