import { motion } from "framer-motion";
import { Link, Redirect } from 'react-router-dom';

export const UeberUns = () => {
 
    return (
      <div class="containerLogin">

        <Link className="Link" to="/">
            <img
            className="logo"
            src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
            alt="Logo"
            />
        </Link>
        
          <div className="container_aboutus" >
          <a className="title">Das Team</a>
          <div style={{alignContent:'center',backgroundColor:'transparent'}}>
            <img style={{width:'10%'}} src="https://freepngimg.com/download/team/6-2-team-png-file.png" ></img>
            </div>
            <h3>Das Team SafeSurf, besteht aus Markward Forkel, Lukas Willms, Herman Zerf und Matthias Betz. Wir sind Studenten an der Technischen Hochschule Würzburg-Schweinfurt und studieren Wirtschaftsinformatik B. Sc.. Was Sie hier sehen ist das Ergebnis unserer Projektarbeit.</h3>

            <a className="title">Das Ziel</a>
            <div style={{alignContent:'center',backgroundColor:'transparent'}}>
            <img style={{width:'10%'}} src="https://i.pinimg.com/originals/d4/8a/ea/d48aeae3ae7b221045128c4c0ee6e2ec.png" ></img>
            </div>
            <h3>Umsetzung von "Gamified Learning". Wir kombinieren Spielmechaniken mit Bildungsinhalten, um das Lernen spannender und effizienter zu gestalten. Dies steigert die Motivation, fördert aktive Beteiligung, ermöglicht Fehlerfreundlichkeit und unterstützt langfristiges Engagement. </h3>

          </div>
       
        

      </div>
    )
  }