import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, setDoc } from "firebase/firestore";
import firestore from "../../firebase";
import ProgressBar from "@ramonak/react-progress-bar";

export const Gefahr3 = () => {
  const [progress, setProgress] = useState({
    QuizDragAndDrop1: 0,
    QuizLueckentext1: 0,
    QuizMatching1: 0,
    QuizChoice1: 0,
    QuizFillIn1: 0,
  });

  const [progressDB, setProgressDB] = useState(0); // Initialisiert mit 0
  const [maxProgressDB, setMaxProgressDB] = useState(0); // Initialisiert mit 0

  const updateProgress = (quizName, newProgress) => {
    setProgress(prevProgress => ({
      ...prevProgress,
      [quizName]: newProgress,
    }));
  };

  const totalProgress = Object.values(progress).reduce((acc, cur) => acc + cur, 0) / 5;

  const videoOptions = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  const getDocument = async () => {
    try {
      const docRef = doc(firestore, "Users", getAuth().currentUser.email);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data();

      if (userData && userData.starsGefahr3 !== undefined) {
        setProgressDB(userData.starsGefahr3[0] + userData.starsGefahr3[1] + userData.starsGefahr3[2]);
        setMaxProgressDB(userData.maxStarsGefahr3[0] + userData.maxStarsGefahr3[1] + userData.maxStarsGefahr3[2]);
      } else {
        console.error("starsGefahr1 nicht gefunden im Dokument");
      }

    } catch (e) {
      console.error("Fehler beim Abrufen des Dokuments aus der Datenbank", e);
    }
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");

    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();

      if (progressDB / maxProgressDB > 0.4){
        userData.unlockGefahr3 = true;
      }
      else {
        userData.unlockGefahr3 = false;
      }

      await setDoc(userDoc, userData);
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  // useEffect wird hier eingebaut
  useEffect(() => {
    getDocument();
  }, ); // Leeres Abhängigkeitsarray sorgt dafür, dass getDocument() nur einmal bei der ersten Render ausgeführt wird.


  return (
    <div className="gefahr3">

      <div className="progressBar" >
        <ProgressBar
          completed={Math.round((progressDB / maxProgressDB) * 100)}  // Aufrunden
          bgColor="#06D001"
          baseBgColor="grey"
          labelColor="black"
          labelAlignment="center"
          labelSize="14px"
        />
      </div>

      <Link className="Link" onClick={updateDatabase} to="/StartseiteLoggedIn" >
        <img className="logo" src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image" alt="Logo" />
      </Link>

      <div className="gefahr-text">
    <h1>Phishing</h1>
    
    <p><strong>Was ist Phishing?<br /><br /></strong>
        Phishing ist eine Cyberangriffstechnik, bei der Kriminelle versuchen, über gefälschte E-Mails, Websites oder Nachrichten an persönliche und vertrauliche Informationen zu gelangen. 
        Ziel ist es, den Empfänger zu täuschen und ihn dazu zu bringen, sensible Daten wie Passwörter, Kreditkarteninformationen oder andere persönliche Details preiszugeben.
    </p>
    
    <p><strong>Wie funktioniert Phishing?</strong>
    <ul><br />
        <li><strong>Gefälschte Kommunikation:</strong><br />
            Angreifer senden E-Mails oder Nachrichten, die wie legitime Kommunikation von vertrauenswürdigen Quellen aussehen, z.B. von Banken, sozialen Netzwerken oder Online-Diensten. 
            Diese Nachrichten fordern den Empfänger oft auf, dringend zu handeln, beispielsweise sein Konto zu verifizieren oder ein Problem zu beheben.
        </li><br />
        
        <li><strong>Bösartige Links und Anhänge:</strong><br />
            Die Phishing-Nachricht enthält in der Regel Links zu gefälschten Websites oder Anhänge, die Malware enthalten. 
            Die gefälschte Website ist so gestaltet, dass sie dem Original täuschend ähnlich sieht und den Nutzer dazu bringt, seine Anmeldedaten einzugeben.
        </li><br />
        
        <li><strong>Datendiebstahl:</strong><br />
            Sobald der Nutzer seine Daten auf der gefälschten Website eingibt, werden diese direkt an die Angreifer übermittelt, die sie dann für betrügerische Zwecke verwenden können, 
            wie den Zugriff auf Bankkonten oder das Verkaufen der Daten im Darknet.
        </li>
    </ul></p>
    
    <p><strong>Warum ist Phishing gefährlich?</strong>
    <ul><br />
        <li><strong>Täuschende Echtheit:</strong><br />
            Phishing-Angriffe sind oft so gut gemacht, dass sie selbst für erfahrene Nutzer schwer zu erkennen sind. 
            Die gefälschten E-Mails und Websites sehen oft identisch mit den echten aus.
        </li><br />
        
        <li><strong>Hohe Verbreitung:</strong><br />
            Phishing ist eine der am weitesten verbreiteten Formen von Cyberangriffen, da es einfach durchzuführen und oft sehr effektiv ist.
        </li><br />
        
        <li><strong>Schwerwiegende Folgen:</strong><br />
            Ein erfolgreicher Phishing-Angriff kann zu Identitätsdiebstahl, finanziellen Verlusten oder unbefugtem Zugriff auf persönliche und berufliche Konten führen.
        </li>
    </ul></p>
    
    <p><strong>Wie kann man sich schützen?</strong>
    <ul><br />
        <li><strong>Aufmerksam bleiben:</strong><br />
            Misstrauen Sie unerwarteten E-Mails oder Nachrichten, besonders wenn sie Dringlichkeit vermitteln oder persönliche Informationen verlangen.
        </li><br />
        
        <li><strong>Überprüfen Sie Links:</strong><br />
            Fahren Sie mit der Maus über Links, bevor Sie darauf klicken, um zu sehen, wohin sie tatsächlich führen. Achten Sie auf ungewöhnliche oder unbekannte URLs.
        </li><br />
        
        <li><strong>Zwei-Faktor-Authentifizierung (2FA):</strong><br />
            Verwenden Sie die Zwei-Faktor-Authentifizierung, wo immer möglich, um zusätzlichen Schutz für Ihre Konten zu gewährleisten.
        </li><br />
        
        <li><strong>Antivirenprogramme:</strong><br />
            Halten Sie Ihre Sicherheitssoftware aktuell, um sich vor Phishing-Malware zu schützen.
        </li>
    </ul></p>
    
    <p><strong>Zusammenfassung:<br /><br /></strong>
        Phishing ist eine weit verbreitete und gefährliche Methode des Cyberangriffs, bei der gefälschte Kommunikation verwendet wird, um sensible Daten zu stehlen. 
        Schutzmaßnahmen wie erhöhte Wachsamkeit, die Überprüfung von Links und die Verwendung der Zwei-Faktor-Authentifizierung können helfen, das Risiko eines erfolgreichen Phishing-Angriffs zu minimieren.
    </p>
</div>


      <div className="video-container">
        <h2>Mehr über Phishing</h2>
        <YouTube videoId="W0CcOPhiW48" opts={videoOptions} />
      </div>

      <div className="Quiz-button">
        <div className="containerQuizze">
          <Link className="Link" to="/QuizLueckentext3">
            <button className='button-87'>Lückentext Quiz</button>
          </Link>
          <Link className="Link" to="/QuizMatching3">
            <button className='button-87'>Kombiniere Quiz</button>
          </Link>
          <Link className="Link" to="/QuizChoice3">
            <button className='button-87'>Multiple- oder Single-Choice-Quiz</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
