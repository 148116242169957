import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, setDoc } from "firebase/firestore";
import firestore from "../../firebase";
import ProgressBar from "@ramonak/react-progress-bar";

export const Gefahr5 = () => {
  const [progress, setProgress] = useState({
    QuizDragAndDrop1: 0,
    QuizLueckentext1: 0,
    QuizMatching1: 0,
    QuizChoice1: 0,
    QuizFillIn1: 0,
  });

  const [progressDB, setProgressDB] = useState(0); // Initialisiert mit 0
  const [maxProgressDB, setMaxProgressDB] = useState(0); // Initialisiert mit 0

  const updateProgress = (quizName, newProgress) => {
    setProgress(prevProgress => ({
      ...prevProgress,
      [quizName]: newProgress,
    }));
  };

  const totalProgress = Object.values(progress).reduce((acc, cur) => acc + cur, 0) / 5;

  const videoOptions = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  const getDocument = async () => {
    try {
      const docRef = doc(firestore, "Users", getAuth().currentUser.email);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data();

      if (userData && userData.starsGefahr5 !== undefined) {
        setProgressDB(userData.starsGefahr5[0] + userData.starsGefahr5[1] + userData.starsGefahr5[2]);
        setMaxProgressDB(userData.maxStarsGefahr5[0] + userData.maxStarsGefahr5[1] + userData.maxStarsGefahr5[2]);
      } else {
        console.error("starsGefahr1 nicht gefunden im Dokument");
      }

    } catch (e) {
      console.error("Fehler beim Abrufen des Dokuments aus der Datenbank", e);
    }
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");

    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();

      if (progressDB / maxProgressDB > 0.4){
        userData.unlockGefahr5 = true;
      }
      else {
        userData.unlockGefahr5 = false;
      }

      await setDoc(userDoc, userData);
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  // useEffect wird hier eingebaut
  useEffect(() => {
    getDocument();
  }, ); // Leeres Abhängigkeitsarray sorgt dafür, dass getDocument() nur einmal bei der ersten Render ausgeführt wird.

  return (
    <div className="gefahr2">

      <div className="progressBar" >
        <ProgressBar
          completed={Math.round((progressDB / maxProgressDB) * 100)}  // Aufrunden
          bgColor="#06D001"
          baseBgColor="grey"
          labelColor="black"
          labelAlignment="center"
          labelSize="14px"
        />
      </div>

      <Link className="Link" onClick={updateDatabase} to="/StartseiteLoggedIn" >
        <img className="logo" src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image" alt="Logo" />
      </Link>

      <div className="gefahr-text">
    <h1>Malware</h1>
    
    <p><strong>Definition:<br /><br /></strong>
        Malware, kurz für "malicious software", bezeichnet jede Art von bösartiger Software, die entwickelt wurde, um Computer, Netzwerke oder andere digitale Geräte zu schädigen, zu stören oder unberechtigten Zugriff darauf zu erhalten.
    </p>
    
    <p><strong>Hauptarten von Malware:</strong>
    
    <ul><br />
        <li><strong>Viren:</strong><br />
            Schädliche Programme, die sich an andere Programme oder Dateien anhängen und sich verbreiten, wenn diese ausgeführt werden. Viren können Daten zerstören, Systeme instabil machen oder andere schädliche Aktionen ausführen.
        </li><br />
        
        <li><strong>Würmer:</strong><br />
            Ähnlich wie Viren, aber mit der Fähigkeit, sich eigenständig über Netzwerke zu verbreiten. Würmer nutzen oft Sicherheitslücken aus und können große Netzwerke lahmlegen.
        </li><br />
        
        <li><strong>Trojaner:</strong><br />
            Malware, die sich als legitime Software tarnt, um unbemerkt auf ein System zu gelangen. Sobald installiert, können Trojaner Hintertüren öffnen, Daten stehlen oder andere Malware nachladen.
        </li><br />
        
        <li><strong>Ransomware:</strong><br />
            Diese Art von Malware verschlüsselt die Dateien des Opfers und fordert ein Lösegeld, um den Zugriff darauf wieder freizugeben. Ransomware ist besonders zerstörerisch und kann zu erheblichen finanziellen Verlusten führen.
        </li><br />
        
        <li><strong>Spyware:</strong><br />
           Malware, die das System des Opfers ausspioniert, um vertrauliche Informationen wie Passwörter, Kreditkartendaten oder Browserverläufe zu sammeln und an den Angreifer weiterzuleiten.
        </li><br />
        
        <li><strong>Adware:</strong><br />
            Software, die Werbung anzeigt, oft in Form von Pop-ups. Während einige Adware harmlos ist, kann sie in bösartige Aktivitäten verwickelt sein, wie das Sammeln von Nutzerdaten ohne Zustimmung.
        </li><br />
        
        <li><strong>Rootkits:</strong><br />
            Diese Malware versteckt sich tief im System, oft auf Root-Ebene, und ermöglicht es Angreifern, die Kontrolle über das Gerät zu übernehmen und sich vor Entdeckung zu schützen.
        </li><br />
        
        <li><strong>Bots/Botnets:</strong><br />
            Infizierte Computer, die Teil eines Netzwerks (Botnet) werden, das von einem Botmaster gesteuert wird. Botnets können für Angriffe wie DDoS (Denial of Service), Spam-Versand und andere schädliche Aktivitäten verwendet werden.
        </li>
    </ul></p>
    
    <p><strong>Gefahren durch Malware:</strong>
    <ul><br />
        <li><strong>Datenverlust:</strong><br />
            Malware kann Dateien löschen, verschlüsseln oder manipulieren, was zu irreparablem Datenverlust führen kann.
        </li><br />
        
        <li><strong>Identitätsdiebstahl:</strong><br />
            Spyware und Trojaner können persönliche und finanzielle Daten stehlen, die dann für betrügerische Aktivitäten genutzt werden.
        </li><br />
        
        <li><strong>Systemschäden:</strong><br />
            Malware kann Computer und Netzwerke verlangsamen, instabil machen oder komplett unbrauchbar machen.
        </li><br />
        
        <li><strong>Finanzielle Verluste:</strong><br />
            Ransomware-Angriffe und der Diebstahl von Finanzdaten können erhebliche finanzielle Schäden verursachen.
        </li><br />
        
        <li><strong>Reputation:</strong><br />
            Für Unternehmen kann ein Malware-Angriff den Verlust von Kundenvertrauen und einen langfristigen Reputationsschaden bedeuten.
        </li>
    </ul></p>
    
    <p><strong>Schutzmaßnahmen:</strong>
    <ul><br />
        <li><strong>Aktuelle Software:</strong><br />
            Regelmäßige Updates für Betriebssysteme und Anwendungen, um Sicherheitslücken zu schließen.
        </li><br />
        
        <li><strong>Antivirensoftware:</strong><br />
            Verwenden und regelmäßiges Aktualisieren von Antiviren- und Antimalware-Programmen.
        </li><br />
        
        <li><strong>Vorsicht beim Download:</strong><br />
            Keine Software aus unsicheren oder unbekannten Quellen herunterladen.
        </li><br />
        
        <li><strong>Sicheres Surfen:</strong><br />
            Auf Phishing-Versuche achten und verdächtige Links oder Anhänge in E-Mails vermeiden.
        </li><br />
        
        <li><strong>Backups:</strong><br />
            Regelmäßige Sicherung wichtiger Daten, um im Falle eines Angriffs schnell wiederherstellen zu können.
        </li>
    </ul></p>
    
    <p><strong>Zusammenfassung:<br /><br /></strong>
        Malware ist eine erhebliche Bedrohung für die Sicherheit und Integrität von Computersystemen und Netzwerken. 
        Durch Wissen über die verschiedenen Arten von Malware und die Implementierung von Sicherheitsmaßnahmen können die Risiken jedoch erheblich reduziert werden.
    </p>
</div>


      <div className="video-container">
        <h2>Youtube Video</h2>
        <YouTube videoId="WHmGzb8bRaQ" opts={videoOptions} />
      </div>

      <div className="Quiz-button">
        <div className="containerQuizze">
          <Link className="Link" to="/QuizLueckentext5">
            <button className='button-87'>Lückentext Quiz</button>
          </Link>
          <Link className="Link" to="/QuizChoice5">
            <button className='button-87'>Mutliple- Single-Choice-Quiz</button>
          </Link>
          <Link className="Link" to="/QuizDragAndDrop5">
            <button className='button-87'>Draggable Quiz</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
