import { getAuth, onAuthStateChanged,GoogleAuthProvider, signInWithPopup } from '@firebase/auth';
import { initializeApp } from 'firebase/app';
import { useState, useEffect, useContext, createContext } from 'react';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyDMgWXa7O5xbKi-PhlZdtP30XtOCY7qpNs",
  authDomain: "internetfuehrerschein-7c740.firebaseapp.com",
  projectId: "internetfuehrerschein-7c740",
  storageBucket: "internetfuehrerschein-7c740.appspot.com",
  messagingSenderId: "1043229967203",
  appId: "1:1043229967203:web:cd70bc1545e7202eeb4956",
  measurementId: "G-WZ8L6ZT9ZY"
});

const provider = new GoogleAuthProvider();
export const AuthContext = createContext();
const auth = getAuth();
auth.languageCode ='de';

export const AuthContextProvider = (props) => {

  const [user, setUser] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError);
    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider value={{ user, error }} {...props} />;
};

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.user != null }
}

const firestore = getFirestore(firebaseApp);
export default firestore;

export const FirebaseStorage = getStorage(firebaseApp);
export const storage = getStorage(firebaseApp);
