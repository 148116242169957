import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import { getAuth } from "firebase/auth";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import firestore from "../../firebase";

export const QuizChoiceGesamtuebung2 = ({ onQuizResult }) => {
  const initialQuestions = [
    {
      id: 1,
      question: 'Welche Beschreibung trifft auf einen Virus zu?',
      options: [
        { id: 'a', text: 'Er zeigt ständig Pop-up-Werbung an' },
        { id: 'b', text: 'Er überwacht heimlich die Aktivitäten des Benutzers' },
        { id: 'c', text: 'Er ist ein eigenständiges Programm, das sich verbreiten kann' },
        { id: 'd', text: 'Er hängt sich an andere Programme an, um sich zu verbreiten', correct: true }
      ]
    },
    {
      id: 2,
      question: 'Welche Art von Malware wird verwendet, um vertrauliche Informationen wie Passwörter zu stehlen?',
      options: [
        { id: 'a', text: 'Trojaner', orrect: true },
        { id: 'b', text: 'Adware' },
        { id: 'c', text: 'Rootkit' },
        { id: 'd', text: 'Wurm',  }
      ]
    },
    {
      id: 3,
      question: 'Wofür werden Botnetze typischerweise verwendet?',
      options: [
        { id: 'a', text: 'Zur Erstellung von Sicherheitssoftware' },
        { id: 'b', text: 'Zum Schutz vor Malware' },
        { id: 'c', text: 'Zum Speichern von Cloud-Daten' },
        { id: 'd', text: 'Zum Versenden von Spam-E-Mails', correct: true }
      ]
    },
    {
      id: 4,
      question: 'Wie werden Computer typischerweise Teil eines Botnetzes?',
      options: [
        { id: 'a', text: 'Durch Infektion mit Malware, die den Computer fernsteuert', correct: true },
        { id: 'b', text: 'Durch die manuelle Anmeldung beim Botnetz' },
        { id: 'c', text: 'Durch das Herunterladen einer offiziellen Software' },
        { id: 'd', text: 'Durch die Installation eines Betriebssystem-Updates' }
      ]
    },
    {
      id: 5,
      question: 'Was ist eine SQL-Injection?',
      options: [
        { id: 'a', text: 'Ein Tool zur Überwachung von Netzwerkaktivitäten' },
        { id: 'b', text: 'Eine Art von Computervirus' },
        { id: 'c', text: 'Eine Technik, bei der schädliche SQL-Befehle in eine Datenbank eingeschleust werden', correct: true },
        { id: 'd', text: 'Eine Methode zur sicheren Verschlüsselung von Daten' }
      ]
    },
    {
      id: 6,
      question: 'Was ist das Hauptziel eines Cross-Site Scripting (XSS)-Angriffs?',
      options: [
        { id: 'a', text: 'Das Einschleusen von bösartigem Skriptcode in eine Webseite, um Benutzerdaten zu stehlen', correct: true },
        { id: 'b', text: 'Das Abrufen von Sicherheitsupdates für Webanwendungen' },
        { id: 'c', text: 'Die Durchführung eines DDoS-Angriffs' },
        { id: 'd', text: 'Die Komprimierung von Daten auf einem Server' }
      ]
    }
  ];

  const [questions, setQuestions] = useState(initialQuestions);
  const [answers, setAnswers] = useState({});
  const [result, setResult] = useState(null);  // Zustand für das Ergebnis
  const [progress, setProgress] = useState(0); // Zustand für den Fortschritt
  const [resultsDB, setResultsDB] = useState(0);
  const [maxResultsDB, setMaxResultsDB] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false); // Zustand für die Anzeige des Fortschrittsbalkens
  const [check, setCheck] = useState(false);

  const handleOptionSelect = (questionId, optionId) => {
    const newAnswers = { ...answers };
    if (newAnswers[questionId]) {
      // If question already has answers, toggle the option
      if (newAnswers[questionId].includes(optionId)) {
        newAnswers[questionId] = newAnswers[questionId].filter(id => id !== optionId);
      } else {
        newAnswers[questionId].push(optionId);
      }
    } else {
      // If no answers yet, create new array with the selected option
      newAnswers[questionId] = [optionId];
    }
    setAnswers(newAnswers);
  };

  const checkAnswers = () => {
    let rightAnswers = 0;
    let maxAnswers = 0;
    questions.forEach(question => {
      const selectedOptions = answers[question.id] || [];
      const correctOptions = question.options.filter(option => option.correct).map(option => option.id);
      maxAnswers++;
      if (selectedOptions.length === correctOptions.length && selectedOptions.every(option => correctOptions.includes(option))) {
        rightAnswers++;
      }
    });

    setResultsDB(rightAnswers);
    setMaxResultsDB(maxAnswers);

    const totalQuestions = questions.length;
    setResult({ rightAnswers, totalQuestions });

    const progressPercentage = (rightAnswers / totalQuestions) * 100;
    setProgress(progressPercentage);
    setShowProgressBar(true); // Zeigt den Fortschrittsbalken an, nachdem die Antworten überprüft wurden

    if (typeof onQuizResult === 'function') {
      onQuizResult({ rightAnswers, totalQuestions });
    }

    updateDatabase();
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");
  
    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
  
      // Prüfe, ob das Dokument existiert
      if (!userDocSnap.exists()) {
        console.error("Benutzerdokument existiert nicht.");
        return;
      }
  
      const userData = userDocSnap.data();
      const currentStars = resultsDB;
      const currentMaxStars = maxResultsDB;
      
      // Vergleiche die aktuellen Ergebnisse mit den gespeicherten Ergebnissen
      if (!userData.starsGesamt2 || !userData.maxStarsGesamt2) {
        // Initialisiere die Arrays, wenn sie nicht existieren
        userData.starsGesamt2 = [];
        userData.maxStarsGesamt2 = [];
      }
  
      // Überprüfe, ob die aktuellen Ergebnisse besser sind als die gespeicherten Ergebnisse
      if (!userData.starsGesamt2[1] || currentStars > userData.starsGesamt2[1]) {
        userData.starsGesamt2[1] = currentStars;
      }
      
      if (!userData.maxStarsGesamt2[1] || currentMaxStars > userData.maxStarsGesamt2[1]) {
        userData.maxStarsGesamt2[1] = currentMaxStars;
      }
  
      // Speichern der aktualisierten Daten
      await setDoc(userDoc, userData);
      
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  useEffect(() => {
    if (check == true){
      checkAnswers();
      setCheck(false);
    }
  }, );

  return (
    <div className="quiz-container">
      <Link className="Link" to="/QuizGesamtuebung2">
      <img
          className="logo"
          src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
          alt="Logo"
        />
      </Link>

      {showProgressBar && (
        <ProgressBar
        completed={Math.round((resultsDB / maxResultsDB) * 100)}  // Aufrunden
        bgColor="#06D001"
        baseBgColor="grey"
        labelColor="black"
        labelAlignment="center"
        labelSize="14px"
      />
      )}

      {questions.map(question => (
        <div className="Erklärungscard-Div-Choice1" key={question.id}>
          <p>{question.question}</p>
          <div className="options">
            {question.options.map(option => (
              <div key={option.id}>
                <input
                  type="checkbox"
                  id={`${question.id}-${option.id}`}
                  name={`question-${question.id}`}
                  value={option.id}
                  checked={answers[question.id] && answers[question.id].includes(option.id)}
                  onChange={() => handleOptionSelect(question.id, option.id)}
                />
                <label htmlFor={`${question.id}-${option.id}`}>{option.text}</label>
              </div>
            ))}
          </div>
        </div>
      ))}

      <button
        className='button-87'
        onClick={() => {
          checkAnswers();   
          setCheck(true);       
        }}
      >
        Überprüfen
      </button>
        <br />
      <br />
      
      {result && (
        <div className="Erklärungscard-Div">
          <p>Du hast {result.rightAnswers} von {result.totalQuestions} Fragen richtig beantwortet.</p>
        </div>
      )}
    </div>
  );
};

export default QuizChoiceGesamtuebung2;
