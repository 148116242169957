import '../../App.css';
import { Link } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, setDoc } from "firebase/firestore";
import firestore from "../../firebase";

export const Einfuehrung1 = () => {

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");

    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();

      console.log(getAuth().currentUser.email);

      if (userData && userData.unlockEin1 !== undefined) {
        userData.unlockEin1 = true;
      } else {
        console.error("unlockEin1 nicht gefunden im Dokument");
      }

      await setDoc(userDoc, userData);
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  return (
    <div className="einfuehrung1">

      <Link className="Link" onClick={updateDatabase} to="/StartseiteLoggedIn" >
        <img className="logo" src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image" alt="Logo" />
      </Link>

      <div className="gefahr-text">
        <h1>SafeSurf</h1>
        <p>   
          <h4>Hintergrund:</h4>
            SafeSurf wurde entwickelt, um Internetnutzern die möglichen Gefahren des Internets näherzubringen. Damit die Nutzer auch Spaß am Lernen haben und motiviert bleiben, verwenden wir verschiedene Gamification-Elemente, die das Lernen endlich einmal unterhaltsam machen.
           <img class="team-image" src="https://cdn-icons-png.flaticon.com/512/6180/6180617.png" />
          <p>
            <li>Sammle Badges</li>
            <br></br>
            <li>Teste dein Wissen</li>
            <br></br>
            <li>Vergleiche dich im Leaderboard</li>
          </p>
          </p>
          </div>
        <div className="gefahr-text">
          <p>
          <h4>Das Team hinter SafeSurf:</h4>
          <img class="team-image" src="https://www.svgrepo.com/show/10747/team.svg" /> 
          <p>
          <li>Hermann Zerf <font size="3">Student THWS</font> </li>
          <br></br>
          <li>Lukas Willms <font size="3">Student THWS</font></li>
          <br></br>
          <li>Markward Forkel <font size="3">Student THWS</font></li>
          <br></br>
          <li>Matthias Betz <font size="3">Student THWS</font></li>
          </p>
          </p>
      </div>
      </div>
  );
};
