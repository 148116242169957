import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, setDoc } from "firebase/firestore";
import firestore from "../../firebase";
import ProgressBar from "@ramonak/react-progress-bar";

export const Gefahr4 = () => {
  const [progress, setProgress] = useState({
    QuizDragAndDrop1: 0,
    QuizLueckentext1: 0,
    QuizMatching1: 0,
    QuizChoice1: 0,
    QuizFillIn1: 0,
  });

  const [progressDB, setProgressDB] = useState(0); // Initialisiert mit 0
  const [maxProgressDB, setMaxProgressDB] = useState(0); // Initialisiert mit 0

  const updateProgress = (quizName, newProgress) => {
    setProgress(prevProgress => ({
      ...prevProgress,
      [quizName]: newProgress,
    }));
  };

  const totalProgress = Object.values(progress).reduce((acc, cur) => acc + cur, 0) / 5;

  const videoOptions = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  const getDocument = async () => {
    try {
      const docRef = doc(firestore, "Users", getAuth().currentUser.email);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data();

      if (userData && userData.starsGefahr4 !== undefined) {
        setProgressDB(userData.starsGefahr4[0] + userData.starsGefahr4[1] + userData.starsGefahr4[2]);
        setMaxProgressDB(userData.maxStarsGefahr4[0] + userData.maxStarsGefahr4[1] + userData.maxStarsGefahr4[2]);
      } else {
        console.error("starsGefahr1 nicht gefunden im Dokument");
      }

    } catch (e) {
      console.error("Fehler beim Abrufen des Dokuments aus der Datenbank", e);
    }
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");

    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      const userData = userDocSnap.data();

      if (progressDB / maxProgressDB > 0.4){
        userData.unlockGefahr4 = true;
      }
      else {
        userData.unlockGefahr4 = false;
      }

      await setDoc(userDoc, userData);
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  // useEffect wird hier eingebaut
  useEffect(() => {
    getDocument();
  }, ); // Leeres Abhängigkeitsarray sorgt dafür, dass getDocument() nur einmal bei der ersten Render ausgeführt wird.


  return (
    <div className="gefahr2">

      <div className="progressBar" >
        <ProgressBar
          completed={Math.round((progressDB / maxProgressDB) * 100)}  // Aufrunden
          bgColor="#06D001"
          baseBgColor="grey"
          labelColor="black"
          labelAlignment="center"
          labelSize="14px"
        />
      </div>

      <Link className="Link" onClick={updateDatabase} to="/StartseiteLoggedIn" >
        <img className="logo" src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image" alt="Logo" />
      </Link>

      <div className="gefahr-text">
    <h1>SQL-Injection und Cross-Site Scripting</h1>
    
    <p><strong>Was sind SQL-Injection und Cross-Site Scripting (XSS)?<br /><br /></strong>
        SQL-Injection und Cross-Site Scripting (XSS) sind zwei häufige Techniken, die Angreifer verwenden, um Schwachstellen in Webanwendungen auszunutzen. 
        Beide Methoden zielen darauf ab, unbefugten Zugriff auf Daten zu erlangen oder schädlichen Code in Websites einzuschleusen.
    </p>
    
    <p><strong>Was ist SQL-Injection?</strong><br /><br />
    SQL-Injection ist ein Angriff, bei dem bösartiger SQL-Code in eine Eingabemaske einer Webanwendung eingeschleust wird. 
       Ziel ist es, die Datenbank der Anwendung zu manipulieren, um vertrauliche Daten zu stehlen, zu verändern oder zu löschen.</p>
    
    <p><strong>Wie funktioniert SQL-Injection?</strong>
    <ul><br />
        <li><strong>Eingabe von SQL-Code:</strong><br />
            Angreifer geben in eine Eingabemaske (z.B. Suchfeld, Login-Formular) bösartigen SQL-Code ein.
        </li><br />
        
        <li><strong>Manipulation der Datenbank:</strong><br />
            Wenn die Webanwendung die Eingabe nicht richtig prüft, wird der schädliche SQL-Code direkt an die Datenbank weitergeleitet.
        </li><br />
        
        <li><strong>Datenzugriff:</strong><br />
            Der Angreifer kann dadurch Zugriff auf sensible Daten wie Benutzernamen, Passwörter oder Kreditkarteninformationen erhalten.
        </li>
    </ul></p>
    
    <p><strong>Was ist Cross-Site Scripting (XSS)?</strong><br /><br />
    Cross-Site Scripting (XSS) ist ein Angriff, bei dem schädlicher Code, meist in Form von JavaScript, in eine Webseite eingeschleust wird. 
       Dieser Code wird dann ausgeführt, wenn andere Nutzer die infizierte Seite besuchen, wodurch der Angreifer Zugriff auf ihre Sitzungsdaten, Cookies oder andere vertrauliche Informationen erhält.
    </p>
    
    <p><strong>Wie funktioniert XSS?</strong>
    <ul><br />
        <li><strong>Einschleusen von JavaScript:</strong><br />
            Der Angreifer platziert schädlichen JavaScript-Code in ein Eingabefeld (z.B. Kommentarbereich) einer Website.
        </li><br />
        
        <li><strong>Ausführung durch Nutzer:</strong><br />
            Wenn andere Nutzer die infizierte Seite besuchen, wird das eingeschleuste Script in ihrem Browser ausgeführt.
        </li><br />
        
        <li><strong>Datenabgriff:</strong><br />
            Der Angreifer kann so sensible Informationen wie Sitzungscookies abgreifen oder Nutzeraktionen manipulieren.
        </li>
    </ul></p>
    
    <p><strong>Warum sind SQL-Injection und XSS gefährlich?</strong>
    <ul><br />
        <li><strong>Unbefugter Datenzugriff:</strong><br />
            Beide Angriffe können zu unbefugtem Zugriff auf sensible Daten führen, was zu Datenschutzverletzungen und erheblichen Schäden führen kann.
        </li><br />
        
        <li><strong>Manipulation von Webseiten:</strong><br />
            Angreifer können Webseiten verändern, um Malware zu verbreiten oder die Nutzererfahrung zu stören.
        </li><br />
        
        <li><strong>Schwer zu erkennen:</strong><br />
            Diese Angriffe können oft unbemerkt bleiben, wenn die Webanwendungen nicht richtig abgesichert sind.
        </li>
    </ul></p>
    
    <p><strong>Wie kann man sich schützen?</strong>
    <ul><br />
        <li><strong>Eingabevalidierung:</strong><br />
            Webanwendungen sollten Eingaben von Nutzern immer gründlich prüfen und bereinigen, um schädlichen Code herauszufiltern.
        </li><br />
        
        <li><strong>Vorbereitete Statements:</strong><br />
            Bei der Arbeit mit SQL-Datenbanken sollten vorbereitete Statements oder Stored Procedures verwendet werden, um SQL-Injection zu verhindern.
        </li><br />
        
        <li><strong>Content-Security-Policy (CSP):</strong><br />
            Eine strikte Content-Security-Policy kann helfen, die Ausführung von bösartigen Scripts zu verhindern.
        </li>
    </ul></p>
    
    <p><strong>Arten von SQL-Injection:</strong>
    <ul><br />
        <li><strong>Klassische (In-Band) SQL-Injection:</strong><br /><br />
            <strong>Beschreibung:</strong><br />Der Angreifer erhält sofortige Rückmeldungen direkt von der Datenbank durch die gleiche Kommunikationskanäle, die für die Anfrage verwendet wurden.<br /><br />
            <strong>Beispiele:</strong><br />Union-basierte SQL-Injection, Error-basierte SQL-Injection.
        </li><br />
        
        <li><strong>Blind SQL-Injection:</strong><br /><br />
            <strong>Beschreibung:</strong><br />Der Angreifer erhält keine direkten Rückmeldungen von der Datenbank. Stattdessen verwendet er Techniken, um durch wahr/falsch-Antworten Informationen zu extrahieren.<br /><br />
            <strong>Beispiele:</strong><br />Zeitbasierte Blind SQL-Injection, Boolean-basierte Blind SQL-Injection.
        </li><br />
        
        <li><strong>Out-of-Band SQL-Injection:</strong><br /><br />
            <strong>Beschreibung:</strong><br />Angreifer verwenden einen anderen Kanal, wie E-Mail oder HTTP-Anfragen, um Informationen zu erhalten, wenn direkte Rückmeldungen nicht möglich sind.<br /><br />
            <strong>Beispiele:</strong><br />Nutzung von HTTP-Requests oder DNS-Servern zur Übertragung von extrahierten Daten.
        </li>
    </ul></p>
    
    <p><strong>Arten von Cross-Site Scripting (XSS):</strong>
    <ul><br />
        <li><strong>Stored (Persistente) XSS:</strong><br /><br />
            <strong>Beschreibung:</strong><br />Der schädliche Code wird dauerhaft in die Webanwendung eingebettet (z.B. in Datenbanken) und wird jedes Mal ausgeführt, wenn die infizierte Seite aufgerufen wird.<br /><br />
            <strong>Beispiele:</strong><br />Eingeschleuster Code in Kommentarfeldern oder Benutzerprofilen, der bei jedem Aufruf der Seite aktiv wird.
        </li><br />
        
        <li><strong>Reflected (Nicht-persistente) XSS:</strong><br /><br />
            <strong>Beschreibung:</strong><br />Der schädliche Code wird in einer URL oder Anfrage eingeschleust und sofort zurückgegeben und ausgeführt, ohne gespeichert zu werden.<br /><br />
            <strong>Beispiele:</strong><br />Angreifer schickt eine präparierte URL, und der schädliche Code wird sofort bei der Eingabe in die Adressleiste ausgeführt.
        </li><br />
        
        <li><strong>DOM-based XSS:</strong><br /><br />
            <strong>Beschreibung:</strong> 
            <br />Der schädliche Code wird direkt im Client (im Browser) durch Manipulation des Document Object Model (DOM) ausgeführt. Hier findet die Ausführung vollständig im Browser statt, ohne dass der Server beteiligt ist.<br /><br />
            <strong>Beispiele:</strong><br />Manipulation von JavaScript-Variablen oder HTML-Elementen auf der Client-Seite.
        </li>
    </ul></p>
    
    <p><strong>Zusammenfassung:<br /><br /></strong>
        SQL-Injection und Cross-Site Scripting (XSS) sind gefährliche Angriffstechniken auf Webanwendungen. 
        SQL-Injection erlaubt unbefugten Datenbankzugriff, während XSS bösartige Scripts in Websites einfügt. 
        Durch Sicherheitspraktiken wie Eingabevalidierung und sichere Programmiertechniken lassen sich diese Angriffe verhindern.
    </p>
</div>



      <div className="video-container">
        <h2>Mehr über SQL-Injection und Cross-Site Scripting</h2>
        <YouTube videoId="SoL6_y-aWLM" opts={videoOptions} />
        <YouTube videoId="Y0Op-52mr_s" opts={videoOptions} />
      </div>

      <div className="Quiz-button">
        <div className="containerQuizze">
          <Link className="Link" to="/QuizDragAndDrop4">
            <button className='button-87'>Draggable Quiz</button>
          </Link>
          <Link className="Link" to="/QuizLueckentext4">
            <button className='button-87'>Lückentext Quiz</button>
          </Link>
          <Link className="Link" to="/QuizMatching4">
            <button className='button-87'>Kombiniere Quiz</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
