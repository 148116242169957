import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import firestore from "../../firebase";

export const QuizChoice1 = ({ onQuizResult }) => {
  const initialQuestions = [
    {
      id: 1,
      question: 'Welche Maßnahmen können helfen, sich vor Drive-by-Downloads zu schützen?',
      options: [
        { id: 'a', text: 'Regelmäßige Nutzung von öffentlichen WLANs' },
        { id: 'b', text: 'Verwenden der neuesten Versionen von Browsern und Plugins', correct: true },
        { id: 'c', text: 'Deaktivieren des Internetzugangs' },
        { id: 'd', text: 'Installation und Aktualisierung von Antivirenprogrammen', correct: true }
      ]
    },
    {
      id: 2,
      question: 'Was nutzen Drive-by-Downloads typischerweise aus, um schädliche Software zu verbreiten?',
      options: [
        { id: 'a', text: 'Unsichere Passwörter' },
        { id: 'b', text: 'Sicherheitslücken in Browsern und Plugins', correct: true },
        { id: 'c', text: 'Veraltete Betriebssysteme' },
        { id: 'd', text: 'Fehlende Antivirenprogramme' }
      ]
    },
    {
      id: 3,
      question: 'Welche Plugins sind besonders anfällig für Sicherheitslücken, die von Drive-by-Downloads ausgenutzt werden?',
      options: [
        { id: 'a', text: 'Flash', correct: true },
        { id: 'b', text: 'Java', correct: true },
        { id: 'c', text: 'Adobe Reader', correct: true },
        { id: 'd', text: 'Microsoft Word' }
      ]
    },
    {
      id: 4,
      question: 'Was sind typische Indikatoren dafür, dass eine Website manipuliert wurde und Drive-by-Downloads verbreitet?',
      options: [
        { id: 'a', text: 'Unerwartete Pop-ups', correct: true },
        { id: 'b', text: 'Verlangsamte Ladezeiten', correct: true },
        { id: 'c', text: 'Browser-Warnmeldungen', correct: true },
        { id: 'd', text: 'Verbesserte Benutzerfreundlichkeit' }
      ]
    },
    {
      id: 5,
      question: 'Welche dieser Maßnahmen kann die Wahrscheinlichkeit eines erfolgreichen Drive-by-Downloads verringern?',
      options: [
        { id: 'a', text: 'Deaktivierung von Pop-ups', correct: true },
        { id: 'b', text: 'Regelmäßige System-Backups' },
        { id: 'c', text: 'Nutzung eines Ad-Blockers', correct: true },
        { id: 'd', text: 'Deaktivierung von Flash und Java', correct: true }
      ]
    }
  ];


  const [questions, setQuestions] = useState(initialQuestions);
  const [answers, setAnswers] = useState({});
  const [result, setResult] = useState(0);  // Zustand für das Ergebnis
  const [progress, setProgress] = useState(0); // Zustand für den Fortschritt
  const [resultsDB, setResultsDB] = useState(0);
  const [maxResultsDB, setMaxResultsDB] = useState(0);
  const [check, setCheck] = useState(false);

  const handleOptionSelect = (questionId, optionId) => {
    const newAnswers = { ...answers };
    if (newAnswers[questionId]) {
      // If question already has answers, toggle the option
      if (newAnswers[questionId].includes(optionId)) {
        newAnswers[questionId] = newAnswers[questionId].filter(id => id !== optionId);
      } else {
        newAnswers[questionId].push(optionId);
      }
    } else {
      // If no answers yet, create new array with the selected option
      newAnswers[questionId] = [optionId];
    }
    setAnswers(newAnswers);
  };

  const checkAnswers = async () => {
    let rightAnswers = 0;
    let maxAnswers = 0;
    questions.forEach(question => {
      const selectedOptions = answers[question.id] || [];
      const correctOptions = question.options.filter(option => option.correct).map(option => option.id);
      maxAnswers++;
      if (selectedOptions.length === correctOptions.length && selectedOptions.every(option => correctOptions.includes(option))) {
        rightAnswers++;
      }
    });
  
    setResultsDB(rightAnswers);
    setMaxResultsDB(maxAnswers);
  
    const totalQuestions = questions.length;
    setResult({ rightAnswers, totalQuestions });
  
    const progressPercentage = (rightAnswers / totalQuestions) * 100;
    setProgress(progressPercentage);
  
    if (typeof onQuizResult === 'function') {
      onQuizResult({ rightAnswers, totalQuestions });
    }
  
    await updateDatabase();
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");
    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
      if (!userDocSnap.exists()) {
        console.error("Benutzerdokument existiert nicht.");
        return;
      }
  
      const userData = userDocSnap.data();
      const currentStars = resultsDB;
      const currentMaxStars = maxResultsDB;
  
      if (!userData.starsGefahr1 || !userData.maxStarsGefahr1) {
        userData.starsGefahr1 = [];
        userData.maxStarsGefahr1 = [];
      }
  
      if (!userData.starsGefahr1[2] || currentStars > userData.starsGefahr1[2]) {
        userData.starsGefahr1[2] = currentStars;
      }
  
      if (!userData.maxStarsGefahr1[2] || currentMaxStars > userData.maxStarsGefahr1[2]) {
        userData.maxStarsGefahr1[2] = currentMaxStars;
      }
  
      await setDoc(userDoc, userData);
      console.log("Datenbank erfolgreich aktualisiert.");
  
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  useEffect(() => {
    if (check == true){
      checkAnswers();
      setCheck(false);
    }
  }, );
  

  return (
    <div className="quiz-container">
      <Link className="Link" to="/Gefahr1">
        <img
          className="logo"
          src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
          alt="Logo"
        />
      </Link>
  
      {result && (
        <ProgressBar
          completed={Math.round((resultsDB / maxResultsDB) * 100)}
          bgColor="#06D001"
          baseBgColor="grey"
          labelColor="black"
          labelAlignment="center"
          labelSize="12px"
          className="progress-bar"
        />
      )}
      
      {questions.map(question => (
        <div className="Erklärungscard-Div" key={question.id}>
          <p>{question.question}</p>
          <div className="options">
            {question.options.map(option => (
              <div key={option.id} style={{ marginBottom: '8px' }}>
                <input
                  type="checkbox"
                  id={`${question.id}-${option.id}`}
                  name={`question-${question.id}`}
                  value={option.id}
                  checked={answers[question.id] && answers[question.id].includes(option.id)}
                  onChange={() => handleOptionSelect(question.id, option.id)}
                />
                <label htmlFor={`${question.id}-${option.id}`} style={{ whiteSpace: 'normal' }}>{option.text}</label>
              </div>
            ))}
          </div>
        </div>
      ))}
  
      <button
        className='button-87'
        onClick={() => {
          checkAnswers();
          setCheck(true);
        }}
      >
        Überprüfen
      </button>
      <br />
      <br />
      
      {result && (
        <div className="Erklärungscard-Div">
          <p>Du hast {result.rightAnswers} von {result.totalQuestions} Fragen richtig beantwortet.</p>
        </div>
      )}
    </div>
  );  
};

export default QuizChoice1;
