import React, { useState, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getAuth } from 'firebase/auth';
import { useAuthState } from '../firebase'; 

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [redirect, setRedirect] = useState(false);
    const { isAuthenticated } = useAuthState();
    const auth = getAuth();
    const googleProvider = new GoogleAuthProvider();

    const handleEmailPasswordLogin = useCallback(async () => {
        try {
          await signInWithEmailAndPassword(auth, email, password);
          setRedirect(true);
        } catch (e) {
          setError(e.message);
        }
    }, [auth, email, password]);

    const handleGoogleSignIn = useCallback(async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            setRedirect(true);
        } catch (e) {
            setError(e.message);
        }
    }, [auth, googleProvider]);

    if (redirect) {
        return <Redirect to="/" />
    }

    return (
        <div className="curved" style={{ minHeight: '100vh', position: 'relative' }}>
            <svg className='svg-curve' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#0099ff" d="M0,32L34.3,42.7C68.6,53,137,75,206,106.7C274.3,139,343,181,411,218.7C480,256,549,288,617,266.7C685.7,245,754,171,823,144C891.4,117,960,139,1029,165.3C1097.1,192,1166,224,1234,229.3C1302.9,235,1371,213,1406,202.7L1440,192L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
            </svg>


            <Link className="Link-Anmeldung" to="/">
                <img
                className="logo"
                src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
                alt="Logo"
                />
            </Link>

            <h1 className='login-headline'>Login</h1>

            <div style={{ alignItems: "center" }} className="Login_Form">
                <Link to="/">
                    <img
                        style={{ margin: "0 auto", width: '100px' }}
                        src="https://firebasestorage.googleapis.com/v0/b/internet-fuererschein.appspot.com/o/IF_Logo.svg?alt=media&token=fa845e98-fd2a-4bad-882e-5acf05a5b21c"
                        alt=""
                    />
                </Link>
                {error && <p className="ErrorLogin">{error}</p>}
                <label>Email-Adresse</label>
                <input
                    className="input-field"
                    name="email"
                    placeholder="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label>Password</label>
                <input
                    className="input-field"
                    name="password"
                    placeholder="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button 
                    style={{ marginLeft: "30px", textDecoration: "none" }} 
                    className='button-87' 
                    onClick={handleEmailPasswordLogin}
                >
                    Einloggen
                </button>
                <button
                    className='googleLogin'
                    id='googleLogin'
                    onClick={handleGoogleSignIn}
                >
                    Sign in with Google
                    <img src="https://www.imagensempng.com.br/wp-content/uploads/2023/05/278-4.png" alt="Google logo" className="google-icon" />
                </button>
                <Link to='/Help' className='move-down' style={{ color: 'blue' }}>
                Passwort vergessen?
                </Link>
                <Link to='Signup' className='move-down' style={{ color: 'blue' }}>
                Kostenlosen Account erstellen
                </Link>
            </div>

            <div className='bottom-div' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '50px', left: '0', right: '0' }}>
                <hr className='hr-modern'></hr>
                <a>© 2024 THWS</a>
            </div>
        </div>
    );
};
