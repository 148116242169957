import { useState } from 'react';
import '../../App.css';
import QuizConnection4 from '../../Jsx-Components/QuizConnection4';
import { Link } from 'react-router-dom';

export const QuizMatching4 = () => {
  const [quizResult, setQuizResult] = useState([]);

  // Diese Funktion wird als Prop an die QuizDragable-Komponente übergeben
  const handleQuizResult = (result) => {
    setQuizResult(result);
    console.log(result.totalQuestions & "/" &result.rightAnswers)
  };

  //        <h1> {quizResult.rightAnswers}/{quizResult.totalQuestions}</h1>
  //        <QuizDragable  onQuizResult={handleQuizResult}></QuizDragable>
  //        <QuizConnection2 onQuizResult={handleQuizResult}></QuizConnection2>
  return (
    <div className="kostenloseVariante">
            <Link className="Link" to="/Gefahr4">
        <img
          className="logo"
          src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
          alt="Logo"
        />
      </Link>

              <QuizConnection4 onQuizResult={handleQuizResult}></QuizConnection4>^  
    </div>
  );
}

