import React, { useState, useEffect } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import { Link } from 'react-router-dom';
const WeeklyQuizKachel = () => {
    const [daysRemaining, setDaysRemaining] = useState(0);

    useEffect(() => {
        const calculateDaysRemaining = () => {
            const today = new Date();
            const endOfWeek = new Date(today);
            endOfWeek.setDate(today.getDate() + (7 - today.getDay()));
            const remaining = Math.ceil((endOfWeek - today) / (1000 * 60 * 60 * 24));
            setDaysRemaining(remaining);
        };

        calculateDaysRemaining();
    }, []);

    return (
        <div className="weeklyQuizKachel">
            <h1>Wochenquiz</h1>
            <div className="daysRemaining">
                <h4>{daysRemaining} Tage verbleiben ⏳</h4>
            </div>
            <div className="kachelContainer">
                <div className="kachel">
                    <img src="https://cdn-icons-png.flaticon.com/128/2985/2985455.png" alt="Bild 1" className="kachelImage" />
                    <div className="kachelContent">
                        <h3>Starte ein Streak</h3>
                        
                        <div className="progressBar">
                        <ProgressBar
                        borderRadius="0px"
                        completed={40}
                        bgColor="#06D001"
                        baseBgColor="grey"
                        labelColor="grey"
                        labelAlignment="center"
                        labelSize="0px"
                        />
                        </div>
                    </div>
                </div>
                <div className="kachel">
                    <img src="https://cdn-icons-png.flaticon.com/512/938/938589.png" alt="Bild 2" className="kachelImage" />
                    <div className="kachelContent">
                        <h3>Schließe 1 Lektion perfekt ab</h3>
                        <div className="progressBar">
                        <ProgressBar
                        borderRadius="0px"
                        completed={0}
                        bgColor="#06D001"
                        baseBgColor="grey"
                        labelColor="grey"
                        labelAlignment="center"
                        labelSize="0px"
                        />
                        </div>
                    </div>
                </div>
                <div className="kachel">
                    <Link to="/weekly">
                    <img src="https://cdn-icons-png.freepik.com/256/861/861506.png?semt=ais_hybrid" alt="Bild 3" className="kachelImage" />
                    </Link>
                    <div className="kachelContent">
                        <h3>Schließe das Weekly Quiz ab</h3>
                        <div className="progressBar">
                        <ProgressBar
                        borderRadius="0px"
                        completed={100}
                        bgColor="#06D001"
                        baseBgColor="grey"
                        labelColor="grey"
                        labelAlignment="center"
                        labelSize="0px"
                        />
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    );
};

export default WeeklyQuizKachel;
