import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import firestore from "../../firebase";

const initialQuestions = [
  {
    id: 1,
    text: 'Ein Drive-by-Download kann automatisch ausgelöst werden, wenn ein Nutzer eine __________ besucht, die mit schädlichem Code infiziert ist.',
    blanks: ['Antwort'],
    answers: ['Webseite'],
  },
  {
    id: 2,
    text: 'Social Engineering nutzt die __________ von Menschen aus, um vertrauliche Informationen zu stehlen.',
    blanks: ['Antwort'],
    answers: ['Schwächen'],
  },
  {
    id: 3,
    text: 'Phishing-Angriffe versuchen oft, Benutzer dazu zu bringen, ihre __________ auf gefälschten Websites einzugeben.',
    blanks: ['Antwort'],
    answers: ['Anmeldedaten'],
  },
  {
    id: 4,
    text: 'Um sich vor Drive-by-Downloads zu schützen, sollten Nutzer ihre __________ regelmäßig aktualisieren.',
    blanks: ['Antwort'],
    answers: ['Software'],
  },
  {
    id: 5,
    text: 'Social Engineering zielt darauf ab, durch psychologische Manipulation an vertrauliche __________ zu gelangen.',
    blanks: ['Antwort'],
    answers: ['Informationen'],
  },
  {
    id: 6,
    text: 'Ein häufiger Phishing-Trick ist das Versenden von E-Mails, die zur Eingabe von __________ auffordern.',
    blanks: ['Antwort'],
    answers: ['Passwörtern'],
  }
];

export const QuizFillInGesamtuebung = ({ onQuizResult }) => {
  const [answers, setAnswers] = useState({});
  const [results, setResults] = useState(0);
  const [resultsDB, setResultsDB] = useState(0);
  const [maxResultsDB, setMaxResultsDB] = useState(0);
  const [check, setCheck] = useState(false);

  const handleChange = (questionId, blankIndex, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: {
        ...prevAnswers[questionId],
        [blankIndex]: answer,
      },
    }));
  };

  const checkAnswers = () => {
    let correct = 0;
    let totalAnswers = 0;

    initialQuestions.forEach((question) => {
      const userAnswers = answers[question.id];
      question.answers.forEach((correctAnswer, index) => {
        totalAnswers++;
        const userAnswer = userAnswers ? userAnswers[index] : '';
        if (userAnswer && userAnswer.trim().toLowerCase() === correctAnswer.toLowerCase()) {
          correct++;
        }
      });
    });

    const percentage = (correct / totalAnswers) * 100;
    const result = { correct, total: totalAnswers, percentage };
    setResults(result);
    setResultsDB(correct);
    setMaxResultsDB(totalAnswers);

    if (onQuizResult) {
      onQuizResult(result);
    }

    updateDatabase();

  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");
  
    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
  
      // Prüfe, ob das Dokument existiert
      if (!userDocSnap.exists()) {
        console.error("Benutzerdokument existiert nicht.");
        return;
      }
  
      const userData = userDocSnap.data();
      const currentStars = resultsDB;
      const currentMaxStars = maxResultsDB;
      
      // Vergleiche die aktuellen Ergebnisse mit den gespeicherten Ergebnissen
      if (!userData.starsGesamt1 || !userData.maxStarsGesamt1) {
        // Initialisiere die Arrays, wenn sie nicht existieren
        userData.starsGesamt1 = [];
        userData.maxStarsGesamt1 = [];
      }
  
      // Überprüfe, ob die aktuellen Ergebnisse besser sind als die gespeicherten Ergebnisse
      if (!userData.starsGesamt1[2] || currentStars > userData.starsGesamt1[2]) {
        userData.starsGesamt1[2] = currentStars;
      }
      
      if (!userData.maxStarsGesamt1[2] || currentMaxStars > userData.maxStarsGesamt1[2]) {
        userData.maxStarsGesamt1[2] = currentMaxStars;
      }
  
      // Speichern der aktualisierten Daten
      await setDoc(userDoc, userData);
      
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };

  useEffect(() => {
    if (check == true){
      checkAnswers();
      setCheck(false);
    }
  }, );
  

  return (
    <div className="quiz-container">
      <Link className="link" to="/QuizGesamtuebung">
      <img
          className="logo"
          src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
          alt="Logo"
        />
      </Link>

      {/* Fortschrittsbalken wird nur angezeigt, wenn ein Ergebnis vorliegt */}
      {results && (
        <ProgressBar
        completed={Math.round((resultsDB / maxResultsDB) * 100)}  // Aufrunden
        bgColor="#06D001"
          baseBgColor="grey"
          labelColor="black"
          labelAlignment="center"
          labelSize="14px"
        />
      )}

      {initialQuestions.map((question) => (
        <div key={question.id} className="Erklärungscard-Div">
          <p style={{ textAlign: 'center' }}>{question.text}</p>
          <div className="blanks-container">
            {question.blanks.map((blank, index) => (
              <div key={index} className="blank-input">
                <label>{blank}:</label>
                <input
                  type="text"
                  value={(answers[question.id] && answers[question.id][index]) || ''}
                  onChange={(e) => handleChange(question.id, index, e.target.value)}
                  placeholder={`Antwort für ${blank} eingeben`}
                />
              </div>
            ))}
          </div>
        </div>
      ))}

      <button
        className='button-87'
        onClick={() => {
          checkAnswers();   
          setCheck(true);       
        }}
      >
        Überprüfen
      </button>

      {results && (
        <div className="Erklärungscard-Div">
          <p>
            Du hast {results.correct} von {results.total} Antworten richtig beantwortet.
          </p>
        </div>
      )}
    </div>
  );
};

export default QuizFillInGesamtuebung;
