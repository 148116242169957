import { useState } from 'react';
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";

import ErklärungsCard from '../Jsx-Components/ErklärungsCard';


export const GefahrTemplate = () => {
  const [weiter,setWeiter] = useState(false);
  return (
    <div className="Lektion">
      <div style={{ position: "fixed", top: "10px", width: "80%", left: "50%", transform: "translateX(-50%)", display: "flex", alignItems: "center" }}>
        <Link style={{marginTop:"-10px",textDecoration:"none "}} to="/startseite">
        <a style={{  color:"grey",fontSize:"50px", marginRight: "10px", cursor: "pointer" }}>x</a>

        </Link>
        <div style={{ flexGrow: 1 }}>
          <ProgressBar
            borderRadius="0px"
            completed={0}
            bgColor="#06D001"
            baseBgColor="grey"
            labelColor="grey"
            labelAlignment="center"
            labelSize="0px"
          />
        </div>
      </div>
      
      <ErklärungsCard> </ErklärungsCard>
    </div>
  );
}
