import { useState } from 'react';
import '../App.css';
import ErklärungsCardKostenpflichtig from '../Jsx-Components/ErklärungsCardKostenpflichtig';
import FirstSettingsCardKostenpflichtig from '../Jsx-Components/FirstSettingsCardKostenpflichtig';
import { Link} from 'react-router-dom';


export const KostenpflichtigeVariante = () => {
  const [showErklärungsCardKostenpflichtig, setShowErklärungsCardKostenpflichtig] = useState(false);
  const handleButtonClicked = () => {
    setShowErklärungsCardKostenpflichtig(true);
  };

  return (
    <div className="kostenloseVariante">
       <Link className="Link" to="/">
              <img className="logo" src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"></img>
        </Link>
{!showErklärungsCardKostenpflichtig?<FirstSettingsCardKostenpflichtig onButtonClicked={handleButtonClicked}></FirstSettingsCardKostenpflichtig>:<></>}
{showErklärungsCardKostenpflichtig?
      <ErklärungsCardKostenpflichtig> </ErklärungsCardKostenpflichtig>
      :<></> }
        
     

    </div>
  );
}

