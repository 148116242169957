import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import firestore from "../../firebase";

export const QuizLueckentext5 = ({ onQuizResult }) => {
  const initialQuestions = [
    {
      id: 1,
      parts: [
        { type: 'text', content: 'Ransomware ist eine Art von Malware, die ' },
        { type: 'blank', id: 1 },
        { type: 'text', content: ' verschüsselt und ein ' },
        { type: 'blank', id: 2 },
        { type: 'text', content: ' fordert, um die Dateien wieder zugänglich zu machen.' }
      ],
      answers: { 1: 'Dateien', 2: 'Lösegeld' },
      options: { 1: ['Browser', 'Dateien', 'Informationen'], 2: ['Lösegeld', 'Download', 'Passwort'] }
    },
    {
      id: 2,
      parts: [
        { type: 'text', content: 'Adware ist eine Art von Malware, die ' },
        { type: 'blank', id: 3 },
        { type: 'text', content: ' anzeigt und oft dazu verwendet wird, ' },
        { type: 'blank', id: 4 },
        { type: 'text', content: ' zu sammeln.' }
      ],
      answers: { 3: 'Werbung', 4: 'Nutzerdaten' },
      options: { 3: ['Daten', 'Warnungen', 'Werbung'], 4: ['Informationen', 'Nutzerdaten', 'Abonennten'] }
    },
    {
      id: 3,
      parts: [
        { type: 'text', content: 'Ein ' },
        { type: 'blank', id: 5 },
        { type: 'text', content: ' ist ein schädliches Programm, das sich als legitime Software tarnt und darauf abzielt, den Computer des Benutzers zu kompromittieren, oft um weiteren Schaden anzurichten.' }
      ],
      answers: { 5: 'Trojaner'},
      options: { 5: ['Wurm', 'Spam', 'Trojaner']}
    },
    {
      id: 4,
      parts: [
        { type: 'text', content: 'Ein ' },
        { type: 'blank', id: 7 },
        { type: 'text', content: ' ist eine Art von Malware, die sich selbst repliziert und über Netzwerke verbreitet, oft ohne das Wissen des Benutzers.' }
      ],
      answers: { 7: 'Wurm'},
      options: { 7: ['Spam', 'Trojaner', 'Wurm']}
    }
  ];

  const [questions, setQuestions] = useState(initialQuestions);
  const [answers, setAnswers] = useState({});
  const [draggedWord, setDraggedWord] = useState(null);
  const [result, setResult] = useState(0);  // Zustand für das Ergebnis
  const [progress, setProgress] = useState(0); // Zustand für den Fortschritt
  const [resultsDB, setResultsDB] = useState(0);
  const [maxResultsDB, setMaxResultsDB] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false); // Zustand für die Anzeige des Fortschrittsbalkens
  const [check, setCheck] = useState(false);

  const handleDragStart = (word) => {
    setDraggedWord(word);
  };

  const handleDrop = (questionId, blankId) => {
    const newAnswers = { ...answers };
    newAnswers[`${questionId}-${blankId}`] = draggedWord;
    setAnswers(newAnswers);
    setDraggedWord(null);
  };

  const checkAnswers = () => {
    let rightAnswers = 0;
    let maxAnswers = 0;
    questions.forEach(question => {
      question.parts.forEach(part => {
        if (part.type === 'blank') {
          maxAnswers++;
          const answer = answers[`${question.id}-${part.id}`];
          if (answer === question.answers[part.id]) {
            rightAnswers++;
          }
        }
      });
    });

    setResultsDB(rightAnswers);
    setMaxResultsDB(maxAnswers);

    const totalQuestions = countTotalQuestions();
    setResult({ rightAnswers, totalQuestions });

    const progressPercentage = (rightAnswers / totalQuestions) * 100;
    setProgress(progressPercentage);
    setShowProgressBar(true); // Zeigt den Fortschrittsbalken an, nachdem die Antworten überprüft wurden

    if (typeof onQuizResult === 'function') {
      onQuizResult({ rightAnswers, totalQuestions });
    }

    updateDatabase();
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");
  
    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
  
      // Prüfe, ob das Dokument existiert
      if (!userDocSnap.exists()) {
        console.error("Benutzerdokument existiert nicht.");
        return;
      }
  
      const userData = userDocSnap.data();
      const currentStars = resultsDB;
      const currentMaxStars = maxResultsDB;
      
      // Vergleiche die aktuellen Ergebnisse mit den gespeicherten Ergebnissen
      if (!userData.starsGefahr1 || !userData.maxStarsGefahr1) {
        // Initialisiere die Arrays, wenn sie nicht existieren
        userData.starsGefahr1 = [];
        userData.maxStarsGefahr1 = [];
      }
  
      // Überprüfe, ob die aktuellen Ergebnisse besser sind als die gespeicherten Ergebnisse
      if (!userData.starsGefahr5[0] || currentStars > userData.starsGefahr5[0]) {
        userData.starsGefahr5[0] = currentStars;
      }
      
      if (!userData.maxStarsGefahr5[0] || currentMaxStars > userData.maxStarsGefahr5[0]) {
        userData.maxStarsGefahr5[0] = currentMaxStars;
      }
  
      // Speichern der aktualisierten Daten
      await setDoc(userDoc, userData);
      
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };


  const countTotalQuestions = () => {
    let totalQuestions = 0;
    questions.forEach(question => {
      totalQuestions += question.parts.filter(part => part.type === 'blank').length;
    });
    return totalQuestions;
  };

  useEffect(() => {
    if (check == true){
      checkAnswers();
      setCheck(false);
    }
  }, );

  return (
    <div className="quiz-container">
      <Link className="link" to="/Gefahr5">
      <img
          className="logo"
          src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
          alt="Logo"
        />
      </Link>
      
      {showProgressBar && (
        <ProgressBar
        completed={Math.round((resultsDB / maxResultsDB) * 100)}  // Aufrunden
        bgColor="#06D001"
        baseBgColor="grey"
        labelColor="black"
        labelAlignment="center"
        labelSize="14px"
      />
      )}
    
      {questions.map(question => (
        <div className="Erklärungscard-Div" key={question.id}>
          <div className="question-text">
            {question.parts.map(part => (
              part.type === 'text' ? (
                <span key={part.content}>{part.content}</span>
              ) : (
                <span
                  key={part.id}
                  className="dropzone"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => handleDrop(question.id, part.id)}
                >
                  {answers[`${question.id}-${part.id}`] || ''}
                </span>
              )
            ))}
          </div>
          <br></br>
          <div className="optionsLueckentext">
            {Object.keys(question.options).map(blankId => (
              <div key={blankId} className="word-bank-section">
                {question.options[blankId].map((word, index) => (
                  <div
                    key={index}
                    className="word"
                    draggable
                    onDragStart={() => handleDragStart(word)}
                  >
                    {word}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}

      <button
        className='button-87'
        onClick={() => {
          checkAnswers();   
          setCheck(true);       
        }}
      >
        Überprüfen
      </button>

      {result && (
        <div className="Erklärungscard-Div">
          <br></br>
          <p>Du hast {result.rightAnswers} von {result.totalQuestions} richtig.</p>
        </div>
      )}
    </div>
  );
};

export default QuizLueckentext5;
