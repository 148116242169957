import { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import firestore from "../../firebase";

export const QuizLueckentextGesamtuebung = ({ onQuizResult }) => {
  const initialQuestions = [
    {
      id: 1,
      parts: [
        { type: 'text', content: 'Bei einem Drive-by-Download wird oft eine ' },
        { type: 'blank', id: 1 },
        { type: 'text', content: ' aufgerufen, die Sicherheitslücken in ' },
        { type: 'blank', id: 2 },
        { type: 'text', content: ' ausnutzt, um schädliche Software herunterzuladen.' }
      ],
      answers: { 1: 'Webseite', 2: 'Browsern' },
      options: { 1: ['Dokument', 'Datei', 'Webseite'], 2: ['Browsern', 'E-Mail-Konten', 'Dateien'] }
    },
    {
      id: 2,
      parts: [
        { type: 'text', content: 'Ein häufiges Social Engineering-Szenario ist das Vortäuschen von ' },
        { type: 'blank', id: 3 },
        { type: 'text', content: ', um ' },
        { type: 'blank', id: 4 },
        { type: 'text', content: ' zu erhalten.' }
      ],
      answers: { 3: 'Identität', 4: 'Zugangsdaten' },
      options: { 3: ['Identität', 'Updates', 'technischen Kenntnissen'], 4: ['Informationen', 'Werbung', 'Zugangsdaten'] }
    },
    {
      id: 3,
      parts: [
        { type: 'text', content: 'Phishing-Angriffe versuchen oft, Benutzer dazu zu bringen, ihre ' },
        { type: 'blank', id: 5 },
        { type: 'text', content: ' auf gefälschten ' },
        { type: 'blank', id: 6 },
        { type: 'text', content: ' einzugeben.' }
      ],
      answers: { 5: 'Passwörter', 6: 'Webseiten' },
      options: { 5: ['Telefonnummer', 'Passwörter', 'E-Mail'], 6: ['Formularen', 'Webseiten', ''] }
    },
    {
      id: 4,
      parts: [
        { type: 'text', content: 'Um sich vor Drive-by-Downloads zu schützen, sollten Nutzer ' },
        { type: 'blank', id: 7 },
        { type: 'text', content: ', und ' },
        { type: 'blank', id: 8 },
        { type: 'text', content: ' regelmäßig aktualisieren.' }
      ],
      answers: { 7: 'Browser', 8: 'Plugins' },
      options: { 7: ['Telefonnummern', 'Passwörter', 'Browser'], 8: ['Plugins', 'Benutzernamen', 'Zugangsdaten'] }
    },
    {
      id: 5,
      parts: [
        { type: 'text', content: 'Social Engineering nutzt ' },
        { type: 'blank', id: 9 },
        { type: 'text', content: ', um Menschen zu täuschen und an vertrauliche Informationen zu gelangen.' }
      ],
      answers: { 9: 'Tricks'},
      options: { 9: ['Tricks', 'Computer', 'Software']}
    },
    {
      id: 6,
      parts: [
        { type: 'text', content: 'Phishing-Angriffe versuchen, Benutzer durch gefälschte ' },
        { type: 'blank', id: 11 },
        { type: 'text', content: ' dazu zu bringen, persönliche Daten preiszugeben.' }
      ],
      answers: { 11: 'E-Mails' },
      options: { 11: ['Anrufe', 'Links', 'E-Mails']}
    }
  ];

  const [questions, setQuestions] = useState(initialQuestions);
  const [answers, setAnswers] = useState({});
  const [draggedWord, setDraggedWord] = useState(null);
  const [result, setResult] = useState(0);  // Zustand für das Ergebnis
  const [progress, setProgress] = useState(0); // Zustand für den Fortschritt
  const [resultsDB, setResultsDB] = useState(0);
  const [maxResultsDB, setMaxResultsDB] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false); // Zustand für die Anzeige des Fortschrittsbalkens
  const [check, setCheck] = useState(false);

  const handleDragStart = (word) => {
    setDraggedWord(word);
  };

  const handleDrop = (questionId, blankId) => {
    const newAnswers = { ...answers };
    newAnswers[`${questionId}-${blankId}`] = draggedWord;
    setAnswers(newAnswers);
    setDraggedWord(null);
  };

  const checkAnswers = () => {
    let rightAnswers = 0;
    let maxAnswers = 0;
    questions.forEach(question => {
      question.parts.forEach(part => {
        if (part.type === 'blank') {
          maxAnswers++;
          const answer = answers[`${question.id}-${part.id}`];
          if (answer === question.answers[part.id]) {
            rightAnswers++;
          }
        }
      });
    });

    setResultsDB(rightAnswers);
    setMaxResultsDB(maxAnswers);

    const totalQuestions = countTotalQuestions();
    setResult({ rightAnswers, totalQuestions });

    const progressPercentage = (rightAnswers / totalQuestions) * 100;
    setProgress(progressPercentage);
    setShowProgressBar(true); // Zeigt den Fortschrittsbalken an, nachdem die Antworten überprüft wurden

    if (typeof onQuizResult === 'function') {
      onQuizResult({ rightAnswers, totalQuestions });
    }

    updateDatabase();
  };

  const updateDatabase = async () => {
    const ref = collection(firestore, "Users");
  
    try {
      const userDoc = doc(ref, getAuth().currentUser.email);
      const userDocSnap = await getDoc(userDoc);
  
      // Prüfe, ob das Dokument existiert
      if (!userDocSnap.exists()) {
        console.error("Benutzerdokument existiert nicht.");
        return;
      }
  
      const userData = userDocSnap.data();
      const currentStars = resultsDB;
      const currentMaxStars = maxResultsDB;
      
      // Vergleiche die aktuellen Ergebnisse mit den gespeicherten Ergebnissen
      if (!userData.starsGesamt1 || !userData.maxStarsGesamt1) {
        // Initialisiere die Arrays, wenn sie nicht existieren
        userData.starsGesamt1 = [];
        userData.maxStarsGesamt1 = [];
      }
  
      // Überprüfe, ob die aktuellen Ergebnisse besser sind als die gespeicherten Ergebnisse
      if (!userData.starsGesamt1[0] || currentStars > userData.starsGesamt1[0]) {
        userData.starsGesamt1[0] = currentStars;
      }
      
      if (!userData.maxStarsGesamt1[0] || currentMaxStars > userData.maxStarsGesamt1[0]) {
        userData.maxStarsGesamt1[0] = currentMaxStars;
      }
  
      // Speichern der aktualisierten Daten
      await setDoc(userDoc, userData);
      
    } catch (e) {
      console.error("Fehler beim Aktualisieren der Datenbank", e);
    }
  };
  

  const countTotalQuestions = () => {
    let totalQuestions = 0;
    questions.forEach(question => {
      totalQuestions += question.parts.filter(part => part.type === 'blank').length;
    });
    return totalQuestions;
  };

  useEffect(() => {
    if (check == true){
      checkAnswers();
      setCheck(false);
    }
  }, );

  return (
    <div className="quiz-container">
      <Link className="link" to="/QuizGesamtuebung">
      <img
          className="logo"
          src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
          alt="Logo"
        />
      </Link>
      
      {showProgressBar && (
        <ProgressBar
        completed={Math.round((resultsDB / maxResultsDB) * 100)}  // Aufrunden
        bgColor="#06D001"
        baseBgColor="grey"
        labelColor="black"
        labelAlignment="center"
        labelSize="14px"
      />
      )}
    
      {questions.map(question => (
        <div className="Erklärungscard-Div" key={question.id}>
          <div className="question-text">
            {question.parts.map(part => (
              part.type === 'text' ? (
                <span key={part.content}>{part.content}</span>
              ) : (
                <span
                  key={part.id}
                  className="dropzone"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => handleDrop(question.id, part.id)}
                >
                  {answers[`${question.id}-${part.id}`] || ''}
                </span>
              )
            ))}
          </div>
          <br></br>
          <div className="optionsLueckentext">
            {Object.keys(question.options).map(blankId => (
              <div key={blankId} className="word-bank-section">
                {question.options[blankId].map((word, index) => (
                  <div
                    key={index}
                    className="word"
                    draggable
                    onDragStart={() => handleDragStart(word)}
                  >
                    {word}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}

      <button
        className='button-87'
        onClick={() => {
          checkAnswers();   
          setCheck(true);       
        }}
      >
        Überprüfen
      </button>

      {result && (
        <div className="Erklärungscard-Div">
          <br></br>
          <p>Du hast {result.rightAnswers} von {result.totalQuestions} richtig.</p>
        </div>
      )}
    </div>
  );
};

export default QuizLueckentextGesamtuebung;
