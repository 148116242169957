import { useState } from 'react';
import '../App.css';
import ErklärungsCard from '../Jsx-Components/ErklärungsCard';
import FirstSettingsCard from '../Jsx-Components/FirstSettingsCard';
import { Link} from 'react-router-dom';


export const KostenloseVariante = () => {
  const [showErklärungsCard, setShowErklärungsCard] = useState(false);
  const handleButtonClicked = () => {
    setShowErklärungsCard(true);
  };

  return (
    <div className="kostenloseVariante">
       <Link className="Link" to="/">
              <img className="logo" src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"></img>
        </Link>
{!showErklärungsCard?<FirstSettingsCard onButtonClicked={handleButtonClicked}></FirstSettingsCard>:<></>}
{showErklärungsCard?
      <ErklärungsCard> </ErklärungsCard>
      :<></> }
        
     

    </div>
  );
}

