import { useState } from 'react';
import '../../App.css';
import QuizDragable4 from '../../Jsx-Components/QuizDragable4';
import { Link } from 'react-router-dom';
import ProgressBar from '../ProgressBar';

export const QuizDragAndDrop4 = () => {
  const [quizResult, setQuizResult] = useState({ totalQuestions: 0, rightAnswers: 0 });
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const handleQuizResult = (result) => {
    setQuizResult(result);
    const progressPercent = (result.rightAnswers / result.totalQuestions) * 100;
    setProgress(progressPercent);
    setShowProgressBar(true);
  };

  return (
    <div className="kostenloseVariante">
      <Link className="Link" to="/Gefahr4">
        <img
          className="logo"
          src="https://assets.propertyshelf.com/web-graphics/click-buttons/back-button.png/image"
          alt="Logo"
        />
      </Link>
      <QuizDragable4 onQuizResult={handleQuizResult}></QuizDragable4>
    </div>
  );
}
